import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { PageEvent } from '@angular/material/paginator';
import { EntryFileAsyncService } from 'src/app/app-services-async/entry-file-async.service';
import { WHFileAsyncService } from 'src/app/app-services-async/w-h-file-async.service';
import { DataModelHelperService } from 'src/app/app-services-helper/data-model-helper.service';
import { AppDialogService } from 'src/app/app-dialogs/app-dialog.service';
import { Subscription } from 'rxjs';
import {
  WHEntryDOM,
  WHIconENUM,
  IWHFileDTO,
  IWHEntryFileDTO,
  WHEntryFileDOM,
} from '@workheld/workheld-shared-lib';

@Component({
  selector: 'ng-bee-entry-file-img-gallery',
  templateUrl: './entry-file-img-gallery.component.html',
  styleUrls: ['./entry-file-img-gallery.component.scss'],
})
export class EntryFileImgGalleryComponent implements OnInit, OnDestroy {
  // INPUT STATE
  @Input() entryDOM: WHEntryDOM;

  // STATE
  public loading: boolean = false;
  public loaded: boolean = false;

  // DATA
  public entryFileDomArray: WHEntryFileDOM[] = [];
  public entryFileDomDisplayPage: WHEntryFileDOM[] = [];

  public noCache = 'noCache';
  // SUBSCRIPTION
  public subscriptions: Subscription[] = [];

  constructor(
    private entryFileAsyncService: EntryFileAsyncService,
    private workheldFileAsyncService: WHFileAsyncService,
    private dataModelHelperService: DataModelHelperService,
    private appDialogService: AppDialogService,
  ) {}

  ngOnInit() {
    this.loading = true;
    this.subscriptions.push(
      this.entryFileAsyncService
        .getEntryFileDtoListByEntryId(this.entryDOM?.id)
        .subscribe((entryFileDtoArray: IWHEntryFileDTO[]) => {
          if (entryFileDtoArray) {
            entryFileDtoArray.forEach((entryFileDTO: IWHEntryFileDTO) => {
              const entryFileDOM: WHEntryFileDOM =
                this.dataModelHelperService.initEntryFileDOM(entryFileDTO);
              this.entryFileDomArray.push(entryFileDOM);
            });
            this.entryFileDomDisplayPage = this.setDisplayPage(
              this.entryFileDomArray,
              0,
            );
            this.loading = false;
            this.loaded = true;
          } else {
            this.loading = false;
            this.loaded = true;
          }
        }),
    );
  }

  public filePreview(data: { file: IWHFileDTO; comment: string }) {
    this.appDialogService.openWHFilePreviewDialog(data);
  }

  public downloadFile(file: IWHFileDTO) {
    this.subscriptions.push(
      this.workheldFileAsyncService
        .downloadWHFileBlob(file)
        .subscribe((fileBlob: Blob) => {
          this.workheldFileAsyncService.saveFile(file, fileBlob);
        }),
    );
  }

  public pageEvent($event: PageEvent) {
    this.entryFileDomDisplayPage = this.setDisplayPage(
      this.entryFileDomArray,
      $event.pageIndex,
    );
  }

  private setDisplayPage(data: any[], pageIndex: number, length: number = 10) {
    const begin = pageIndex * length;
    const end = begin + length;
    const page: Array<any> = data.slice(begin, end);
    return page;
  }

  public get downloadIcon(): string {
    return WHIconENUM.DownloadIcon as string;
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription: Subscription) => {
      subscription.unsubscribe();
    });
  }
}
