import { Component, ViewChild } from '@angular/core';

import {
  BryntumSchedulerProComponent,
  BryntumSchedulerProModule,
} from '@bryntum/schedulerpro-angular';
import { createSchedulerConfig } from '../equipment-planner/equipment-planner.config';

@Component({
  selector: 'app-equipment-planner',
  standalone: true,
  templateUrl: './equipment-planner-groups.component.html',
  styleUrls: ['./equipment-planner-groups.component.scss'],
  imports: [BryntumSchedulerProModule],
})
export class EquipmentPlannerGroupsComponent {
  @ViewChild(BryntumSchedulerProComponent, { static: false })
  schedulerComponent: BryntumSchedulerProComponent;
  @ViewChild('addRangeButton', { static: false })
  addRangeButton: any;
  schedulerConfig: any;

  constructor() {
    // sending "this" beacause we need to access the bryntum instance in the config file
    this.schedulerConfig = createSchedulerConfig(this);
  }
  onSchedulerEvents(event: any) {}
}
