<!-- MEASURMENT Entry / entryTypeId = 6 -->
<div class="row-wrapper">
  <entry-header style="width: 100%" [entryDOM]="entryDOM"></entry-header>
</div>
<div class="row-wrapper entry-detail-row-wrapper">
  <span>
    <div class="w-h-label entry-detail-label">
      {{ "entry.measurement.model.type" | translate }}
    </div>
    <div class="default-value">
      {{
        measurmentEntryDOM?.measurementType
          ? $ENUMS_OBJECT().MEASUREMENT_TYPE[
              measurmentEntryDOM?.measurementType
            ]
          : "-"
      }}
    </div>
  </span>
  <span>
    <div class="w-h-label entry-detail-label">
      {{ "entry.measurement.model.value" | translate }}
    </div>
    <div class="default-value">
      @if (measurmentEntryDOM?.value) {
        {{ measurmentEntryDOM?.value }}
      } @else if (!!measurmentEntryDOM?.measurementValue?.valueBoolean) {
        {{ "app.boolean." + measurmentValue | translate }}
      } @else if (measurmentEntryDOM?.measurementValue) {
        measurmentValue
      } @else {
        -
      }
    </div>
  </span>
  <span>
    <div class="w-h-label entry-detail-label">
      {{ "entry.measurement.model.unit" | translate }}
    </div>
    <div class="default-value">
      {{
        measurmentEntryDOM?.unit
          ? $ENUMS_OBJECT().UNIT[measurmentEntryDOM?.unit]
          : "-"
      }}
    </div>
  </span>
</div>

<div>
  <div class="w-h-label-no-margine">
    {{ "entry.model.comment" | translate }}
  </div>
  <div>
    <mat-form-field whFlex>
      <textarea
        [disabled]="true"
        rows="2"
        matInput
        [value]="entryDOM?.comment ? entryDOM?.comment : ''"
      >
      </textarea>
    </mat-form-field>
  </div>
</div>

<div style="width: 100%">
  <ng-bee-entry-file-img-gallery
    [entryDOM]="entryDOM"
  ></ng-bee-entry-file-img-gallery>
</div>
