// ENVIRONMENT
import { environment } from 'src/environments/environment';

// ANGULAR
import { RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';

// APP
import { AppLayoutComponent } from './app-layout.component';
import { WHContentPageComponent } from './w-h-content-page/w-h-content-page/w-h-content-page.component';

// AUTH0
import { AuthGuard } from '@auth0/auth0-angular';

// API
import {
  WHRouteZeroENUM,
  WHDiagnosticPageComponent,
  WHDiagnosticPageContentComponent,
  IWHPageConfigModel,
} from '@workheld/workheld-shared-lib';
import {
  layoutLoggedInUserNavItemDOMList,
  layoutNavItemDOMList,
} from './app.config';
import {
  bryntumSchedulerLibVersion,
  workheldSharedLibVersion,
  workheldWebClientVersion,
} from 'src/environments/env.config';
import { CheckUnsavedCanDeactivateGuard } from './app-shared/guards/check-unsaved.guard';
import { DevelopmentGuard } from './app-services-helper/dev-guard-helper.service';
import { EquipmentPlannerGroupsComponent } from './app-modules-pages/equipment/groups/equipment-planner-groups.component';

export const routes: Routes = [
  {
    path: '',
    component: AppLayoutComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: `${WHRouteZeroENUM.HOME}`,
        component: WHContentPageComponent,
        loadChildren: () =>
          import(
            './app-modules-pages/work-object/work-object-status-overview/work-object-status-overview.module'
          ).then((m) => m.WorkObjectStatusOverviewModule),
      },
      // {
      //   path: `${WHRouteZeroENUM.SUBCONTACTOR_BOARD}`,
      //   component: WHContentPageComponent,
      //   loadChildren: () => import('./app-modules-pages/work-object/work-object-status-overview/work-object-status-overview.module').then(m => m.WorkObjectStatusOverviewModule)
      // },
      {
        path: `${WHRouteZeroENUM.PROJECT_OVERVIEW}`,
        component: WHContentPageComponent,
        loadChildren: () =>
          import(
            './app-modules-pages/project-overview/project-overview.module'
          ).then((m) => m.ProjectOverviewModule),
      },
      {
        path: `${WHRouteZeroENUM.TEAM_PLANNER}`,
        component: WHContentPageComponent,
        loadChildren: () =>
          import(
            './app-pages/w-h-team-planner-page/w-h-team-planner-page.module'
          ).then((m) => m.WHTeamPlannerPageModule),
      },
      {
        path: `${WHRouteZeroENUM.ENTRIES_UNASSIGNED}`,
        component: WHContentPageComponent,
        loadChildren: () =>
          import(
            './app-modules-pages/entry/entry-unassigned/entry-unassigned.module'
          ).then((m) => m.EntryUnassignedModule),
      },
      {
        path: `maintenancenotifications`,
        component: WHContentPageComponent,
        loadChildren: () =>
          import(
            './app-modules-pages/entry/entry-maintenance-notifications/entry-maintenance-notifications.module'
          ).then((m) => m.EntryMaintenanceNotificationsModule),
      },
      {
        path: `${WHRouteZeroENUM.WORK_OBJECT_EFFORT_REPORT}`,
        component: WHContentPageComponent,
        loadChildren: () =>
          import(
            './app-pages/effort-report-page/effort-report-page.module'
          ).then((m) => m.EffortReportPageModule),
      },
      {
        path: `reports`,
        component: WHContentPageComponent,
        loadChildren: () =>
          import('./app-pages/reports-page/reports-page.module').then(
            (m) => m.ReportsPageModule,
          ),
      },
      {
        path: `${WHRouteZeroENUM.TIME_REPORT}`,
        component: WHContentPageComponent,
        loadChildren: () =>
          import(
            './app-modules-pages/worker/worker-time-report/worker-time-report.module'
          ).then((m) => m.WorkerTimeReportModule),
      },
      {
        path: `${WHRouteZeroENUM.PROJECTS}/:customerId/:workObjectId`,
        canDeactivate: [CheckUnsavedCanDeactivateGuard],
        component: WHContentPageComponent,
        loadChildren: () =>
          import(
            './app-modules-pages/project/project-crud/project-crud.module'
          ).then((m) => m.ProjectCrudModule),
      },
      {
        path: `${WHRouteZeroENUM.PROJECTS}`,
        component: WHContentPageComponent,
        loadChildren: () =>
          import(
            './app-modules-pages/work-object/work-object-overview/work-object-overview.module'
          ).then((m) => m.WorkObjectOverviewModule),
      },
      {
        path: `${WHRouteZeroENUM.CASES}/:customerId/:workObjectId`,
        component: WHContentPageComponent,
        canDeactivate: [CheckUnsavedCanDeactivateGuard],
        loadChildren: () =>
          import('./app-modules-pages/case/case-crud/case-crud.module').then(
            (m) => m.CaseCrudModule,
          ),
      },
      {
        path: `${WHRouteZeroENUM.CASES}`,
        component: WHContentPageComponent,
        loadChildren: () =>
          import(
            './app-modules-pages/work-object/work-object-overview/work-object-overview.module'
          ).then((m) => m.WorkObjectOverviewModule),
      },
      {
        path: `${WHRouteZeroENUM.CASES_CATEGORY}`,
        component: WHContentPageComponent,
        loadChildren: () =>
          import(
            './app-modules-pages/case/case-quick-create/case-quick-create.module'
          ).then((m) => m.CaseQuickCreateModule),
      },
      {
        path: `${WHRouteZeroENUM.STANDING_ORDER}/:customerId/:workObjectId`,
        component: WHContentPageComponent,
        canDeactivate: [CheckUnsavedCanDeactivateGuard],
        loadChildren: () =>
          import(
            './app-modules-pages/standing-order/standing-order-crud/standing-order-crud.module'
          ).then((m) => m.StandingOrderCrudModule),
      },
      {
        path: `${WHRouteZeroENUM.STANDING_ORDER}`,
        component: WHContentPageComponent,
        loadChildren: () =>
          import(
            './app-modules-pages/work-object/work-object-overview/work-object-overview.module'
          ).then((m) => m.WorkObjectOverviewModule),
      },
      {
        path: `:workObjectTypeId/:customerId/:workObjectId/:workOrderId`,
        component: WHContentPageComponent,
        canDeactivate: [CheckUnsavedCanDeactivateGuard],
        loadChildren: () =>
          import(
            './app-modules-pages/work-order/work-order-crud/work-order-crud.module'
          ).then((m) => m.WorkOrderCrudModule),
      },
      {
        path: `${WHRouteZeroENUM.EQUIPMENTS}/:bomNodeId/:equipmentId`,
        component: WHContentPageComponent,
        canDeactivate: [CheckUnsavedCanDeactivateGuard],
        loadChildren: () =>
          import(
            './app-modules-pages/equipment/equipment-crud/equipment-crud.module'
          ).then((m) => m.EquipmentCrudModule),
      },
      {
        path: WHRouteZeroENUM.QUALIFICATIONS,
        component: WHContentPageComponent,
        loadChildren: () =>
          import(
            './app-modules-pages/qualifications/qualifications.module'
          ).then((m) => m.QualificationsModule),
      },
      {
        path: WHRouteZeroENUM.JOB_REQUIREMENTS,
        component: WHContentPageComponent,
        loadChildren: () =>
          import(
            './app-modules-pages/job-requirements/job-requirements.module'
          ).then((m) => m.JobRequirementsModule),
      },
      {
        path: WHRouteZeroENUM.EXECUTION_GROUPS,
        component: WHContentPageComponent,
        loadChildren: () =>
          import(
            './app-modules-pages/execution-groups/execution-groups.module'
          ).then((m) => m.ExecutionGroupsModule),
      },
      {
        path: WHRouteZeroENUM.SHIFT_DEFINITIONS,
        component: WHContentPageComponent,
        loadChildren: () =>
          import(
            './app-modules-pages/shift-definitions/shift-definitions.module'
          ).then((m) => m.ShiftDefinitionsModule),
      },
      {
        path: WHRouteZeroENUM.SHIFT_PLANNER,
        component: WHContentPageComponent,
        loadChildren: () =>
          import('./app-modules-pages/shift-planner/shift-planner.module').then(
            (m) => m.ShiftPlannerModule,
          ),
      },
      {
        path: WHRouteZeroENUM.DAILY_DASHBOARD,
        component: WHContentPageComponent,
        loadChildren: () =>
          import(
            './app-modules-pages/daily-dashboard/daily-dashboard.module'
          ).then((m) => m.DailyDashboardModule),
      },
      {
        path: `${WHRouteZeroENUM.EQUIPMENTS}/:equipmentId`,
        component: WHContentPageComponent,
        canDeactivate: [CheckUnsavedCanDeactivateGuard],
        loadChildren: () =>
          import(
            './app-modules-pages/equipment/equipment-crud/equipment-crud.module'
          ).then((m) => m.EquipmentCrudModule),
      },
      {
        path: WHRouteZeroENUM.EQUIPMENTS,
        component: WHContentPageComponent,
        loadChildren: () =>
          import(
            './app-modules-pages/equipment/equipment-data-table/equipment-data-table.module'
          ).then((m) => m.EquipmentDataTableModule),
      },
      {
        path: WHRouteZeroENUM.EQUIPMENT_PLANNER,
        component: WHContentPageComponent,
        loadChildren: () =>
          import(
            './app-modules-pages/equipment/equipment-planner/equipment-planner.module'
          ).then((m) => m.EquipmentPlannerModule),
      },
      {
        path: 'eqgroups',
        component: EquipmentPlannerGroupsComponent,
      },
      {
        path: WHRouteZeroENUM.SKILLS,
        component: WHContentPageComponent,
        loadChildren: () =>
          import(
            './app-modules-pages/skills/skills-data-table/skills-data-table.module'
          ).then((m) => m.SkillsDataTableModule),
      },
      {
        path: `${WHRouteZeroENUM.SKILLS}/:skillId`,
        component: WHContentPageComponent,
        canDeactivate: [CheckUnsavedCanDeactivateGuard],
        loadChildren: () =>
          import(
            './app-modules-pages/skills/skills-crud/skills-crud.module'
          ).then((m) => m.SkillsCrudModule),
      },
      {
        path: `${WHRouteZeroENUM.HR_WORKERS}/:hrWorkerId`,
        component: WHContentPageComponent,
        canDeactivate: [CheckUnsavedCanDeactivateGuard],
        loadChildren: () =>
          import(
            './app-modules-pages/hr-worker/hr-worker-crud/hr-worker-crud.module'
          ).then((m) => m.HrWorkerCrudModule),
      },
      {
        path: `${WHRouteZeroENUM.HR_WORKERS}`,
        component: WHContentPageComponent,
        loadChildren: () =>
          import(
            './app-modules-pages/hr-worker/hr-worker-data-table/hr-worker-data-table.module'
          ).then((m) => m.HrWorkerDataTableModule),
      },
      {
        path: `${WHRouteZeroENUM.SHIFT_GROUPS}`,
        component: WHContentPageComponent,
        loadChildren: () =>
          import(
            './app-modules-pages/shift-group/shift-group-data-table/shift-group-data-table.module'
          ).then((m) => m.ShiftGroupDataTableModule),
      },
      {
        path: `${WHRouteZeroENUM.SHIFT_CALENDAR}`,
        component: WHContentPageComponent,
        loadChildren: () =>
          import(
            './app-modules-pages/shift-calendar/shift-calendar-data-table/shift-calendar-data-table.module'
          ).then((m) => m.ShiftCalendarDataTableModule),
      },
      {
        path: `${WHRouteZeroENUM.SHIFT_CALENDAR}/:shiftCalendarId`,
        component: WHContentPageComponent,
        loadChildren: () =>
          import(
            './app-modules-pages/shift-calendar/shift-calendar-crud/shift-calendar-crud.module'
          ).then((m) => m.ShiftCalendarCrudModule),
      },
      {
        path: `${WHRouteZeroENUM.ABSENCE_REQUESTS}`,
        component: WHContentPageComponent,
        loadChildren: () =>
          import(
            './app-modules-pages/absencerequest/absencerequest-data-table/absencerequest-data-table.module'
          ).then((m) => m.AbsencerequestDataTableModule),
      },
      {
        path: `${WHRouteZeroENUM.ABSENCE_REQUESTS}/:absenceRequestId`,
        component: WHContentPageComponent,
        loadChildren: () =>
          import(
            './app-modules-pages/absencerequest/absencerequest-details/absencerequest-details.module'
          ).then((m) => m.AbsencerequestDetailsModule),
      },
      {
        path: WHRouteZeroENUM.MAINTENANCE_PLAN_FIX,
        component: WHContentPageComponent,
        loadChildren: () =>
          import(
            './app-pages/w-h-maintenance-plan-page-fix/w-h-maintenance-plan-page-fix.module'
          ).then((m) => m.WHMaintenancePlanPageFixModule),
      },
      {
        path: WHRouteZeroENUM.MAINTENANCE_PLAN,
        component: WHContentPageComponent,
        loadChildren: () =>
          import(
            './app-pages/w-h-maintenance-plan-page/w-h-maintenance-plan-page.module'
          ).then((m) => m.WHMaintenancePlanPageModule),
      },
      {
        path: `${WHRouteZeroENUM.MATERIALS}/:materialId`,
        component: WHContentPageComponent,
        canDeactivate: [CheckUnsavedCanDeactivateGuard],
        loadChildren: () =>
          import(
            './app-modules-pages/material/material-crud/material-crud.module'
          ).then((m) => m.MaterialCrudModule),
      },
      {
        path: `${WHRouteZeroENUM.MATERIALS}`,
        component: WHContentPageComponent,
        loadChildren: () =>
          import(
            './app-modules-pages/material/material-data-table/material-data-table.module'
          ).then((m) => m.MaterialDataTableModule),
      },
      {
        path: `${WHRouteZeroENUM.MATERIALINVENTORY}`,
        component: WHContentPageComponent,
        loadChildren: () =>
          import(
            './app-modules-pages/material/material-inventory-overview/material-inventory-overview.module'
          ).then((m) => m.MaterialInventoryOverviewModule),
      },
      {
        path: `${WHRouteZeroENUM.CUSTOMERS}/:customerId`,
        component: WHContentPageComponent,
        canDeactivate: [CheckUnsavedCanDeactivateGuard],
        loadChildren: () =>
          import(
            './app-modules-pages/customer/customer-crud/customer-crud.module'
          ).then((m) => m.CustomerCrudModule),
      },
      {
        path: `${WHRouteZeroENUM.CUSTOMERS}`,
        component: WHContentPageComponent,
        loadChildren: () =>
          import(
            './app-modules-pages/customer/customer-master-data-table/customer-master-data-table.module'
          ).then((m) => m.CustomerMasterDataTableModule),
      },
      {
        path: `${WHRouteZeroENUM.CHECKLIST_DEFINITION}/:checklistDefinitionId`,
        component: WHContentPageComponent,
        canDeactivate: [CheckUnsavedCanDeactivateGuard],
        loadChildren: () =>
          import(
            './app-modules-pages/checklist-definition/checklist-definition-crud/checklist-definition-crud.module'
          ).then((m) => m.ChecklistDefinitionCrudModule),
        // data: {
        //   apiUrl: environment.apiUrl
        // }
      },
      {
        path: `${WHRouteZeroENUM.CHECKLIST_DEFINITION}`,
        component: WHContentPageComponent,
        loadChildren: () =>
          import(
            './app-modules-pages/checklist-definition/checklist-definition-master-data-table/checklist-definition-master-data-table.module'
          ).then((m) => m.ChecklistDefinitionMasterDataTableModule),
        // data: {
        //   apiUrl: environment.apiUrl
        // }
      },
      {
        path: `${WHRouteZeroENUM.TOOLS}/:toolId`,
        component: WHContentPageComponent,
        canDeactivate: [CheckUnsavedCanDeactivateGuard],
        loadChildren: () =>
          import('./app-modules-pages/tool/tool-crud/tool-crud.module').then(
            (m) => m.ToolCrudModule,
          ),
      },
      {
        path: `${WHRouteZeroENUM.TOOLS}`,
        component: WHContentPageComponent,
        loadChildren: () =>
          import(
            './app-modules-pages/tool/tool-data-table/tool-data-table.module'
          ).then((m) => m.ToolDataTableModule),
      },
      {
        path: `${WHRouteZeroENUM.WORK_OBJECT_TEMPLATES}/:workObjectTypeId/:templateId`,
        component: WHContentPageComponent,
        canDeactivate: [CheckUnsavedCanDeactivateGuard],
        loadChildren: () =>
          import(
            './app-modules-pages/work-object/work-object-template-crud/work-object-template-crud.module'
          ).then((m) => m.WorkObjectTemplateCrudModule),
      },
      {
        path: `${WHRouteZeroENUM.WORK_OBJECT_TEMPLATES}`,
        component: WHContentPageComponent,
        loadChildren: () =>
          import(
            './app-modules-pages/work-object/work-object-template-data-table/work-object-template-data-table.module'
          ).then((m) => m.WorkObjectTemplateDataTableModule),
      },
      {
        path: `${WHRouteZeroENUM.WORKERS}/:workerId`,
        component: WHContentPageComponent,
        canDeactivate: [CheckUnsavedCanDeactivateGuard],
        loadChildren: () =>
          import(
            './app-pages/worker/worker-crud-page/worker-crud-page.module'
          ).then((m) => m.WorkerCrudPageModule),
      },
      {
        path: `${WHRouteZeroENUM.WORKERS}`,
        component: WHContentPageComponent,
        loadChildren: () =>
          import(
            './app-pages/worker/worker-management-page/worker-management-page.module'
          ).then((m) => m.WorkerManagementPageModule),
      },
      {
        path: `${WHRouteZeroENUM.PROFILE}`,
        component: WHContentPageComponent,
        loadChildren: () =>
          import('@workheld/workheld-shared-lib').then(
            (m) => m.WHProfilePageModule,
          ),
        data: {
          apiUrl: environment.apiUrl,
          pageConfig: {
            headerType: 'flow',
          } as IWHPageConfigModel,
        },
      },
      {
        path: `${WHRouteZeroENUM.DIAGNOSTICS}`,
        component: WHContentPageComponent,
        data: {
          pageConfig: {
            headerType: 'flow',
          } as IWHPageConfigModel,
        },
        children: [
          {
            path: '',
            canActivate: [DevelopmentGuard],
            component: WHDiagnosticPageComponent,
            data: {
              appVersion: workheldWebClientVersion,
              workheldSharedLibVersion,
              bryntumSchedulerLibVersion,
            },
            children: [
              {
                path: 'featureflag',
                component: WHDiagnosticPageContentComponent,
                loadChildren: () =>
                  import('@workheld/workheld-shared-lib').then(
                    (m) => m.WHFeatureFlagPageModule,
                  ),
                data: {
                  apiUrl: environment.apiUrl,
                  devFeatureConfigMap: environment.devFeatureConfigMap,
                  layoutNavItemDOMList,
                  layoutLoggedInUserNavItemDOMList,
                },
              },
              {
                path: 'i18n',
                component: WHDiagnosticPageContentComponent,
                loadChildren: () =>
                  import('@workheld/workheld-shared-lib').then(
                    (m) => m.WHI18nPageModule,
                  ),
                data: {
                  apiUrl: environment.apiUrl,
                },
              },
              {
                path: 'permissions',
                component: WHDiagnosticPageContentComponent,
                loadChildren: () =>
                  import('@workheld/workheld-shared-lib').then(
                    (m) => m.WHPermissionPageModule,
                  ),
              },
              {
                path: 'theme',
                component: WHDiagnosticPageContentComponent,
                loadChildren: () =>
                  import('@workheld/workheld-shared-lib').then(
                    (m) => m.WHThemePageModule,
                  ),
              },
              {
                path: 'layout',
                component: WHDiagnosticPageContentComponent,
                loadChildren: () =>
                  import('@workheld/workheld-shared-lib').then(
                    (m) => m.WHLayoutSettingsPageModule,
                  ),
              },
              {
                path: '',
                component: WHDiagnosticPageContentComponent,
              },
            ],
          },
        ],
      },
      {
        path: `${WHRouteZeroENUM.WORK_OBJECT_MATERIALUSAGE_REPORT}`,
        component: WHContentPageComponent,
        loadChildren: () =>
          import(
            './app-pages/material-usage-report-page/material-usage-report-page.module'
          ).then((m) => m.MaterialUsageReportPageModule),
      },
      {
        path: `${WHRouteZeroENUM.WORK_OBJECT_REPORTS_OVERVIEW}`,
        component: WHContentPageComponent,
        loadChildren: () =>
          import('./app-pages/reports-page/reports-page.module').then(
            (m) => m.ReportsPageModule,
          ),
      },
      {
        // All other routes redirect to the home page
        path: '**',
        redirectTo: `${WHRouteZeroENUM.HOME}`,
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
