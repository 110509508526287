import { Pipe, PipeTransform } from '@angular/core';
import { WHEnumDataService } from 'src/app/app-services-helper/w-h-enum-helper.service';

@Pipe({ name: 'whenum' })
export class WHEnumPipe implements PipeTransform {
  constructor(private whEnumServiceHelper: WHEnumDataService) {}
  transform(value: string): any {
    if (!value) {
      return [];
    }

    try {
      const matchingEnum = this.whEnumServiceHelper.$ENUMS()[value];
      return matchingEnum;
    } catch (error) {
      console.error(`Error fetching ENUM ${value}`, error);
      return [];
    }
  }
}
