<div class="ng-bee-list">
  <div class="list-header flex--r-c">
    <div class="list-name" *ngIf="isWithSearchHeaderIcon">
      <i
        *ngIf="!isMaterialIcon"
        class="workheld-icons list-icon"
        [innerHTML]="displayIcon"
      ></i>
      <mat-icon *ngIf="isMaterialIcon" class="list-icon">{{
        displayIcon
      }}</mat-icon>
    </div>

    <ng-bee-w-h-search-term-util
      class="m-l--8 flex-1"
      [focusElement]="true"
      (onSearchTermInput)="onSearchTermChange($event)"
    >
    </ng-bee-w-h-search-term-util>
    <div class="actions-wrapper">
      <ng-content select=".actions"></ng-content>
    </div>
  </div>

  <mat-menu #columnFilter="matMenu" yPosition="below" xPosition="before">
    <button
      class="checkbox-item mat-menu-item"
      *ngFor="let column of columns"
      (click)="toggleColumnVisibility(column, $event)"
    >
      <mat-checkbox
        [(ngModel)]="column.visible"
        class="checkbox"
        #checkbox
        (keydown.enter)="$event.stopPropagation()"
        (click)="$event.stopPropagation()"
      >
        {{ column.name }}
      </mat-checkbox>
    </button>
  </mat-menu>

  <ng-content></ng-content>
</div>
