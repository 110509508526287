import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {
  Observable,
  Subscription,
  catchError,
  first,
  map,
  throwError,
} from 'rxjs';
import {
  AbsenceRequestDataService,
  RequestResponse,
} from 'src/app/app-services-async/absencerequest.service';
import {
  ABSENCE_REQUEST_STATUS,
  IWHAbsenceApprovalDto,
  IWHAbsenceApprovalPayload,
  IWHAbsenceRequestDetailDto,
  WHLayoutConfigService,
  WHLayoutLoggedInUserDOM,
  WHNgxToastrENUM,
  WHNgxToastrService,
} from '@workheld/workheld-shared-lib';

interface AbsenceUpdateRequest {
  title: string;
  reasonText: string;
  buttonText: string;
  status: ABSENCE_REQUEST_STATUS;
  isAccept: boolean;
}

@Component({
  selector: 'app-mat-dialog-update-absence-request',
  templateUrl: './mat-dialog-update-absence-request.component.html',
  styleUrls: ['./mat-dialog-update-absence-request.component.scss'],
})
export class MatDialogUpdateAbsenceRequestComponent
  implements OnInit, OnDestroy
{
  form: FormGroup = new FormGroup({
    response: new FormControl(''),
  });
  private subscriptions: Subscription[] = [];
  loggedUserId: string = null;
  loading$: Observable<boolean>;

  absenceUpdateRequest: AbsenceUpdateRequest;
  loading: boolean = false;

  constructor(
    private absenceRequestService: AbsenceRequestDataService,
    private layoutConfigService: WHLayoutConfigService,
    private ngxToastrService: WHNgxToastrService,
    public matDialogRef: MatDialogRef<MatDialogUpdateAbsenceRequestComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      absencerequestId: string;
      requestResponse: RequestResponse;
      absenceRequest: IWHAbsenceRequestDetailDto;
    },
  ) {}

  ngOnInit() {
    if (this.data.requestResponse === RequestResponse.APPROVED) {
      this.absenceUpdateRequest = {
        title: 'absencerequest.approve.title',
        reasonText: 'absencerequest.approve.reason',
        buttonText: 'app.approve.label',
        status: ABSENCE_REQUEST_STATUS.Approved,
        isAccept: true,
      };
    } else if (this.data.requestResponse === RequestResponse.REJECTED) {
      this.absenceUpdateRequest = {
        title: 'absencerequest.reject.title',
        reasonText: 'absencerequest.reject.reason',
        buttonText: 'app.reject.label',
        status: ABSENCE_REQUEST_STATUS.Rejected,
        isAccept: false,
      };
      this.form.controls['response'].setValidators(Validators.required);
    }

    this.layoutConfigService.layoutLoggedInUserDOM$
      .pipe(first((val) => val.id !== null))
      .subscribe((layoutLoggedInUserDOM: WHLayoutLoggedInUserDOM) => {
        this.loggedUserId = layoutLoggedInUserDOM.id;
      });
  }

  confirm() {
    this.loading = true;
    const response = this.form.get('response').value;

    const payload: IWHAbsenceApprovalPayload = {
      absenceRequestId: this.data.absencerequestId,
      createdDate: new Date().toISOString(),
      createdBy: this.loggedUserId,
      status: this.absenceUpdateRequest.status,
      note: response,
    };
    const absenceRequest = structuredClone(this.data.absenceRequest);
    absenceRequest.status = this.absenceUpdateRequest.status;

    this.absenceRequestService
      .approveAbsenceRequest(payload, absenceRequest)
      .pipe(
        map((data: IWHAbsenceApprovalDto) => {
          this.matDialogRef.close(data);
          this.absenceRequestService.data._loading.next(false);
          this.ngxToastrService.displayToastr({
            toastrType: WHNgxToastrENUM.SUCCESS,
            messageTranslateKey: 'absencerequest.ui.createsuccess.notification',
          });
        }),
        catchError((err) => {
          this.absenceRequestService.data._loading.next(false);
          this.loading = false;
          return throwError(() => err);
        }),
      )
      .subscribe();
  }

  clear() {
    this.form.reset();
  }

  cancel() {
    this.matDialogRef.close(false);
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }
}
