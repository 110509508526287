// ANGULAR
import { Injectable } from '@angular/core';

// REDUX

@Injectable()
export class AppEffects {
  /**
   *
   */
  constructor() {}
}
