<form [formGroup]="form">
  <div class="detail-card-header mat-dialog-add-content">
    <span whFlex class="w-h-header-span">
      <div class="single-header">
        {{ "hr.button.add.certificate" | translate }}
      </div>
    </span>
  </div>

  <mat-divider class="wh-dialog-divider"></mat-divider>
  <div class="mat-dialog-add-content">
    <div class="flex--c-fs gap--16">
      <div class="flex--row gap--16 w-100">
        <mat-form-field>
          <mat-label>{{ "hr.model.certificate.name" | translate }}</mat-label>
          <input
            type="text"
            matInput
            formControlName="qualification"
            [matAutocomplete]="auto"
          />
          <mat-icon
            matSuffix
            *ngIf="hasQualification()"
            (keydown.enter)="clear()"
            (click)="clear()"
            >close</mat-icon
          >
          <mat-autocomplete
            #auto="matAutocomplete"
            [displayWith]="displayFn.bind(this)"
            (optionSelected)="onOptionClick($event)"
          >
            <mat-option
              *ngFor="let qualification of filteredOptions | async"
              [value]="qualification"
            >
              {{ qualification.name }}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>

        <mat-form-field>
          <mat-label>{{ "hr.model.certificate.issuer" | translate }}</mat-label>
          <input
            matInput
            placeholder="{{ 'hr.model.certificate.issuer' | translate }}"
            formControlName="issuer"
            autocomplete="off"
          />
        </mat-form-field>
      </div>
      <div class="flex--row gap--16">
        <mat-form-field>
          <mat-label>{{
            "hr.model.certificate.acquiredAt" | translate
          }}</mat-label>
          <input
            matInput
            [matDatepicker]="acquiredAt"
            formControlName="acquiredAt"
          />
          <mat-datepicker-toggle
            matIconSuffix
            [for]="acquiredAt"
          ></mat-datepicker-toggle>
          <mat-datepicker #acquiredAt></mat-datepicker>
        </mat-form-field>

        <mat-form-field>
          <mat-label>{{
            "hr.model.certificate.validDue" | translate
          }}</mat-label>
          <input
            matInput
            [min]="form.value.acquiredAt"
            [matDatepicker]="validDue"
            formControlName="validDue"
          />
          <mat-datepicker-toggle
            matIconSuffix
            [for]="validDue"
          ></mat-datepicker-toggle>
          <mat-datepicker #validDue></mat-datepicker>
        </mat-form-field>
      </div>
      <div class="flex--row gap--16">
        <div class="toggle-renewed">
          <div class="title">
            {{ "hr.model.certificate.renewed" | translate }}
          </div>
          <div class="default-value html-overflow">
            {{ "hr.model.certificate.renewdescription" | translate }}
          </div>
          <mat-slide-toggle
            color="primary"
            formControlName="renewed"
          ></mat-slide-toggle>
        </div>
      </div>
    </div>
    <div class="dialog-action-wrapper" mat-dialog-actions>
      <button
        class="w-h-default-button dialog-button mat-elevation-z2"
        mat-flat-button
        type="button"
        (click)="cancel()"
        tabindex="-1"
      >
        {{ "app.cancel.label" | translate | uppercase }}
      </button>

      <button
        color="accent"
        [disabled]="formInvalid()"
        class="dialog-button mat-elevation-z2"
        mat-flat-button
        type="button"
        (click)="confirm()"
        tabindex="-1"
      >
        {{ "app.confirm.label" | translate | uppercase }}
      </button>
    </div>
  </div>
</form>
