<div class="add-equipment-node-dialog">
  <div class="detail-card-header">
    <span whFlex class="w-h-header-icon-span">
      <i
        mat-card-avatar
        class="workheld-icons header-icon"
        [innerHtml]="equipmentIcon"
      ></i>
    </span>
    <span whFlex class="w-h-header-span">
      <div class="single-header">
        {{ "equipment.model.root" | translate }}
      </div>
    </span>
  </div>
  <div class="add-equipment-node-dialog-body">
    <!--Search Side Bar-->
    <div class="flex-2 left">
      <ng-bee-list
        [focusElement]="true"
        [isMaterialIcon]="true"
        [icon]="equipmentIcon"
        name="EQUIPMENT"
        [columns]="columns"
        (filterChange)="onFilterChange($event)"
      >
        <div class="no-data" *ngIf="resultsLength === 0 && !fetchingEquipment">
          {{ "app.nodata.label" | translate }}
        </div>
        <mat-progress-bar
          *ngIf="fetchingEquipment"
          mode="query"
        ></mat-progress-bar>
        <mat-table class="data-table" #table [dataSource]="dataSource" matSort>
          <!-- ID Column -->
          <ng-container matColumnDef="id">
            <mat-header-cell
              class="image-cell"
              *matHeaderCellDef
            ></mat-header-cell>
            <mat-cell class="image-cell" *matCellDef="let row">
              <img
                alt="avatar"
                *ngIf="
                  row?.thumbnailURL &&
                  (row?.thumbnailURL
                    | WHImgAuthHeader: equipmentPlaceholderPNG
                    | async) as image
                "
                [src]="image"
                mat-card-avatar
                class="small-avatar"
                matTooltip="{{ row?.equipmentNumber + ' ' + row?.name }}"
              />
              <i
                *ngIf="!row.thumbnailURL"
                class="workheld-icons"
                [innerHTML]="equipmentIcon"
              ></i>
            </mat-cell>
          </ng-container>

          <!-- equipmentName Column -->
          <ng-container matColumnDef="name">
            <mat-header-cell *matHeaderCellDef mat-sort-header>
              {{ column.name | translate }}</mat-header-cell
            >
            <mat-cell *matCellDef="let row">
              <div class="html-overflow">
                {{ row.name }}
              </div>
            </mat-cell>
          </ng-container>

          <mat-row
            *matRowDef="let row; columns: visibleColumns"
            (click)="selectEquipment(row)"
            (keydown.enter)="selectEquipment(row)"
            [ngClass]="{
              'active-equipment': row.id === selectedEquipmentId,
            }"
          >
          </mat-row>
        </mat-table>
        <mat-paginator
          class="paginator wh-equipment-node-paginator"
          hidePageSize
          [length]="resultsLength"
          [pageSize]="pageSize"
        >
        </mat-paginator>
      </ng-bee-list>
    </div>
    <!-- Equipment Node Selector-->
    <div class="flex-6 right">
      <div class="bomstructure-wrapper flex-1">
        @if (bomnodesLoading) {
          <div class="no-data">
            <mat-spinner [diameter]="40"></mat-spinner>
          </div>
        } @else if (!selectedEquipmentId) {
          <div class="no-data">
            {{ "equipmentplanner.selectequipment.label" | translate }}
          </div>
        } @else {
          <div class="table-top-menu">
            <span
              whFlex="calc(100% - 440px)"
              class="align-self-center"
              [ngStyle]="{
                'padding-left':
                  rootBomNodeDOM?.numChildNodes > 0 ? '80px' : '40px',
              }"
            >
              {{ "node.model.name" | translate }}
            </span>
            <span whFlex="160px" class="align-self-center">
              {{ "node.model.type" | translate }}
            </span>
            <span whFlex="90px" class="align-self-center">
              {{ "node.model.status" | translate }}
            </span>
            <span whFlex="128px" class="align-self-center">
              {{ "node.ui.numberofchildren.label" | translate }}
            </span>
          </div>
          <div>
            <mat-tree
              [dataSource]="bomNodeDataSource"
              [treeControl]="treeControl"
              class="node-tree"
            >
              <mat-tree-node *matTreeNodeDef="let node">
                <div
                  class="mat-tree-node equipment-tree-node-container matTreeNodeRow"
                  [ngClass]="{
                    selectedBomNode: selectedBomNode?.id === node?.id,
                  }"
                  (click)="selectBomNode(node)"
                  (keydown.enter)="selectBomNode(node)"
                >
                  <button
                    mat-icon-button
                    matTreeNodeToggle
                    color="accent"
                    [disabled]="node?.numChildNodes === 0"
                    (click)="toggleNode(node.id)"
                  >
                    @if (childLoading && node.id === selectedNodeId) {
                      <mat-spinner [diameter]="20"></mat-spinner>
                    } @else {
                      <i
                        class="workheld-icons mat-icon-rtl-mirror"
                        *ngIf="node?.numChildNodes !== 0"
                        [innerHTML]="
                          treeControl.isExpanded(node)
                            ? expandIcon
                            : showSnappedIcon
                        "
                      ></i>
                    }
                  </button>
                  <img
                    alt="thumbnail"
                    *ngIf="node?.thumbnailURL"
                    [attr.src]="node?.thumbnailURL | WHImgAuthHeader | async"
                    class="node-img-style"
                  />
                  <i
                    *ngIf="!node?.thumbnailURL"
                    class="workheld-icons node-icon-style"
                    color="primary"
                    [innerHTML]="node?.icon"
                  ></i>
                  <div class="node-name-with-buttons">
                    <span whFlex="calc(100% - 88px)" class="align-self-center">
                      <div>
                        <span
                          whFlex="calc(100% - 360px)"
                          class="align-self-center"
                        >
                          {{
                            node?.extId
                              ? node.extId + " - " + node.name
                              : node.name
                          }}
                        </span>
                        <span whFlex="170px" class="align-self-center">
                          {{ $ENUMS_OBJECT()?.BOM_NODE_TYPE[node?.type] }}
                        </span>
                        <span
                          whFlex="90px"
                          class="align-self-center"
                          *ngIf="node.id !== rootBomNodeId"
                        >
                          {{
                            node?.bomNodeStatus
                              ? ($ENUMS_OBJECT()?.BOM_NODE_STATUS[
                                  node?.bomNodeStatus
                                ] | titlecase)
                              : "-"
                          }}
                        </span>
                        <span
                          whFlex="90px"
                          class="align-self-center"
                          *ngIf="node.id === rootBomNodeId"
                        >
                          {{
                            node?.bomNodeStatus
                              ? ($ENUMS_OBJECT()?.EQUIPMENT_STATUS[
                                  node?.bomNodeStatus
                                ] | titlecase)
                              : "-"
                          }}
                        </span>
                        <span whFlex="128px" class="align-self-center">
                          {{
                            node?.numChildNodes && node.numChildNodes > 0
                              ? node.numChildNodes
                              : "-"
                          }}
                        </span>
                      </div>
                    </span>
                  </div>
                </div>
              </mat-tree-node>
              <!-- This is the tree node template for expandable nodes -->
              <mat-nested-tree-node *matTreeNodeDef="let node; when: hasChild">
                <div>
                  <div
                    class="mat-tree-node matTreeNodeRow"
                    [ngClass]="{
                      selectedBomNode: selectedBomNode?.id === node?.id,
                    }"
                    (click)="selectBomNode(node)"
                    (keydown.enter)="selectBomNode(node)"
                  >
                    <button
                      mat-icon-button
                      matTreeNodeToggle
                      color="accent"
                      [disabled]="node?.numChildNodes === 0"
                      (click)="toggleNode(node.id)"
                    >
                      <i
                        class="workheld-icons"
                        *ngIf="node?.numChildNodes !== 0"
                        [innerHTML]="
                          treeControl.isExpanded(node)
                            ? expandIcon
                            : showSnappedIcon
                        "
                      ></i>
                    </button>
                    <img
                      alt="thumbnail"
                      *ngIf="node?.thumbnailURL"
                      [attr.src]="node?.thumbnailURL | WHImgAuthHeader | async"
                      class="node-thumbnail-style"
                    />
                    <i
                      *ngIf="!node?.thumbnailURL"
                      class="workheld-icons m-r--16"
                      color="primary"
                      [innerHTML]="node?.icon"
                    ></i>
                    <div class="node-name-with-buttons">
                      <span
                        whFlex="calc(100% - 88px)"
                        class="align-self-center"
                      >
                        <div>
                          <span whFlex="calc(100% - 360px)">
                            {{
                              node?.extId
                                ? node.extId + " - " + node.name
                                : node.name
                            }}
                          </span>
                          <span whFlex="170px" class="align-self-center">
                            {{
                              node?.type
                                ? $ENUMS_OBJECT()?.BOM_NODE_TYPE[node?.type]
                                : "-"
                            }}
                          </span>
                          <span
                            whFlex="90px"
                            class="align-self-center"
                            *ngIf="node.id !== rootBomNodeId"
                          >
                            {{
                              node?.bomNodeStatus
                                ? ($ENUMS_OBJECT()?.BOM_NODE_STATUS[
                                    node?.bomNodeStatus
                                  ] | titlecase)
                                : "-"
                            }}
                          </span>
                          <span
                            whFlex="90px"
                            class="align-self-center"
                            *ngIf="node.id === rootBomNodeId"
                          >
                            {{
                              node?.bomNodeStatus
                                ? (EQUIPMENT_STATUS[node?.bomNodeStatus]
                                  | titlecase)
                                : "-"
                            }}
                          </span>
                          <span whFlex="128px" class="align-self-center">
                            {{
                              node?.numChildNodes && node.numChildNodes > 0
                                ? node.numChildNodes
                                : "-"
                            }}
                          </span>
                        </div>
                      </span>
                    </div>
                  </div>
                </div>
                <ul [class.node-tree-invisible]="!treeControl.isExpanded(node)">
                  <ng-container matTreeNodeOutlet></ng-container>
                </ul>
              </mat-nested-tree-node>
            </mat-tree>
          </div>
        }
      </div>
      <div class="flex--r-c-fe gap--8 p--16">
        <button
          class="w-h-default-button dialog-button mat-elevation-z2"
          mat-flat-button
          type="button"
          (click)="cancel()"
        >
          {{ "app.cancel.label" | translate | uppercase }}
        </button>
        <button
          color="accent"
          class="dialog-button mat-elevation-z2"
          [disabled]="!selectedBomNode || assigningBomNode"
          mat-flat-button
          type="button"
          (click)="confirm()"
        >
          <mat-spinner
            style="display: inline-block"
            *ngIf="assigningBomNode"
            [diameter]="18"
          >
          </mat-spinner>
          {{ "app.assign.label" | translate | uppercase }}
        </button>
      </div>
    </div>
  </div>
</div>
