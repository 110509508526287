<form [formGroup]="form" class="mat-dialog-add-shift-definition-container">
  <div class="detail-card-header mat-dialog-add-content">
    <span class="w-h-header-span">
      <div class="single-header">
        {{ "shift.label.addShiftDefinitio n" | translate }}
      </div>
    </span>
  </div>

  <mat-divider class="wh-dialog-divider"></mat-divider>
  <div class="mat-dialog-add-content">
    <p>{{ "shift.label.shiftDefinitionDescription" | translate }}</p>
    <div
      class="shift-definitions-table mat-elevation-z2 m--16"
      infinite-scroll
      [scrollWindow]="false"
      [infiniteScrollDistance]="2"
      [infiniteScrollThrottle]="50"
      (scrolled)="onScroll()"
    >
      <table
        mat-table
        matSort
        aria-describedby="shift-definitions-table"
        [dataSource]="dataSource"
      >
        <!-- Name Column -->
        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            {{ "qualifications.model.name" | translate }}
          </th>
          <td
            mat-cell
            class="default-value html-overflow"
            *matCellDef="let element"
          >
            {{ element.name }}
          </td>
        </ng-container>

        <!-- DayOfWeek Column -->
        <ng-container matColumnDef="dayOfWeek">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            {{ "shift.model.dayofweek" | translate }}
          </th>
          <td mat-cell *matCellDef="let element">
            {{ element.dayOfWeek | dayofweek | translate }}
          </td>
        </ng-container>

        <!-- Selected Column -->
        <ng-container matColumnDef="toggle">
          <th mat-header-cell *matHeaderCellDef></th>
          <td mat-cell *matCellDef="let element">
            <div class="p-l--8">
              <mat-icon
                color="accent"
                *ngIf="!selectedShiftDefinitions.includes(element)"
                >radio_button_unchecked</mat-icon
              >
              <mat-icon
                color="accent"
                *ngIf="selectedShiftDefinitions.includes(element)"
                >check_circle</mat-icon
              >
            </div>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr
          mat-row
          *matRowDef="let row; columns: displayedColumns"
          [class]="
            selectedDays.includes(row.dayOfWeek)
              ? selectedShiftDefinitions.includes(row)
                ? 'selected-row'
                : 'disabled-row'
              : null
          "
          (click)="selectRow(row)"
          (keydown.enter)="selectRow(row)"
        ></tr>
      </table>
      <div *ngIf="loading$ | async" class="p-t--16 p-b--16 flex--r-c-c">
        <mat-spinner
          style="display: inline-block"
          [diameter]="30"
        ></mat-spinner>
      </div>
      <div
        *ngIf="loaded && dataSource.data.length === 0"
        class="p-t--16 p-b--16 flex--r-c-c"
      >
        <div>{{ "app.nodata.label" | translate }}</div>
      </div>
    </div>

    <div
      *ngIf="overlappingAnotherShiftDefinition"
      class="messages-container warn m-b--16"
    >
      <div>{{ "error.formValidation.title" | translate }}</div>
      <ul>
        <li>
          <mat-error>
            {{ "error.formValidation.shiftDefinitionOverlapping" | translate }}
          </mat-error>
        </li>
      </ul>
    </div>

    <div class="flex--r-c-sb">
      <button
        class="w-h-default-button dialog-button mat-elevation-z2"
        mat-flat-button
        type="button"
        (click)="cancel()"
      >
        {{ "app.cancel.label" | translate | uppercase }}
      </button>
      <button
        color="accent"
        [disabled]="noneSelected || overlappingAnotherShiftDefinition"
        class="dialog-button mat-elevation-z2"
        mat-flat-button
        type="button"
        (click)="confirm()"
      >
        {{ "app.confirm.label" | translate | uppercase }}
      </button>
    </div>
  </div>
</form>
