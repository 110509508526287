<!-- EXPENCE Entry / entryTypeId = 3 -->
<div class="row-wrapper">
  <entry-header style="width: 100%" [entryDOM]="entryDOM"></entry-header>
</div>

<div class="row-wrapper entry-detail-row-wrapper">
  <span>
    <div class="w-h-label entry-detail-label">
      {{ "entry.expense.model.amount" | translate }}
    </div>
    <div class="default-value">
      {{
        expenceEntryDOM?.amount ? (expenceEntryDOM?.amount | number: ".2") : "-"
      }}
    </div>
  </span>
  <span>
    <div class="w-h-label entry-detail-label">
      {{ "entry.expense.model.currency" | translate }}
    </div>
    <div class="default-value">
      {{
        expenceEntryDOM?.currencyCodeISO
          ? $ENUMS_OBJECT().CURRENCY[expenceEntryDOM?.currencyCodeISO]
          : "-"
      }}
    </div>
  </span>
</div>

<div>
  <div class="w-h-label-no-margine">
    {{ "entry.model.comment" | translate }}
  </div>
  <div>
    <mat-form-field class="w-100">
      <textarea
        [disabled]="true"
        rows="2"
        matInput
        [value]="entryDOM?.comment ? entryDOM?.comment : ''"
      >
      </textarea>
    </mat-form-field>
  </div>
</div>

<ng-bee-entry-file-img-gallery
  [entryDOM]="entryDOM"
></ng-bee-entry-file-img-gallery>
