import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, forkJoin } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import {
  IWHWorkOrderFileDTO,
  WHCreateWorkOrderFileDTO,
  WHFileDOM,
  FLOW_GATEWAY,
  IWHFileDTO,
} from '@workheld/workheld-shared-lib';

@Injectable()
export class WorkOrderFileAsyncService {
  // STATE
  public maxDocumentFileSize: number = 20 * 1024 * 1024;

  constructor(private httpClient: HttpClient) {}

  public initCreateWorkOrderFileDtoArray(
    files: File[],
    workOrderId: string,
  ): WHCreateWorkOrderFileDTO[] {
    const createWorkOrderFileDtoArray: WHCreateWorkOrderFileDTO[] = [];
    files.forEach((file: File) => {
      const createWorkOrderFileDTO: WHCreateWorkOrderFileDTO =
        new WHCreateWorkOrderFileDTO(workOrderId, file);
      createWorkOrderFileDtoArray.push(createWorkOrderFileDTO);
    });
    return createWorkOrderFileDtoArray;
  }

  public uploadWorkOrderDocuments(
    workOrderCreateDtoList: WHCreateWorkOrderFileDTO[],
    filesToUpload: File[],
  ): Observable<WHFileDOM[]> {
    return this.httpClient
      .post<
        IWHWorkOrderFileDTO[]
      >(`${environment.apiUrl + FLOW_GATEWAY}/workorderfiles`, workOrderCreateDtoList)
      .pipe(
        switchMap((workOrderCreateDtoMetadataList: IWHWorkOrderFileDTO[]) => {
          const requestArray: Array<Observable<IWHFileDTO>> = new Array();
          workOrderCreateDtoMetadataList.forEach(
            (workOrderCreateDtoMetadata: IWHWorkOrderFileDTO) => {
              const file: File = filesToUpload.find((findFile: File) => {
                return (
                  findFile.name.toLowerCase() ===
                  workOrderCreateDtoMetadata.fileName.toLowerCase()
                );
              });
              const request: Observable<any> = this.uploadWorkOrderFile(
                workOrderCreateDtoMetadata.id,
                file,
              );
              requestArray.push(request);
            },
          );
          return forkJoin(requestArray).pipe(
            map((results: IWHFileDTO[]) => {
              const workOrderFileAddedDomList: WHFileDOM[] = [];
              results.forEach((workheldFileDTO: IWHFileDTO) => {
                const workOrderFileDTO: IWHWorkOrderFileDTO =
                  this.findWorkOrderFileDTO(
                    workOrderCreateDtoMetadataList,
                    workheldFileDTO,
                  );
                workOrderFileDTO.workOrderFile = workheldFileDTO;
                const workFileOrderDOM: WHFileDOM =
                  new WHFileDOM().initWorkOrderFileDOM(workOrderFileDTO);
                workOrderFileAddedDomList.push(workFileOrderDOM);
              });
              return workOrderFileAddedDomList;
            }),
          );
        }),
      );
  }

  private findWorkOrderFileDTO(
    workOrderCreateDtoMetadataList: IWHWorkOrderFileDTO[],
    workheldFileDTO: IWHFileDTO,
  ) {
    return workOrderCreateDtoMetadataList.find(
      (workOrderCreateDtoMetadataDTO: IWHWorkOrderFileDTO) => {
        return (
          workOrderCreateDtoMetadataDTO.workOrderFile.id === workheldFileDTO.id
        );
      },
    );
  }

  private uploadWorkOrderFile(
    workOrderId: string,
    file: File,
  ): Observable<IWHFileDTO> {
    const formData: FormData = new FormData();
    formData.append('file', file, file.name);
    console.log(formData);
    return this.httpClient.post<IWHFileDTO>(
      `${environment.apiUrl + FLOW_GATEWAY}/workorderfiles/${workOrderId}/files`,
      formData,
    );
  }

  public getWorkOrderFileDtoList(
    workOrderId: string,
  ): Observable<IWHWorkOrderFileDTO[]> {
    return this.httpClient.get<IWHWorkOrderFileDTO[]>(
      `${environment.apiUrl + FLOW_GATEWAY}/workOrders/${workOrderId}/workorderfiles`,
    );
  }
}
