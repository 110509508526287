<!-- SINGLE DISPLAY USE CASES -->
<div *ngIf="isSimpleData && !withTitleLabel">
  <div class="html-overflow">
    {{ workObjectDOM?.title ? workObjectDOM?.title : "-" }}
  </div>
</div>

<div *ngIf="isSimpleData && withTitleLabel">
  <div class="w-h-label html-overflow">
    {{ titleLabel | translate }}
  </div>
  <div class="html-overflow">
    {{ workObjectDOM?.title ? workObjectDOM?.title : "-" }}
  </div>
</div>

<!-- WORK OBJECT DETAIL PAGE -->
<div *ngIf="isWorkObjectDetail">
  <!-- FIRST ROW -->
  <div class="flex--r-fs gap--8">
    <div class="flex-1" style="min-width: 0">
      <div class="row-wrapper">
        <ng-bee-customer-static-data
          [withLabel]="true"
          [detailURL]="workObjectDOM?.customerDetailURL"
        >
        </ng-bee-customer-static-data>
      </div>
    </div>
    <div class="flex-1" style="padding-left: 10px; min-width: 0">
      <div [ngSwitch]="workObjectDOM?.workObjectTypeId">
        <div *ngSwitchCase="1"></div>
        <div *ngSwitchCase="2">
          <div class="row-wrapper">
            <ng-bee-w-h-agm-core-location-formatted-address
              *ngIf="isLocationEnabled"
              [withLabel]="true"
              [locationDTO]="location"
            >
            </ng-bee-w-h-agm-core-location-formatted-address>
          </div>
        </div>
        <div *ngSwitchCase="3">
          <div class="w-h-label html-overflow">
            {{ "standingorder.model.accountinginfo" | translate }}
          </div>
          <div class="html-overflow">
            {{
              workObjectDOM?.accountingInfo
                ? workObjectDOM?.accountingInfo
                : "-"
            }}
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- SECOND ROW -->
  <div class="flex--r-fs gap--8">
    <span class="flex-1" style="min-width: 0">
      <div [ngSwitch]="workObjectDOM?.workObjectTypeId">
        <div *ngSwitchCase="1"></div>
        <div *ngSwitchCase="2">
          <div class="row-wrapper">
            <div class="w-h-label html-overflow">
              {{ "case.model.sla" | translate }}
            </div>
            <div class="html-overflow">
              {{
                (SLA[workObjectDOM?.sla] ? SLA[workObjectDOM?.sla] : "-")
                  | titlecase
              }}
            </div>
          </div>
        </div>
        <div *ngSwitchCase="3"></div>
      </div>
    </span>
    <span class="flex-1" style="padding-left: 10px; min-width: 0">
      <div [ngSwitch]="workObjectDOM?.workObjectTypeId">
        <div *ngSwitchCase="1"></div>
        <div *ngSwitchCase="2">
          <div
            class="row-wrapper"
            *ngIf="CATEGORY && CATEGORY[workObjectDOM?.category]"
          >
            <div class="w-h-label html-overflow">
              {{ "workorder.model.category" | translate }}
            </div>
            <div class="html-overflow">
              {{
                CATEGORY[workObjectDOM?.category]
                  ? CATEGORY[workObjectDOM?.category]
                  : "-"
              }}
            </div>
          </div>
        </div>
        <div *ngSwitchCase="3"></div>
      </div>
    </span>
  </div>

  <!-- THIRD ROW -->
  <div class="flex--r-fs gap--8">
    <span class="flex-1" style="min-width: 0">
      <div [ngSwitch]="workObjectDOM?.workObjectTypeId">
        <div *ngSwitchCase="1">
          <div class="w-h-label workobject-desc html-overflow">
            {{ "workobject.model.description" | translate }}
          </div>
          <div>
            <mat-form-field style="width: 100%">
              <textarea
                [disabled]="true"
                rows="2"
                matInput
                [value]="
                  workObjectDOM?.description ? workObjectDOM.description : ''
                "
              >
              </textarea>
            </mat-form-field>
          </div>
        </div>
        <div *ngSwitchCase="2">
          <div class="row-wrapper">
            <div class="w-h-label html-overflow">
              {{ "case.model.priority" | translate }}
            </div>
            <div class="html-overflow">
              {{
                (PRIORITY[workObjectDOM?.priority]
                  ? PRIORITY[workObjectDOM?.priority]
                  : "-"
                ) | titlecase
              }}
            </div>
          </div>
        </div>
        <div *ngSwitchCase="3">
          <div class="w-h-label workobject-desc html-overflow">
            {{ "workobject.model.description" | translate }}
          </div>
          <div>
            <mat-form-field style="width: 100%">
              <textarea
                [disabled]="true"
                rows="2"
                matInput
                [value]="
                  workObjectDOM?.description ? workObjectDOM.description : ''
                "
              >
              </textarea>
            </mat-form-field>
          </div>
        </div>
      </div>
      <div class="row-wrapper">
        <div>
          <div class="w-h-label workobject-desc html-overflow">
            {{ "project.model.priorityscore" | translate }}
          </div>
          <p>
            {{
              workObjectDOM?.priorityScore ? workObjectDOM?.priorityScore : "-"
            }}
          </p>
        </div>
      </div>
    </span>
    <div class="flex-1" style="padding-left: 10px; min-width: 0">
      <div
        *ngIf="
          workObjectDOM?.workObjectCategory && WORK_OBJECT_CATEGORY;
          else isProj
        "
      >
        <div class="w-h-label workobject-desc html-overflow">
          {{ "case.model.category" | translate }}
        </div>
        <p>
          {{
            WORK_OBJECT_CATEGORY[workObjectDOM?.workObjectCategory]
              ? WORK_OBJECT_CATEGORY[workObjectDOM?.workObjectCategory]
              : "-"
          }}
        </p>
      </div>
      <ng-template #isProj>
        <div class="w-h-label workobject-desc html-overflow">
          {{ "case.model.category" | translate }}
        </div>
        <p>
          {{
            WORK_OBJECT_CATEGORY[workObjectDOM?.category]
              ? WORK_OBJECT_CATEGORY[workObjectDOM?.category]
              : "-"
          }}
        </p>
      </ng-template>
      <div [ngSwitch]="workObjectDOM?.workObjectTypeId">
        <div *ngSwitchCase="1"></div>
        <div *ngSwitchCase="2">
          <div class="row-wrapper">
            <ng-bee-w-h-date-static-data
              [withTimeFrameLabel]="true"
              [isWithStartDateLabel]="false"
              [isWithEndDateLabel]="false"
              [isSingleDate]="true"
              [isStartTimeVisible]="true"
              [timeFrameLabel]="'case.ui.timeframe.label'"
              [startDateLabel]="'case.model.occurrencedate'"
              [startDate]="workObjectDOM?.occurrenceDate"
              [endDateLabel]="'workobject.model.scheduledend'"
              [endDate]="workObjectDOM?.scheduledEndDate"
            >
            </ng-bee-w-h-date-static-data>
          </div>
        </div>
        <div *ngSwitchCase="3"></div>
      </div>
      @if (workObjectDOM?.executionGroupTitle) {
        <div>
          <div class="w-h-label workobject-desc html-overflow">
            {{ "app.ui.executiongroup.label" | translate }}
          </div>
          <app-w-h-chip-list
            (click)="openExecutionGroupDetails()"
            [data]="[
              { executionGroupTitle: workObjectDOM?.executionGroupTitle },
            ]"
            [tooltipKeys]="['executionGroupTitle']"
            [isStacked]="false"
            [titleKey]="'executionGroupTitle'"
          ></app-w-h-chip-list>
        </div>
      }
    </div>
  </div>

  <!-- FORTH ROW -->
  <div class="flex--r-fs gap--8">
    <span class="flex-1" style="min-width: 0">
      <div [ngSwitch]="workObjectDOM?.workObjectTypeId">
        <div *ngSwitchCase="1">
          <ng-bee-w-h-date-static-data
            [withTimeFrameLabel]="true"
            [isWithStartDateLabel]="true"
            [isWithEndDateLabel]="true"
            [timeFrameLabel]="'project.ui.timeframe.label'"
            [startDateLabel]="'project.model.start'"
            [startDate]="workObjectDOM?.startDate"
            [isStartTimeVisible]="isStartTimeVisible"
            [endDateLabel]="'project.model.end'"
            [endDate]="workObjectDOM?.endDate"
            [isEndTimeVisible]="isEndTimeVisible"
          >
          </ng-bee-w-h-date-static-data>
        </div>
        <div *ngSwitchCase="2">
          <div class="row-wrapper">
            <div class="w-h-label html-overflow">
              {{ "case.model.callerperson" | translate }}
            </div>
            <div class="html-overflow">
              {{
                workObjectDOM?.callerPerson ? workObjectDOM?.callerPerson : "-"
              }}
            </div>
          </div>
        </div>
        <div *ngSwitchCase="3">
          <ng-bee-w-h-date-static-data
            [withTimeFrameLabel]="true"
            [isWithStartDateLabel]="true"
            [isWithEndDateLabel]="true"
            [timeFrameLabel]="'standingorder.ui.timeframe.label'"
            [startDateLabel]="'standingorder.model.validfrom'"
            [startDate]="workObjectDOM?.validFrom"
            [endDateLabel]="'standingorder.model.validto'"
            [endDate]="workObjectDOM?.validTo"
          >
          </ng-bee-w-h-date-static-data>
        </div>
      </div>
    </span>
    <span class="flex-1" style="padding-left: 10px; min-width: 0">
      <div [ngSwitch]="workObjectDOM?.workObjectTypeId">
        <div *ngSwitchCase="1">
          <div>
            <mat-slide-toggle
              style="padding-left: 10px"
              [disabled]="true"
              [checked]="workObjectDOM?.sendTechnicianReminder"
            >
              {{ "workobject.ui.remindertoggle.label" | translate }}
            </mat-slide-toggle>
            <i
              class="workheld-icons w-h-info-icon"
              [innerHTML]="infoIcon"
              [matTooltip]="
                'workobject.ui.remindertoggle.helpertext' | translate
              "
            ></i>
          </div>
        </div>
        <div *ngSwitchCase="2">
          <div class="row-wrapper">
            <div class="w-h-label html-overflow">
              {{ "case.model.callerphone" | translate }}
            </div>
            <div class="html-overflow">
              {{
                workObjectDOM?.callerPhone
                  ? workObjectDOM?.callerPhone
                  : ("-" | titlecase)
              }}
            </div>
          </div>
        </div>
        <div *ngSwitchCase="3"></div>
      </div>
    </span>
  </div>
  <div
    class="flex--r-fs gap--8"
    style="margin-top: 8px"
    *ngIf="isTargetStateEnabled || isPlannedWorkEnabled"
  >
    <span class="flex-1" style="min-width: 0" *ngIf="isTargetStateEnabled">
      <div *ngIf="workObjectDOM?.targetState">
        <div class="w-h-label workobject-desc html-overflow">
          {{ "ui.model.targetState" | translate }}
        </div>
        <p>
          {{
            WORK_OBJECT_TARGET_STATE[workObjectDOM?.targetState]
              ? WORK_OBJECT_TARGET_STATE[workObjectDOM?.targetState]
              : "-"
          }}
        </p>
      </div>
    </span>
    <span
      class="flex-1"
      *ngIf="isPlannedWorkEnabled"
      style="padding-left: 10px; min-width: 0"
    >
      <div class="w-h-label workobject-desc html-overflow">
        {{ "workorder.sumtargettime.label" | translate }}
      </div>
      <p>{{ sumOfPlannedWorkTime }} min</p>
    </span>
  </div>
  <div [ngSwitch]="workObjectDOM?.workObjectTypeId" style="padding-right: 12px">
    <div *ngSwitchCase="2">
      <div class="w-h-label workobject-desc html-overflow">
        {{ "workobject.model.description" | translate }}
      </div>
      <div>
        <mat-form-field style="width: 100%">
          <textarea
            style="overflow-y: scroll"
            [disabled]="true"
            rows="2"
            matInput
            [value]="
              workObjectDOM?.description ? workObjectDOM.description : ''
            "
          >
          </textarea>
        </mat-form-field>
      </div>
    </div>
  </div>

  <!-- FIFTH ROW -->
  <div class="contact-person-detail" *ngIf="isWorkObjectActive">
    <div style="width: 100%">
      <div class="w-h-label html-overflow">
        {{ "workobject.model.contacts" | translate }}
      </div>
    </div>

    <div class="contact-box flex--r-fs flex--wrap gap--12">
      <w-h-contact-card
        *ngFor="let contact of workObjectDOM?.contacts"
        class="flex-1"
        [contactDTO]="contact"
        (openModal)="contactCrud($event)"
      ></w-h-contact-card>

      <div
        *ngIf="
          workObjectDOM?.contacts?.length === 0 &&
          workObjectDOM?.contacts?.length < 2
        "
        class="add-contact-box mat-elevation-z1 flex-1"
        (click)="contactCrud()"
      >
        {{ "contact.ui.add.label" | translate }}
      </div>
      <div
        *ngIf="
          workObjectDOM?.contacts?.length > 0 &&
          workObjectDOM?.contacts?.length < 2
        "
        class="add-contact-line mat-elevation-z1 flex-1"
        (click)="contactCrud()"
      >
        {{ "contact.ui.add.label" | translate }}
      </div>
    </div>
  </div>
</div>

<!-- SHOW ONLY TIME FRAME -->
<div *ngIf="isTimeFrame && !isWorkObjectDetail && !isSimpleData">
  <div [ngSwitch]="workObjectDOM?.workObjectTypeId">
    <div *ngSwitchCase="1">
      <ng-bee-w-h-date-static-data
        [withTimeFrameLabel]="true"
        [timeFrameLabel]="'workobject.ui.timeframe.label'"
        [startDateLabel]="'workobject.model.scheduledstart'"
        [startDate]="workObjectDOM?.scheduledStartDate"
        [endDateLabel]="'workobject.model.scheduledend'"
        [endDate]="workObjectDOM?.scheduledEndDate"
      >
      </ng-bee-w-h-date-static-data>
    </div>
    <div *ngSwitchCase="2">
      <ng-bee-w-h-date-static-data
        [withTimeFrameLabel]="true"
        [timeFrameLabel]="'workobject.ui.timeframe.label'"
        [startDateLabel]="'workobject.model.scheduledstart'"
        [startDate]="workObjectDOM?.scheduledStartDate"
        [endDateLabel]="'workobject.model.scheduledend'"
        [endDate]="workObjectDOM?.scheduledEndDate"
      >
      </ng-bee-w-h-date-static-data>
    </div>
    <div *ngSwitchCase="3">
      <ng-bee-w-h-date-static-data
        [withTimeFrameLabel]="true"
        [timeFrameLabel]="'workobject.ui.timeframe.label'"
        [startDateLabel]="'workobject.model.scheduledstart'"
        [startDate]="workObjectDOM?.scheduledStartDate"
        [endDateLabel]="'workobject.model.scheduledend'"
        [endDate]="workObjectDOM?.scheduledEndDate"
      >
      </ng-bee-w-h-date-static-data>
    </div>
  </div>
</div>
