import { FormGroup } from '@angular/forms';
import { Component, OnInit, Input } from '@angular/core';
import {
  IWHCheckDefinitionDTO,
  resultTypeEnumArray,
  WHChecklistDefinitionDataService,
  WHIconENUM,
  WHTranslationTypeENUM,
} from '@workheld/workheld-shared-lib';

@Component({
  selector: 'ng-bee-check-definition-create-edit-form',
  templateUrl: './check-definition-create-edit-form.component.html',
  styleUrls: ['./check-definition-create-edit-form.component.scss'],
})
export class CheckDefinitionCreateEditFormComponent implements OnInit {
  // INPUT DATA
  @Input() public isFormEnabled: boolean = false;
  @Input() public checkDefinitionDTO: IWHCheckDefinitionDTO;

  // DATA VAR
  @Input() public checkDefinitionFormGroup: FormGroup;
  @Input() public isExpanded: boolean;

  // STATE
  public labelPosition: 'before' | 'after' = 'before';

  // DEP VAR
  public resultTypes: Array<number> = resultTypeEnumArray;
  ENUMS = WHTranslationTypeENUM;

  constructor(
    private checklistDefinitionDataService: WHChecklistDefinitionDataService,
  ) {}

  public ngOnInit(): void {
    if (this.checkDefinitionDTO) {
      this.checkDefinitionFormGroup =
        this.checklistDefinitionDataService.initCheckDefinitionFormGroup();
      this.checkDefinitionDTO.id
        ? this.checkDefinitionFormGroup.patchValue(this.checkDefinitionDTO)
        : console.log();
      this.isFormEnabled
        ? this.checkDefinitionFormGroup.enable()
        : this.checkDefinitionFormGroup.disable();
    }
  }

  public get sortPosition() {
    if (this.checkDefinitionFormGroup) {
      return (this.checkDefinitionFormGroup.controls['sortPosition'].value +
        1) as number;
    } else {
      return null;
    }
  }

  public get checkedIcon(): string {
    return WHIconENUM.CheckedIcon as string;
  }
}
