<section class="w-h-image-preview-mat-dialog">
  <div class="w-h-image-preview-header flex--r-s-sb">
    <span>
      <i class="workheld-icons img-icon" [innerHtml]="previewIcon"></i>
      <span class="file-meta-data">
        {{ data.image.fileName }}{{ data.image.extension }}
      </span>
    </span>

    <span>
      <i
        class="workheld-icons download-img-icon"
        [innerHtml]="downloadIcon"
        (click)="downloadImageFile()"
        (keydown.enter)="downloadImageFile()"
      ></i>
      <i
        class="workheld-icons close-preview-icon"
        [innerHtml]="closeIcon"
        (click)="closeDialog()"
        (keydown.enter)="closeDialog()"
      ></i>
    </span>
  </div>
  <mat-divider class="wh-dialog-divider"></mat-divider>
  <div class="w-h-image-preview-body">
    <div>
      <img
        class="image-preview"
        alt="preview"
        *ngIf="data.image.file | WHImgAuthHeader | async as image; else loading"
        [src]="image"
      />
      <ng-template #loading>
        <!-- Loading... -->
        <div style="text-align: center">
          <mat-spinner
            [diameter]="20"
            style="display: inline-block"
          ></mat-spinner>
        </div>
      </ng-template>
    </div>
  </div>
  <div class="w-h-image-preview-footer" *ngIf="data?.comment">
    {{ data?.comment }}
  </div>
</section>
