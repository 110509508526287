import { Component, Inject } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Observable, map, startWith } from 'rxjs';
import { IWHSkillDTO } from '@workheld/workheld-shared-lib';
import { SkillToQualificationDOM } from 'src/app/app-services-async/hr-worker-data.service';

@Component({
  selector: 'app-mat-dialog-add-skill',
  templateUrl: './mat-dialog-add-skill.component.html',
  styleUrls: ['./mat-dialog-add-skill.component.scss'],
})
export class MatDialogAddSkillComponent {
  skills$: Observable<IWHSkillDTO[]>;
  filteredOptions: Observable<IWHSkillDTO[]>;
  skill: string = '';
  existingSkills: SkillToQualificationDOM[];

  constructor(
    public matDialogRef: MatDialogRef<MatDialogAddSkillComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      skills$: Observable<IWHSkillDTO[]>;
      existingSkills: SkillToQualificationDOM[];
    },
  ) {}

  ngOnInit() {
    this.skills$ = this.data.skills$;
    this.existingSkills = this.data.existingSkills;

    this.skills$.subscribe((skills) => {
      this.filteredOptions = this.form.controls.skill.valueChanges.pipe(
        startWith(''),
        map((mapValue) => {
          const name = typeof mapValue === 'string' ? mapValue : mapValue?.name;
          const existingSkillIds = this.existingSkills.map(
            (existingSkill) => existingSkill.skill.id,
          );
          skills = skills.filter(
            (skill) => !existingSkillIds.includes(skill.id),
          );
          return skills.filter((option) =>
            option?.name.toLowerCase().includes(name.toLowerCase()),
          );
        }),
      );
    });
  }

  hasSkill() {
    return this.form.value.skill;
  }
  form = new FormGroup({
    skill: new FormControl(null, Validators.required),
  });

  confirm() {
    this.matDialogRef.close(this.form.value.skill);
  }

  clear() {
    this.form.value.skill = null;
    this.skill = '';
  }

  cancel() {
    this.matDialogRef.close(false);
  }

  get validPayload() {
    return typeof this.form.value.skill === 'object';
  }

  displayFn(skill: IWHSkillDTO): string {
    return skill?.name ? skill.name : '';
  }
}
