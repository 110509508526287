import { EventEditConfig } from '@bryntum/schedulerpro';

export const getEventEditorConfig = (): Partial<EventEditConfig> => {
  return {
    editorConfig: {
      items: {
        customTabBar: {
          type: 'tabpanel',
          weight: 50,
          items: [
            {
              title: 'L{TranslateService.teamplanner.popup.tab.helpertext}',
            },
          ],
        },
        nameField: null,
        resourceField: {
          readOnly: true,
          disabled: true,
          label: 'L{TranslateService.nav.equipment.root}',
        },
        endDateField: {
          disabled: true,
          readOnly: true,
        },
        endTimeField: {
          disabled: true,
          readOnly: true,
        },
        confirmedField: {
          hidden: true,
          type: 'checkbox',
          label: 'L{TranslateService.workobject.model.confirmed}',
          name: 'confirmed',
          weight: 700,
        },
        fixedField: {
          hidden: true,
          type: 'checkbox',
          label: 'L{TranslateService.workobject.model.fixed}',
          name: 'fixed',
          weight: 800,
        },
      },
      bbar: {
        items: {
          saveButton: {
            color: 'b-custom',
            text: 'L{TranslateService.app.save.label}',
            weight: 1000,
          },
          deleteButton: null,
          cancelButton: {
            weight: 1000,
            text: 'L{TranslateService.app.cancel.label}',
          },
        },
      },
    },
  };
};
