import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {
  Observable,
  Subscription,
  catchError,
  map,
  tap,
  throwError,
} from 'rxjs';
import {
  ShiftGroupDataService,
  validateStartAndEndDate,
} from 'src/app/app-services-async/shift-group-data.service';
import { FormReferenceService } from 'src/app/app-services-helper/form-reference.service';
import {
  DateTimeService,
  IWHShiftModelDTO,
  NumberValidator,
  WHNgxToastrENUM,
  WHNgxToastrService,
  materialStatusIdEnumArray,
  noWhitespaceValidator,
} from '@workheld/workheld-shared-lib';
import { deriveTimeFormat } from 'time-input-formatter';

export interface SelectTime {
  time: string;
}

@Component({
  selector: 'app-mat-dialog-add-shift-model',
  templateUrl: './mat-dialog-add-shift-model.component.html',
})
export class MatDialogAddShiftModelComponent implements OnInit {
  loading$: Observable<boolean>;
  private subscriptions: Subscription[] = [];
  statusIdEnumArray: string[] = materialStatusIdEnumArray;

  timeIntervals: string[] = [];

  shiftModelsFormGroup: FormGroup = new FormGroup(
    {
      id: new FormControl(null),
      extId: new FormControl(null),
      name: new FormControl('', [Validators.required, noWhitespaceValidator]),
      status: new FormControl('ACTIVE'),
      shiftModelGroupId: new FormControl(null),
      startTime: new FormControl('', [
        Validators.required,
        Validators.pattern(/^([0-1]?\d|2[0-3]):[0-5]\d$/),
      ]),
      endTime: new FormControl('', [
        Validators.required,
        Validators.pattern(/^([0-1]?\d|2[0-3]):[0-5]\d$/),
      ]),
      breakDuration: new FormControl(0, [
        Validators.required,
        NumberValidator.number({ min: 0, max: 1440 }),
      ]),
    },
    { validators: validateStartAndEndDate() },
  );

  constructor(
    private formReferenceService: FormReferenceService,
    private dateTimeHelperService: DateTimeService,
    private shiftGroupService: ShiftGroupDataService,
    private ngxToastrService: WHNgxToastrService,
    public matDialogRef: MatDialogRef<MatDialogAddShiftModelComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      shiftGroupId: string;
      shiftModel: IWHShiftModelDTO;
    },
  ) {}

  ngOnInit(): void {
    this.loading$ = this.shiftGroupService.data.loading$;

    if (this.data.shiftModel) {
      this.shiftModelsFormGroup.patchValue(this.data.shiftModel);
    }

    this.timeIntervals =
      this.dateTimeHelperService.initTimeOptionDropdownArray(30);

    this.subscriptions.push(
      this.shiftModelsFormGroup.valueChanges.subscribe((_) => {
        this.formReferenceService.unsavedChanges =
          this.shiftModelsFormGroup.dirty;
      }),
    );

    //Temporary fix for startTime and endTime validation for the most common case
    //That you edit the start time first and then the end time
    this.subscriptions.push(
      this.shiftModelsFormGroup.get('endTime').valueChanges.subscribe((_) => {
        // update validity but avoid too much recursion
        this.shiftModelsFormGroup.get('startTime').updateValueAndValidity();
      }),
    );
  }

  onBlur(event, timeType: string) {
    const control = this.shiftModelsFormGroup.get(timeType);
    if (control) {
      const formattedTime = deriveTimeFormat(control.value, '24hm');
      if (formattedTime.valid) {
        control.setValue(formattedTime.value);
      }
    }
  }

  confirm() {
    const tempShiftModel = structuredClone(this.shiftModelsFormGroup.value);

    this.shiftGroupService
      .updateShiftModel(this.data.shiftGroupId, tempShiftModel)
      .pipe(
        map((data: IWHShiftModelDTO) => {
          this.matDialogRef.close(data);
          this.shiftGroupService.data._loading.next(false);
          this.ngxToastrService.displayToastr({
            toastrType: WHNgxToastrENUM.SUCCESS,
            messageTranslateKey: 'shiftmodel.ui.updatesuccess.notification',
          });
        }),
        catchError((err) => {
          this.shiftGroupService.data._loading.next(false);
          return throwError(() => err);
        }),
      )
      .subscribe();
  }

  cancel() {
    if (this.shiftModelsFormGroup.dirty) {
      const ref = this.formReferenceService.createDialog();
      ref
        .pipe(
          tap((canDiscard) => {
            if (canDiscard) {
              this.matDialogRef.close(false);
              this.formReferenceService.unsavedChanges = false;
            }
          }),
        )
        .subscribe();
    } else {
      this.matDialogRef.close(false);
    }
  }
}
