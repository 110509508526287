<div class="modal-wrapper">
  <div class="modal-header">
    <mat-icon>search_off</mat-icon>
    <div class="modal-body">
      <h2>{{ "app.ui.equipmentchange.label" | translate }}</h2>
      <p>
        {{ "app.ui.equipmentchange.warning" | translate }}
      </p>
    </div>
  </div>
  <div class="modal-actions">
    <button
      type="button"
      mat-flat-button
      class="w-h-default-button project-crud-button mat-elevation-z2"
      (click)="closeDialog(true)"
    >
      {{ "ui.button.changeEquipment" | translate }}
    </button>

    <button
      type="button"
      mat-flat-button
      color="accent"
      (click)="closeDialog(false)"
      class="project-crud-button mat-elevation-z2"
    >
      {{ "ui.button.cancelChange" | translate }}
    </button>
  </div>
</div>
