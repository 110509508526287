<!-- TRAVEL Entries / entryTypeId = 1 -->
<div class="row-wrapper">
  <entry-header
    class="w-100"
    [showCreatedFor]="true"
    [entryDOM]="entryDOM"
  ></entry-header>
</div>
<div class="row-wrapper entry-detail-row-wrapper">
  <span>
    <div class="w-h-label entry-detail-label">
      {{ "entry.travel.model.startdate" | translate }}
    </div>
    <div class="default-value">
      {{ travelEntryDOM?.startDate | date: "shortDate" : "" : currentLocale }}
    </div>
  </span>
  <span>
    <div class="w-h-label entry-detail-label">
      {{ "entry.travel.model.starttime" | translate }}
    </div>
    <div class="default-value">
      {{ travelEntryDOM?.startDate | date: "mediumTime" : "" : currentLocale }}
    </div>
  </span>
  <span>
    <div class="w-h-label entry-detail-label">
      {{ "entry.travel.model.startlocation" | translate }}
    </div>
    <div class="default-value">
      {{ travelEntryDOM?.startLocation ? travelEntryDOM?.startLocation : "-" }}
    </div>
  </span>
  <span>
    <div class="w-h-label entry-detail-label">
      {{ "entry.travel.model.startcountry" | translate }}
    </div>
    <div class="default-value">
      {{
        travelEntryDOM?.countryCodeISOStart
          ? $ENUMS_OBJECT().COUNTRY[travelEntryDOM?.countryCodeISOStart]
          : "-"
      }}
    </div>
  </span>
</div>
<div class="row-wrapper entry-detail-row-wrapper">
  <span>
    <div class="w-h-label entry-detail-label">
      {{ "entry.travel.model.enddate" | translate }}
    </div>
    <div class="default-value">
      {{ travelEntryDOM?.endDate | date: "shortDate" : "" : currentLocale }}
    </div>
  </span>
  <span>
    <div class="w-h-label entry-detail-label">
      {{ "entry.travel.model.endtime" | translate }}
    </div>
    <div class="default-value">
      {{ travelEntryDOM?.endDate | date: "mediumTime" : "" : currentLocale }}
    </div>
  </span>
  <span>
    <div class="w-h-label entry-detail-label">
      {{ "entry.travel.model.endlocation" | translate }}
    </div>
    <div class="default-value">
      {{ travelEntryDOM?.endLocation ? travelEntryDOM?.endLocation : "-" }}
    </div>
  </span>
  <span>
    <div class="w-h-label entry-detail-label">
      {{ "entry.travel.model.endcountry" | translate }}
    </div>
    <div class="default-value">
      {{ $ENUMS_OBJECT().COUNTRY[travelEntryDOM?.countryCodeISOEnd] }}
    </div>
  </span>
</div>
<div class="row-wrapper entry-detail-row-wrapper">
  <span>
    <div class="w-h-label entry-detail-label">
      {{ "entry.travel.model.startdistance" | translate }}
    </div>
    <div class="default-value">
      {{
        travelEntryDOM?.startKilometer ? travelEntryDOM?.startKilometer : "-"
      }}
    </div>
  </span>
  <span>
    <div class="w-h-label entry-detail-label">
      {{ "entry.travel.model.enddistance" | translate }}
    </div>
    <div class="default-value">
      {{ travelEntryDOM?.endKilometer ? travelEntryDOM?.endKilometer : "-" }}
    </div>
  </span>
</div>
<div class="row-wrapper entry-detail-row-wrapper">
  <span>
    <div class="w-h-label entry-detail-label">
      {{ "entry.travel.model.meansoftravel" | translate }}
    </div>
    <div class="default-value">
      {{
        travelEntryDOM?.meansOfTravel
          ? ($ENUMS_OBJECT().MEANS_OF_TRAVEL[travelEntryDOM?.meansOfTravel]
            | titlecase)
          : "-"
      }}
    </div>
  </span>
  <span>
    <div class="w-h-label entry-detail-label">
      {{ "entry.travel.model.isdriver" | translate }}
    </div>
    <div class="default-value">
      {{ "app.boolean." + travelEntryDOM?.isDriver | translate }}
    </div>
  </span>
  <span>
    <div class="w-h-label entry-detail-label">
      {{ "entry.travel.model.duration" | translate }}
    </div>
    <div class="default-value">
      {{
        travelEntryDOM?.durationMinutes || travelEntryDOM?.durationMinutes === 0
          ? (travelEntryDOM?.durationMinutes | duration)
          : "-"
      }}
    </div>
  </span>
</div>

<div>
  <div class="w-h-label-no-margine">
    {{ "entry.model.comment" | translate }}
  </div>
  <div>
    <mat-form-field class="w-100">
      <textarea
        [disabled]="true"
        rows="2"
        matInput
        [value]="entryDOM?.comment ? entryDOM?.comment : ''"
      >
      </textarea>
    </mat-form-field>
  </div>
</div>

<ng-bee-entry-file-img-gallery
  [entryDOM]="entryDOM"
></ng-bee-entry-file-img-gallery>
