import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';
import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnDestroy, OnInit, signal } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AuthService, AuthClientConfig } from '@auth0/auth0-angular';
import {
  WHDomEvent,
  WHLayoutConfigService,
  WHLayoutConfigDOM,
  WHLayoutLanguageDOM,
  WHLayoutLoggedInUserDOM,
  WHLayoutLogoDOM,
  WHLayoutNavItemDOM,
  WHThemeConfigService,
  WHLoginDataService,
  WHPermissionKeyENUM,
  WHDepartmentDataService,
  MGMT_GATEWAY,
  IWHDepartmentDTO,
  WHDepartmentDOM,
  getAccessTokenFromLocalStorage,
  WHFeatureKeyENUM,
} from '@workheld/workheld-shared-lib';
import { of, Subscription } from 'rxjs';
import { map, switchMap, take } from 'rxjs/operators';
import {
  WHRouteBehaviorSubjectService,
  WHWorkObjectBackButton,
} from './app-services-helper/w-h-route-behavior-subject.service';
import { helpNavDOM, quickNavDOM, workheldCallConnect$ } from './app.config';
import { UIService } from './app-services/ui.service';

@Component({
  selector: 'app-layout',
  templateUrl: './app-layout.component.html',
})
export class AppLayoutComponent implements OnInit, OnDestroy {
  public quickNavItemDOM: WHLayoutNavItemDOM = quickNavDOM;
  public helpNavItemDOM: WHLayoutNavItemDOM = helpNavDOM;

  // VAR
  public permissions: string[] = [];
  public accessToken: string;

  // LAYOUT VAR
  public layoutConfigDOM: WHLayoutConfigDOM;
  public layoutNavItemDOMList: WHLayoutNavItemDOM[] = [];
  public layoutLanguageDOMList: WHLayoutLanguageDOM[];
  public layoutLoggedInUserDOM: WHLayoutLoggedInUserDOM;
  public layoutLoggedInUserNavItemDOMList: WHLayoutNavItemDOM[] = [];
  public layoutLogoDOM: WHLayoutLogoDOM;

  // SUBSCRIPTION
  private subscription: Subscription = new Subscription();
  isWhSenseEnabled: boolean;
  isSmallScreen = signal(false);
  layoutMobileNavItemDOMList = [];

  constructor(
    private router: Router,
    private authService: AuthService,
    private layoutConfigService: WHLayoutConfigService,
    private themeConfigService: WHThemeConfigService,
    private translateService: TranslateService,
    private routeBehaviorSubjectService: WHRouteBehaviorSubjectService,
    private loginDataService: WHLoginDataService,
    private departmentDataService: WHDepartmentDataService,
    authClientConfig: AuthClientConfig,
    private uiService: UIService,
    @Inject(DOCUMENT) private document: Document,
  ) {
    const token = getAccessTokenFromLocalStorage(
      authClientConfig.get().clientId,
    );
    if (token?.body) {
      this.accessToken = token.body.access_token;
    }
  }

  ngOnInit(): void {
    // CHECK IF TENANT HAS ABILITY TO SWITCH BETWEEN CALL AND FLOW
    const enableCallSwitch = this.quickNavItemDOM.children.some((c) =>
      c.routeUrl.includes(environment.callUrl),
    );

    if (enableCallSwitch) {
      this.quickNavItemDOM.children.find((c) =>
        c.routeUrl.includes(environment.callUrl),
      ).enabled =
        this.loginDataService.activeTenantDOM$.value.customization.showNavBarModuleSwitcher;
    }

    this.isSmallScreen = this.uiService.$isSmallScreen;

    this.subscription.add(
      this.loginDataService.featureConfigMap$
        .pipe(
          map((features: Map<string, boolean>) => {
            this.isWhSenseEnabled = features.get(
              WHFeatureKeyENUM.QUICKNAV_WHSENSE,
            );

            this.quickNavItemDOM.children.find(
              (c) => c.translateKey === 'app.whsense.label',
            ).enabled = this.isWhSenseEnabled;
          }),
        )
        .subscribe(),
    );

    this.subscription.add(
      this.loginDataService.permissions$.subscribe((permissions: string[]) => {
        this.permissions = permissions;
      }),
    );

    this.subscription.add(
      this.departmentDataService
        .getDepartmentDTOList({
          apiUrl: environment.apiUrl + MGMT_GATEWAY,
        })
        .subscribe((departments: IWHDepartmentDTO[]) => {
          const departmentDOMList: WHDepartmentDOM[] = [];
          departments.forEach((departmentDTO: IWHDepartmentDTO) => {
            departmentDOMList.push(new WHDepartmentDOM(departmentDTO));
          });
          this.departmentDataService.departments$.next(departmentDOMList);
        }),
    );

    // LAYOUT CONFIG
    this.subscription.add(
      this.layoutConfigService.layoutConfigDOM$.subscribe(
        (layoutConfigDOM: WHLayoutConfigDOM) => {
          this.layoutConfigDOM = layoutConfigDOM;
        },
      ),
    );

    // NAVIGATION ITEMS
    this.subscription.add(
      this.layoutConfigService.layoutNavItemDOMList$.subscribe(
        (layoutNavItemDOMList: WHLayoutNavItemDOM[]) => {
          if (this.coordinatorAccess) {
            this.layoutNavItemDOMList = layoutNavItemDOMList;
            this.layoutMobileNavItemDOMList = this.mobileNavItems();
          }
          // for poc
          // this.layoutNavItemDOMList = layoutNavItemDOMList as WHLayoutNavItemDOM[];
        },
      ),
    );

    // LANGUAGE
    this.subscription.add(
      this.layoutConfigService.layoutLanguageDOMList$.subscribe(
        (layoutLanguageDOMList: WHLayoutLanguageDOM[]) => {
          this.layoutLanguageDOMList = layoutLanguageDOMList;
        },
      ),
    );

    // LOGGED IN USER
    this.subscription.add(
      this.layoutConfigService.layoutLoggedInUserDOM$.subscribe(
        (layoutLoggedInUserDOM: WHLayoutLoggedInUserDOM) => {
          this.layoutLoggedInUserDOM = layoutLoggedInUserDOM;
        },
      ),
    );

    // LOGGED IN USER NAV ITEMS
    this.subscription.add(
      this.layoutConfigService.layoutLoggedInUserNavItemDOMList$.subscribe(
        (layoutLoggedInUserNavItemDOMList: WHLayoutNavItemDOM[]) => {
          this.layoutLoggedInUserNavItemDOMList =
            layoutLoggedInUserNavItemDOMList;
        },
      ),
    );

    // LOGO
    this.subscription.add(
      this.layoutConfigService.layoutLogoDOM$.subscribe(
        (layoutLogoDOM: WHLayoutLogoDOM) => {
          this.layoutLogoDOM = layoutLogoDOM;
        },
      ),
    );
  }

  public get coordinatorAccess() {
    return this.permissions.includes(
      WHPermissionKeyENUM.FLOW_COORDINATOR_ACCESS,
    );
  }

  public async onDomChildEvent($event: WHDomEvent): Promise<void> {
    switch ($event.type) {
      case 'logoClick':
        this.router.navigate(['/']);
        return;
      case 'quickItemClick':
        if (($event.payload as WHLayoutNavItemDOM).routeUrl) {
          window.open(($event.payload as WHLayoutNavItemDOM).routeUrl, '_self');
        }
        return;
      case 'navigate':
        this.routeBehaviorSubjectService.workObjectBackButtonBehaviorSubject.next(
          new WHWorkObjectBackButton(),
        );
        this.routeBehaviorSubjectService.workOrderStateBehaviorSubject.next(
          new WHWorkObjectBackButton(),
        );
        return;
      case 'languageChange':
        localStorage.setItem('whlang', $event.payload.key);
        this.translateService.setDefaultLang($event.payload.key);
        return;
      case 'toggle':
        if (
          ($event.payload as WHLayoutNavItemDOM).featureKey ===
          'WHFeatureKeyENUM.DARK'
        ) {
          this.themeConfigService.dark$.next(
            ($event.payload as WHLayoutNavItemDOM).checked,
          );
        }
        if (
          ($event.payload as WHLayoutNavItemDOM).featureKey ===
          'WHFeatureKeyENUM.CONNECT'
        ) {
          workheldCallConnect$.next(
            ($event.payload as WHLayoutNavItemDOM).checked,
          );
        }
        return;
      case 'logout':
        this.authService.logout({
          logoutParams: {
            returnTo: this.document.location.origin,
            client_id: environment.auth0Config.clientId,
            federated: true,
          },
        });
        return;
      default:
        return;
    }
  }

  private loading(loading: boolean): any {
    return this.authService.isLoading$.pipe(
      switchMap((innerLoading: boolean) => {
        if (loading) {
          return this.loading(innerLoading);
        }
        return of(innerLoading);
      }),
      take(1),
    );
  }

  public mobileNavItems() {
    const items = this.layoutNavItemDOMList.slice(0, 6).map((item) => ({
      ...item,
      showParent: item.children.some((child) => child.enabled),
    }));

    const menuItems = [{ translateKey: '', children: items }];

    return menuItems;
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
