<form [formGroup]="shiftModelsFormGroup" class="flex--r-c flex--wrap gap--16">
  <mat-form-field class="flex-1">
    <mat-label>{{ "shift.model.name" | translate }}</mat-label>
    <input matInput formControlName="name" required />
  </mat-form-field>

  <mat-form-field class="flex-1">
    <mat-label>{{ "shift.model.breakDuration" | translate }}</mat-label>
    <input matInput formControlName="breakDuration" required />
  </mat-form-field>

  <lib-w-h-dropdown
    [valueLabel]="'shift.model.startTime'"
    [required]="true"
    [valueFormControl]="shiftModelsFormGroup.get('startTime')"
    (onDomEvent)="onBlur($event, 'startTime')"
    [dropdownItemDomList]="timeIntervals"
    [loading]="false"
  >
  </lib-w-h-dropdown>

  <lib-w-h-dropdown
    [valueLabel]="'shift.model.endTime'"
    [required]="true"
    [valueFormControl]="shiftModelsFormGroup.get('endTime')"
    (onDomEvent)="onBlur($event, 'endTime')"
    [dropdownItemDomList]="timeIntervals"
    [loading]="false"
  >
  </lib-w-h-dropdown>

  <mat-form-field class="flex-1">
    <mat-label>{{ "shift.model.status" | translate }}</mat-label>
    <mat-select formControlName="status">
      <mat-option *ngFor="let statusId of statusIdEnumArray" [value]="statusId">
        {{ "material.enum.status." + statusId | translate }}
      </mat-option>
    </mat-select>
  </mat-form-field>

  <mat-icon
    *ngIf="canDelete"
    (click)="deleteShiftModel()"
    (keydown.enter)="deleteShiftModel()"
    >delete</mat-icon
  >
</form>
