// ANGULAR
import { Component, Inject, OnDestroy } from '@angular/core';

// ANGULAR MATERIAL
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

// SERVICES
import { WHFileAsyncService } from 'src/app/app-services-async/w-h-file-async.service';

// RxJS 6
import { Subscription } from 'rxjs';

// MODELS
import { IWHFileDTO, WHIconENUM } from '@workheld/workheld-shared-lib';

@Component({
  selector: 'ng-bee-mat-dialog-image-preview-entry',
  templateUrl: './mat-dialog-image-preview-entry.component.html',
  styleUrls: ['./mat-dialog-image-preview-entry.component.scss'],
})
export class MatDialogImagePreviewEntryComponent implements OnDestroy {
  // MANAGE SUBSCRIPTIONS
  private subscriptions: Subscription[] = [];

  constructor(
    public matDialogRef: MatDialogRef<MatDialogImagePreviewEntryComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: { image: IWHFileDTO; comment: string },
    private workheldFileAsyncService: WHFileAsyncService,
  ) {}

  public downloadImageFile(): void {
    this.subscriptions.push(
      this.workheldFileAsyncService
        .downloadWHFileBlob(this.data.image)
        .subscribe((fileBlob: Blob) => {
          this.workheldFileAsyncService.saveFile(this.data.image, fileBlob);
        }),
    );
  }

  public closeDialog(): void {
    this.matDialogRef.close();
  }

  public get previewIcon(): string {
    return WHIconENUM.GalleryPhotoIcon;
  }

  public get downloadIcon(): string {
    return WHIconENUM.DownloadIcon;
  }

  public get closeIcon(): string {
    return WHIconENUM.CancelIcon;
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription: Subscription) => {
      subscription.unsubscribe();
    });
  }
}
