<form [formGroup]="form" class="mat-dialog-add-skill-container">
  <div class="detail-card-header mat-dialog-add-content">
    <span whFlex class="w-h-header-span">
      <div class="single-header">
        {{ "hr.button.add.skill" | translate }}
      </div>
    </span>
  </div>
  <mat-divider class="wh-dialog-divider"></mat-divider>
  <div class="mat-dialog-add-content">
    <div class="flex--r-fs gap--16">
      <div class="flex--col flex--grow gap--16">
        <mat-form-field>
          <mat-label>{{ "nav.skills.root" | translate }}</mat-label>
          <input
            type="text"
            matInput
            [(ngModel)]="skill"
            formControlName="skill"
            [matAutocomplete]="auto"
          />
          <mat-icon matSuffix *ngIf="hasSkill()" (click)="clear()"
            >close</mat-icon
          >
          <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn">
            <mat-option
              *ngFor="let skill of filteredOptions | async"
              [value]="skill"
            >
              {{ skill.name }}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
      </div>
    </div>
    <div class="dialog-action-wrapper" mat-dialog-actions>
      <button
        class="w-h-default-button dialog-button mat-elevation-z2"
        mat-flat-button
        type="button"
        (click)="cancel()"
        tabindex="-1"
      >
        {{ "app.cancel.label" | translate | uppercase }}
      </button>

      <button
        color="accent"
        [disabled]="!validPayload"
        class="dialog-button mat-elevation-z2"
        mat-flat-button
        type="button"
        (click)="confirm()"
        tabindex="-1"
      >
        {{ "app.confirm.label" | translate | uppercase }}
      </button>
    </div>
  </div>
</form>
