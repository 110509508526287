<form [formGroup]="shiftModelsFormGroup">
  <div class="detail-card-header mat-dialog-add-content">
    <span whFlex class="w-h-header-span">
      <div class="single-header">
        {{ "shift.label.updateShiftModel" | translate }}
      </div>
    </span>
  </div>

  <mat-divider class="wh-dialog-divider"></mat-divider>
  <div class="mat-dialog-add-content">
    <app-shift-model-fields
      [shiftModelsFormGroup]="shiftModelsFormGroup"
    ></app-shift-model-fields>

    <div
      *ngIf="shiftModelsFormGroup.errors"
      class="messages-container warn m--8"
    >
      <div>{{ "error.formValidation.title" | translate }}</div>
      <ul>
        <li *ngIf="shiftModelsFormGroup.errors?.overlappingTime">
          <mat-error>
            {{ "error.formValidation.overlappingError" | translate }}
          </mat-error>
        </li>
        <li *ngIf="shiftModelsFormGroup.errors?.isSameHour">
          <mat-error>
            {{ "error.formValidation.sameHourShiftError" | translate }}
          </mat-error>
        </li>
      </ul>
    </div>
    <div class="dialog-action-wrapper" mat-dialog-actions>
      <button
        class="w-h-default-button dialog-button mat-elevation-z2"
        mat-flat-button
        type="button"
        (click)="cancel()"
      >
        {{ "app.cancel.label" | translate | uppercase }}
      </button>

      <button
        color="accent"
        [disabled]="shiftModelsFormGroup.invalid"
        class="dialog-button mat-elevation-z2"
        mat-flat-button
        type="button"
        (click)="confirm()"
      >
        <mat-spinner
          style="display: inline-block"
          *ngIf="loading$ | async"
          [diameter]="18"
        >
        </mat-spinner>
        {{ "app.confirm.label" | translate | uppercase }}
      </button>
    </div>
  </div>
</form>
