<mat-card class="checklist-definition-detail-card">
  <div
    class="detail-card-header checklist-definition-detail-card-header"
    *ngIf="displayHeader"
  >
    <span whFlex="100" style="min-width: 0">
      <i
        mat-card-avatar
        class="workheld-icons header-icon"
        [innerHtml]="checklistDefinitionIcon"
      ></i>
      <div class="header-content-wrapper">
        <div
          class="header-ext-id"
          matTooltip="{{ checklistDefinitionDOM?.extId }}"
        >
          {{ checklistDefinitionDOM?.extId }}
        </div>
        <div
          class="header-title"
          matTooltip="{{ checklistDefinitionDOM?.title }}"
        >
          {{ checklistDefinitionDOM?.title }}
        </div>
      </div>
    </span>
  </div>
  <div class="checklist-definition-detail-card-body">
    <div class="row-wrapper flex--r-c-sb" *ngIf="displayHeaderInBody">
      <div class="flex-1">
        <div class="w-h-label html-overflow">
          {{ "checklistdefinition.model.title" | translate }}
        </div>
        <div class="html-overflow default-value">
          {{ checklistDefinitionDOM?.title }}
        </div>
      </div>
      <div class="flex-1">
        <div class="w-h-label html-overflow">
          {{ "checklistdefinition.model.extid" | translate }}
        </div>
        <div class="html-overflow default-value">
          {{ checklistDefinitionDOM?.extId }}
        </div>
      </div>
    </div>
    <div>
      <div class="w-h-label ch-def-description-label html-overflow">
        {{ "checklistdefinition.model.description" | translate }}
      </div>
      <div>
        <mat-form-field whFlex>
          <textarea
            [disabled]="true"
            [rows]="descriptionRows"
            matInput
            [value]="
              checklistDefinitionDOM?.description
                ? checklistDefinitionDOM?.description
                : ''
            "
          >
          </textarea>
        </mat-form-field>
      </div>
    </div>
    <div class="flex-1 flex--r-c-r m-t--24 m-b--16 m-r--16">
      <mat-slide-toggle
        color="accent"
        [checked]="isExpanded"
        (change)="toggleExpand()"
      >
        {{ "checklistdefinition.ui.expandedView" | translate }}
      </mat-slide-toggle>
    </div>
    <div class="check-definition-array-wrapper">
      <div>
        <div *ngFor="let checkDefinitionDTO of checkDefinitionArray">
          <ng-bee-check-definition-create-edit-form
            [isExpanded]="isExpanded"
            [isFormEnabled]="false"
            [checkDefinitionDTO]="checkDefinitionDTO"
          >
          </ng-bee-check-definition-create-edit-form>
        </div>
      </div>
      <div>
        <div
          class="section-element mat-elevation-z1"
          *ngFor="let sectionDOM of sectionsArray"
        >
          <div>
            <div class="ch-section-header flex--r-c-sb">
              <div style="max-width: 85%">
                <div class="w-h-label html-overflow">
                  {{ "checklistdefinition.model.section.title" | translate }}
                </div>
                <div class="html-overflow default-value">
                  {{ sectionDOM.title }}
                </div>
              </div>
              <div style="align-self: center; text-align: end">
                <mat-slide-toggle
                  [checked]="sectionDOM?.defaultEnabled"
                  [disabled]="true"
                  >{{
                    "checklistdefinition.model.section.defaultenabled"
                      | translate
                  }}</mat-slide-toggle
                >
              </div>
            </div>
            <div>
              <div
                *ngFor="let checkDefinitionDTO of getSectionChecks(sectionDOM)"
              >
                <div style="width: 100%" class="p-l--8">
                  <ng-bee-check-definition-create-edit-form
                    [isFormEnabled]="false"
                    [checkDefinitionDTO]="checkDefinitionDTO"
                  >
                  </ng-bee-check-definition-create-edit-form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="detail-actions-wrapper flex--r-c-sb" *ngIf="displayActionButtons">
    <w-h-back-button (click)="backToOverview()" />
    <div>
      <button
        *appIsAllowed="{
          permission: 'flow.coordinator.checklist_template.write',
        }"
        mat-flat-button
        (click)="copyChecklistDefinition()"
        class="w-h-default-button mat-elevation-z2 delete-button"
      >
        <i class="workheld-icons" [innerHTML]="copyChecklistDefinitionIcon"></i>
        {{ "checklistdefinition.ui.duplicate.label" | translate | uppercase }}
      </button>
      <button
        *appIsAllowed="{
          permission: 'flow.coordinator.checklist_template.delete',
        }"
        [disabled]="deleting"
        mat-flat-button
        (click)="deleteChecklistDefinition()"
        class="w-h-default-button mat-elevation-z2 delete-button"
      >
        <mat-spinner
          style="display: inline-block"
          *ngIf="deleting"
          [diameter]="18"
        >
        </mat-spinner>
        <mat-icon
          *ngIf="!deleting"
          svgIcon="{{
            checklistDefinitionDOM.status === 'ACTIVE'
              ? deactivateIcon
              : activateIcon
          }}"
        ></mat-icon>
        {{
          (checklistDefinitionDOM.status === "ACTIVE"
            ? "app.deactivate.label"
            : "app.activate.label"
          )
            | translate
            | uppercase
        }}
      </button>
      <button
        *appIsAllowed="{
          permission: 'flow.coordinator.checklist_template.write',
        }"
        mat-flat-button
        color="accent"
        (click)="editChecklistDefinition()"
        class="mat-elevation-z2"
        [disabled]="deleting"
      >
        <i class="workheld-icons" [innerHTML]="editIcon"></i>
        {{ "app.edit.label" | translate | uppercase }}
      </button>
    </div>
  </div>
</mat-card>
