// ANGULAR
import { Injectable } from '@angular/core';

// ANGULAR MATERIAL
import {
  MatDialogRef,
  MatDialog,
  MatDialogConfig,
} from '@angular/material/dialog';

// MAT DIALOG
// ENTRY COMPONENTS
import { MatDialogConfirmEntryComponent } from './mat-dialog-confirm-entry/mat-dialog-confirm-entry.component';
import { MatDialogNgxImageCropperEntryComponent } from './mat-dialog-ngx-image-cropper-entry/mat-dialog-ngx-image-cropper-entry.component';
import { MatDialogImagePreviewEntryComponent } from './mat-dialog-image-preview-entry/mat-dialog-image-preview-entry.component';
import { MatDialogWorkStepCreateEditFormEntryComponent } from './mat-dialog-work-step-create-edit-form-entry/mat-dialog-work-step-create-edit-form-entry.component';
import { MatDialogWorkStepSchedulerEntryComponent } from './mat-dialog-work-step-scheduler-entry/mat-dialog-work-step-scheduler-entry.component';
import { MatDialogChecklistDefinitionAssignmentEntryComponent } from './mat-dialog-checklist-definition-assignment-entry/mat-dialog-checklist-definition-assignment-entry.component';
import { MatDialogCreateWorkObjectTemplateComponent } from './mat-dialog-create-work-object-template/mat-dialog-create-work-object-template.component';
import { MatDialogUseWorkObjectTemplateEntryComponent } from './mat-dialog-use-work-object-template-entry/mat-dialog-use-work-object-template-entry.component';
import { MatDialogEntryDetailEntryComponent } from './mat-dialog-entry-detail-entry/mat-dialog-entry-detail-entry.component';
import { MatDialogChecklistInstanceDetailEntryComponent } from './mat-dialog-checklist-instance-detail-entry/mat-dialog-checklist-instance-detail-entry.component';
import { MatDialogCommentEntryComponent } from './mat-dialog-comment-entry/mat-dialog-comment-entry.component';
import { MatDialogPreviewEntryComponent } from './mat-dialog-preview-entry/mat-dialog-preview-entry.component';
import { MatDialogCreateReportEntryComponent } from './mat-dialog-create-report-entry/mat-dialog-create-report-entry.component';
import { MatDialogContactCustomerEntryComponent } from './mat-dialog-contact-customer-entry/mat-dialog-contact-customer-entry.component';
import { MatDialogWorkObjectRejectEntryComponent } from './mat-dialog-workobject-reject-entry/mat-dialog-workobject-reject-entry.component';
import { MatDialogContactPrincipalEntryComponent } from './mat-dialog-contact-principal-entry/mat-dialog-contact-principal-entry.component';
import { MatDialogWorkObjectPauseResumeEntryComponent } from './mat-dialog-workobject-pause-resume-entry/mat-dialog-workobject-pause-resume-entry.component';

// MODELS
import {
  CertificateDTO,
  WHWorkStepDOM,
  IWHAbsenceRequestDetailDto,
  WHEntryDOM,
  IWHFileDTO,
  IWHFlowContactDTO,
  IWHQualificationsDTO,
  IWHShiftDefinitionDTO,
  IWHShiftGroupDTO,
  IWHShiftModelDTO,
  IWHSkillDTO,
  WHWorkOrderDOM,
} from '@workheld/workheld-shared-lib';

import { MatDialogAddCertificateComponent } from './mat-dialog-add-certificate/mat-dialog-add-certificate.component';
import { Observable } from 'rxjs';
import { MatDialogAddSkillComponent } from './mat-dialog-add-skill/mat-dialog-add-skill.component';
import { MatDialogAddShiftGroupComponent } from './mat-dialog-add-shift-group/mat-dialog-add-shift-group.component';
import { MatDialogAddShiftModelsComponent } from './mat-dialog-add-shift-models/mat-dialog-add-shift-models.component';
import { MatDialogAddShiftModelComponent } from './mat-dialog-add-shift-model/mat-dialog-add-shift-model.component';
import { MatDialogAddShiftDefinitionComponent } from './mat-dialog-add-shift-definition/mat-dialog-add-shift-definition.component';
import { MatDialogUpdateAbsenceRequestComponent } from './mat-dialog-update-absence-request/mat-dialog-update-absence-request.component';
import { RequestResponse } from '../app-services-async/absencerequest.service';
import { MatDialogAddEquipmentNodeComponent } from './mat-dialog-add-equipment-node/mat-dialog-add-equipment-node.component';
import { MatDialogContactEntryComponent } from './mat-dialog-contact-entry/mat-dialog-contact-entry.component';
import { SkillToQualificationDOM } from '../app-services-async/hr-worker-data.service';
import { ResumeReasonModalComponent } from './resume-reason-modal/resume-reason-modal.component';

export class WHWorkStepCreateEditDialogActionData {
  actionType: 'CREATE' | 'EDIT' | 'DELETE';
  workStepDOM: WHWorkStepDOM;
}

// CONST
export const CONFIRM_DEFAULT_CODE: string = 'DEFAULT';

@Injectable()
export class AppDialogService {
  constructor(private matDialog: MatDialog) {}

  public openWorkObjectRejectDialog(config?: any): MatDialogRef<{}, any> {
    return this.matDialog.open(
      MatDialogWorkObjectRejectEntryComponent,
      config as MatDialogConfig,
    );
  }

  public createReport(config?: any): MatDialogRef<{}, any> {
    return this.matDialog.open(
      MatDialogCreateReportEntryComponent,
      config as MatDialogConfig,
    );
  }

  public openContactMatDialog(config: any): MatDialogRef<{}, any> {
    return this.matDialog.open(
      MatDialogContactEntryComponent,
      config as MatDialogConfig,
    );
  }

  // PREVIEW
  public openPreviewMatDialog(data: {
    workstep: WHWorkStepDOM;
    type: string;
    targetId: string;
    disableUi: boolean;
  }): MatDialogRef<{}, any> {
    return this.matDialog.open(MatDialogPreviewEntryComponent, {
      width: '95%',
      panelClass: 'material-preview-dialog',
      data,
      autoFocus: 'dialog',
      closeOnNavigation: true,
      disableClose: true,
    } as MatDialogConfig);
  }

  // CHECKLIST DEFINITION ASSIGNMENT
  public openCommentMatDialog(comment: string): MatDialogRef<{}, any> {
    return this.matDialog.open(MatDialogCommentEntryComponent, {
      width: '40%',
      data: {
        comment,
      },
      closeOnNavigation: true,
      disableClose: false,
    } as MatDialogConfig);
  }

  // CHECKLIST DEFINITION ASSIGNMENT
  public openChecklistDefinitionAssignmentMatDialog(
    checklistDefinitionId?: string,
    calculatedWorkStepStatusId?: number,
    isAssignable: boolean = false,
  ): MatDialogRef<{}, any> {
    return this.matDialog.open(
      MatDialogChecklistDefinitionAssignmentEntryComponent,
      {
        maxWidth: '98vw',
        width: '100%',
        data: {
          checklistDefinitionId,
          calculatedWorkStepStatusId,
          isAssignable,
        },
        closeOnNavigation: true,
        disableClose: true,
      } as MatDialogConfig,
    );
  }

  // CHECKLIST INSTANCE DETAIL VIEW
  public openChecklistInstanceDetailMatDialog(
    checklistInstanceId?: string,
  ): MatDialogRef<{}, any> {
    return this.matDialog.open(MatDialogChecklistInstanceDetailEntryComponent, {
      width: '75%',
      data: {
        checklistInstanceId,
      },
      closeOnNavigation: true,
      disableClose: true,
    } as MatDialogConfig);
  }

  public openWorkStepCreateEditFormMatDialog(
    workOrderId: string,
    workStepDOM: WHWorkStepDOM,
  ): MatDialogRef<{}, any> {
    return this.matDialog.open(MatDialogWorkStepCreateEditFormEntryComponent, {
      width: '75%',
      data: { workStepDOM, workOrderId },
      closeOnNavigation: true,
      disableClose: true,
    } as MatDialogConfig);
  }

  public openEntryDetailMatDialog(
    entryDOM: WHEntryDOM & { workObjectStatusId?: number },
  ): MatDialogRef<{}, any> {
    return this.matDialog.open(MatDialogEntryDetailEntryComponent, {
      width: '75%',
      data: { entryDOM },
      closeOnNavigation: true,
      disableClose: true,
    } as MatDialogConfig);
  }

  public openConfirmMatDialog(
    code: string = CONFIRM_DEFAULT_CODE,
    isPlural: boolean = false,
    isOk: boolean = false,
    customButton?: string,
  ): MatDialogRef<{}, any> {
    return this.matDialog.open(MatDialogConfirmEntryComponent, {
      width: '50%',
      data: {
        translateCode: code,
        isPlural,
        isOk,
        customButton,
      },
    } as MatDialogConfig);
  }

  public openResumeReasonModal(): MatDialogRef<{}, any> {
    return this.matDialog.open(ResumeReasonModalComponent, {
      width: '30%',
      data: {},
    } as MatDialogConfig);
  }

  public openImageCropperMatDialog(data: {
    imageChangedEvent: any;
  }): MatDialogRef<{}, any> {
    return this.matDialog.open(MatDialogNgxImageCropperEntryComponent, {
      width: '75%',
      data,
    } as MatDialogConfig);
  }

  public openWHFilePreviewDialog(
    data: { file: IWHFileDTO; comment: string },
    matDialogConfig?: MatDialogConfig,
  ): MatDialogRef<{}, any> {
    if (!data.file.downloadFile) {
      return undefined;
    }
    switch (data.file.mimeType) {
      // case 'application/pdf':
      //   return this.openPdfPreviewDialog(data.file);
      // case 'application/msword':
      // case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
      //   return 'assets/img/FileWordDark.png';
      // case 'application/excel':
      // case 'application/vnd.ms-excel':
      // case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
      //   return 'assets/img/FileExcelDark.png';
      // default:
      //   return 'assets/img/FileDark.png';
      case 'image/png':
      case 'image/jpeg':
        return this.openImagePreviewDialog(data, matDialogConfig);
      default:
        return undefined;
    }
  }

  // IMPROVE - move file to matDialogConfig
  private openImagePreviewDialog(
    data: { file: IWHFileDTO; comment: string },
    matDialogConfig: MatDialogConfig = {
      width: '50%',
    },
  ): MatDialogRef<{}, any> {
    return this.matDialog.open(MatDialogImagePreviewEntryComponent, {
      width: matDialogConfig.width,
      data: {
        image: data.file,
        comment: data.comment,
      },
    } as MatDialogConfig);
  }

  // WORK OBJECT TEMPLATE
  public openCreateWorkObjectTemplateDialog(
    workObjectId: string,
    workOrderDomList?: WHWorkOrderDOM[],
  ) {
    return this.matDialog.open(MatDialogCreateWorkObjectTemplateComponent, {
      width: '50%',
      data: { workObjectId, workOrderDomList },
    } as MatDialogConfig);
  }

  public openUseWorkObjectTemplateDialog(workObjectId: string) {
    return this.matDialog.open(MatDialogUseWorkObjectTemplateEntryComponent, {
      width: '75%',
      data: { workObjectId },
    } as MatDialogConfig);
  }

  // private openPdfPreviewDialog(file: IWHFileDTO): MatDialogRef<{}, any> {
  //   return this.matDialog.open(
  //     NgxExtendedPdfViewerUtilMatDialogComponent, {
  //     data: { pdfFile: file },
  //     width: '920px',
  //   }
  //   );
  // }

  public openWorkStepSchedulerDialog(workOrderId: any): MatDialogRef<{}, any> {
    return this.matDialog.open(MatDialogWorkStepSchedulerEntryComponent, {
      data: { workOrderId },
      width: '50%',
    });
  }

  public openContactCustomerDialog(
    workObjectTypeId: number,
    workObjectId: string,
    contacts: IWHFlowContactDTO[],
  ) {
    return this.matDialog.open(MatDialogContactCustomerEntryComponent, {
      data: {
        workObjectTypeId,
        workObjectId,
        contacts,
      },
      width: '50%',
    } as MatDialogConfig);
  }

  public openContactPrincipalDialog(workObjectId: string) {
    return this.matDialog.open(MatDialogContactPrincipalEntryComponent, {
      data: {
        workObjectId,
      },
      width: '50%',
    });
  }

  public openWorkObjectPauseResumeConfirmDialog(isWorkObjectPaused: boolean) {
    return this.matDialog.open(MatDialogWorkObjectPauseResumeEntryComponent, {
      data: {
        isWorkObjectPaused,
      },
      width: '50%',
    });
  }

  public openCertificateAddDialog(
    qualifications$: Observable<IWHQualificationsDTO[]>,
    certificateDto: CertificateDTO,
  ) {
    return this.matDialog.open(MatDialogAddCertificateComponent, {
      data: {
        qualifications$: qualifications$,
        certificateDto: certificateDto,
      },
    });
  }

  public openSkillAddDialog(
    skills$: Observable<IWHSkillDTO[]>,
    existingSkills: SkillToQualificationDOM[],
  ) {
    return this.matDialog.open(MatDialogAddSkillComponent, {
      data: {
        skills$: skills$,
        existingSkills: existingSkills,
      },
    });
  }

  public openAddShiftGroupDialog(shiftGroup: IWHShiftGroupDTO) {
    return this.matDialog.open(MatDialogAddShiftGroupComponent, {
      disableClose: true,
      data: {
        shiftGroup: shiftGroup,
      },
    });
  }

  public openAddShiftModelsDialog(shiftGroup: IWHShiftGroupDTO) {
    return this.matDialog.open(MatDialogAddShiftModelsComponent, {
      data: {
        shiftGroup: shiftGroup,
      },
    });
  }

  public openAddShiftModelDialog(
    shiftGroupId: string,
    shiftModel: IWHShiftModelDTO,
  ) {
    return this.matDialog.open(MatDialogAddShiftModelComponent, {
      data: {
        shiftGroupId: shiftGroupId,
        shiftModel: shiftModel,
      },
    });
  }

  public openAddShiftDefinitionDialog(
    departmentId?: string,
    shiftDefinitions?: IWHShiftDefinitionDTO[],
  ) {
    return this.matDialog.open(MatDialogAddShiftDefinitionComponent, {
      data: {
        departmentId: departmentId,
        shiftDefinitions: shiftDefinitions,
      },
    });
  }

  public updateUpdateAbsenceRequest(
    absencerequestId: string,
    requestResponse: RequestResponse,
    absenceRequest: IWHAbsenceRequestDetailDto,
  ) {
    return this.matDialog.open(MatDialogUpdateAbsenceRequestComponent, {
      data: {
        absencerequestId: absencerequestId,
        requestResponse: requestResponse,
        absenceRequest: absenceRequest,
      },
    });
  }

  public addEquipmentNode(customerId: string, workStepId: string) {
    return this.matDialog.open(MatDialogAddEquipmentNodeComponent, {
      data: {
        customerId: customerId,
        workStepId: workStepId,
      },
      height: '800px',
      maxWidth: '98vw !important',
    });
  }
}
