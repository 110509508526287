import {
  AfterViewInit,
  Component,
  Inject,
  OnInit,
  signal,
  ViewChild,
} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTreeNestedDataSource } from '@angular/material/tree';
import { NestedTreeControl } from '@angular/cdk/tree';
import {
  BehaviorSubject,
  Subscription,
  catchError,
  first,
  map,
  merge,
  startWith,
  switchMap,
  throwError,
} from 'rxjs';
import { ListColumn } from 'src/app/app-modules-layout/list/list-column.model';
import { EquipmentAsyncService } from 'src/app/app-services-async/equipment-async.service';
import {
  WHNestedBomNodeDOM,
  list_to_tree,
} from 'src/app/w-h-module/w-h-bom-node-crud-form/w-h-nested-node.model';
import { environment } from 'src/environments/environment';
import {
  FLOW_GATEWAY,
  IWHBomNodeDTO,
  IWHBomNodeEndpointConfigModel,
  IWHBomNodeFlexPropertyDTO,
  IWHChildNodePayload,
  IWHEquipmentDTO,
  IWHWorkStepDTO,
  WHAssetsENUM,
  WHBomNodeDataService,
  WHFeatureKeyENUM,
  WHIconENUM,
  WHLoginDataService,
  WHNgxToastrENUM,
  WHNgxToastrService,
} from '@workheld/workheld-shared-lib';
import { WorkStepAsyncService } from 'src/app/app-services-async/work-step-async.service';
import { WHWorkStepCreateEditDialogActionData } from '../app-dialog.service';
import { sortFlexProperties } from 'src/app/app-services/form.service';
import { MatTableDataSource } from '@angular/material/table';
import { WHEnumDataService } from 'src/app/app-services-helper/w-h-enum-helper.service';

@Component({
  selector: 'app-mat-dialog-add-equipment-node',
  templateUrl: './mat-dialog-add-equipment-node.component.html',
  styleUrls: ['./mat-dialog-add-equipment-node.component.scss'],
})
export class MatDialogAddEquipmentNodeComponent
  implements OnInit, AfterViewInit
{
  // DOM
  @ViewChild(MatPaginator, { static: true }) public paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) public sort: MatSort;

  dataSource = new MatTableDataSource<IWHEquipmentDTO>([]);
  resultsLength: number;
  rootBomNodeDOM: IWHBomNodeDTO;

  selectedBomNode: IWHBomNodeDTO;
  selectedEquipmentId: string;

  fetchingEquipment: boolean = false;
  assigningBomNode: boolean = false;

  // ENUMS
  public EQUIPMENT_STATUS: any;
  public BOM_NODE_TYPE: any;
  public BOM_NODE_STATUS: any;
  public enums: any = {};

  // SKILL MANAGEMENT
  hasSkillManagementEnabled: boolean = false;

  nodesMap: Map<string, WHNestedBomNodeDOM> = new Map();
  nodeTree: WHNestedBomNodeDOM;
  bomNodeDataSource2: MatTreeNestedDataSource<WHNestedBomNodeDOM> =
    new MatTreeNestedDataSource<WHNestedBomNodeDOM>();
  bomNodeDataSource = new MatTreeNestedDataSource<WHNestedBomNodeDOM>();
  flexProperties2DArray: Array<IWHBomNodeFlexPropertyDTO[]> = [[]];
  treeControl = new NestedTreeControl<WHNestedBomNodeDOM>(
    (node) => node.children,
  );
  equipmentNodeId: string = undefined;
  isAssignable: boolean = false;
  pageSize = 13;
  searchTerm = new BehaviorSubject('');
  subscriptions = new Subscription();

  public hasChild = (_: number, node: WHNestedBomNodeDOM) =>
    !!node.children && node.children.length > 0;

  columns: ListColumn[] = [
    { name: '', property: 'id', visible: true },
    {
      name: 'equipment.model.name',
      property: 'name',
      visible: true,
      isModelProperty: true,
    },
  ] as ListColumn[];
  childLoading: boolean;
  selectedNodeId: string;
  bomnodesLoading: boolean;
  $ENUMS_OBJECT = signal(null);

  constructor(
    private ngxToastrService: WHNgxToastrService,
    private bomNodeDataService: WHBomNodeDataService,
    private equipmentAsyncService: EquipmentAsyncService,
    private workStepAsyncService: WorkStepAsyncService,
    private loginDataService: WHLoginDataService,
    public matDialogRef: MatDialogRef<MatDialogAddEquipmentNodeComponent>,
    private whEnumDataService: WHEnumDataService,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      customerId: string;
      workStepId: string;
    },
  ) {}
  ngAfterViewInit(): void {
    this.dataSource.paginator = this.paginator;
  }

  ngOnInit(): void {
    this.$ENUMS_OBJECT = this.whEnumDataService.$ENUMS_OBJECT;
    if (!this.data.customerId) {
      return;
    }

    this.hasSkillManagementEnabled =
      this.loginDataService.featureConfigMap$.value.get(
        WHFeatureKeyENUM.SKILL_MANAGEMENT,
      );

    this.subscriptions.add(
      merge(this.searchTerm, this.paginator.page)
        .pipe(
          startWith(''),
          switchMap(() => {
            this.fetchingEquipment = true;
            return this.equipmentAsyncService.getEquipmentList(
              this.data.customerId,
              {
                page: this.searchTerm.value ? 0 : this.paginator.pageIndex,
                pageSize: this.pageSize,
                term: this.searchTerm.value,
              },
            );
          }),
          map((res) => {
            this.fetchingEquipment = false;
            this.dataSource = new MatTableDataSource(res.equipments);
            if (this.searchTerm.value) this.paginator.pageIndex = 0;
            this.resultsLength = res.numTotalEntries;
          }),
        )
        .subscribe(),
    );
  }

  get visibleColumns() {
    return this.columns
      .filter((column) => column.visible)
      .map((column) => column.property);
  }

  selectBomNode(bomNode: IWHBomNodeDTO) {
    this.selectedBomNode = bomNode;
  }

  selectEquipment(equipment: IWHEquipmentDTO) {
    // unselect bom node
    this.selectedBomNode = undefined;
    this.selectedEquipmentId = equipment?.id;
    this.bomnodesLoading = true;
    const bomNodeEndpointConfig = {
      apiUrl: environment.apiUrl + FLOW_GATEWAY,
      bomNodeId: equipment.rootBomNodeId,
    };
    this.subscriptions.add(
      this.bomNodeDataService
        .getBomNodeDTO(bomNodeEndpointConfig, this.hasSkillManagementEnabled)
        .pipe(
          first(),
          catchError((err) => {
            this.bomnodesLoading = false;
            return throwError(() => err);
          }),
        )
        .subscribe((bomNodeDTO) => {
          this.bomnodesLoading = false;
          this.rootBomNodeDOM = bomNodeDTO;
          this.rootBomNodeDOM.flexProperties = sortFlexProperties(
            bomNodeDTO.flexProperties,
          );

          this.nodeTree = new WHNestedBomNodeDOM(this.rootBomNodeDOM);
          this.nodesMap = new Map().set(this.rootBomNodeDOM.id, this.nodeTree);

          this.bomNodeDataSource.data = [this.nodeTree];

          let j = 0;
          for (let i = 0; i < this.rootBomNodeDOM.flexProperties.length; i++) {
            if (i !== 0 && i % 4 == 0) {
              this.flexProperties2DArray.push([]);
              j++;
            }
            this.flexProperties2DArray[j].push(
              this.rootBomNodeDOM.flexProperties[i],
            );
          }
        }),
    );
  }

  public toggleNode(nodeId: string) {
    this.childLoading = true;
    this.selectedNodeId = nodeId;
    const childEndpointConfig: IWHBomNodeEndpointConfigModel = {
      apiUrl: environment.apiUrl + FLOW_GATEWAY,
      bomNodeId: nodeId,
    };
    const params: IWHChildNodePayload = {
      withSkills: this.hasSkillManagementEnabled,
      includeDecommissioned: false,
    };
    this.subscriptions.add(
      this.bomNodeDataService
        .getBomChildNodes(childEndpointConfig, params)
        .pipe(first())
        .subscribe((children) => {
          children.forEach((bomNodeDTO: IWHBomNodeDTO) => {
            this.nodesMap.set(
              bomNodeDTO.id,
              new WHNestedBomNodeDOM(bomNodeDTO),
            );
          });
          this.childLoading = false;
          this.nodeTree = list_to_tree(Array.from(this.nodesMap.values()))[0];
          this.bomNodeDataSource.data = null;
          this.bomNodeDataSource.data = [this.nodeTree];
        }),
    );
  }

  onFilterChange(filter) {
    this.searchTerm.next(filter);
  }

  confirm() {
    this.assigningBomNode = true;
    const workStepId = this.data?.workStepId;
    const payload = {
      id: workStepId,
      bomNodeId: this.selectedBomNode.id,
    } as IWHWorkStepDTO;

    if (this.selectedBomNode?.parentNodeId) {
      // Assign BomNode to WorkStep
      this.workStepAsyncService.updateWorkStep(workStepId, payload).subscribe({
        next: (res) => {
          this.successCloseDialog(res);
        },
        error: (err) => {
          this.assigningBomNode = false;
          throwError(() => err);
        },
      });
    } else {
      //Assign Equipment to WorkStep
      this.subscriptions.add(
        this.workStepAsyncService
          .assignToWorkStep('EQUIPMENT', workStepId, this.selectedEquipmentId)
          .pipe(first())
          .subscribe({
            next: (res) => {
              this.successCloseDialog(res);
            },
            error: (err) => {
              this.assigningBomNode = false;
              throwError(() => err);
            },
          }),
      );
    }
  }

  successCloseDialog(response: any) {
    this.ngxToastrService.displayToastr({
      toastrType: WHNgxToastrENUM.SUCCESS,
      messageTranslateKey: 'workstep.ui.updatesuccess.notification',
    });

    this.assigningBomNode = false;
    this.matDialogRef.close({
      actionType: 'EDIT',
      workStepDOM: response,
    } as WHWorkStepCreateEditDialogActionData);
  }

  cancel() {
    this.matDialogRef.close();
  }

  get equipmentIcon(): string {
    return WHIconENUM.EquipmentIcon as string;
  }

  get saveIcon(): string {
    return WHIconENUM.SaveIcon as string;
  }

  get cancelIcon(): string {
    return WHIconENUM.CancelIcon as string;
  }

  get personImgJPG(): string {
    return WHAssetsENUM.personImgJPG as string;
  }

  public get expandIcon(): string {
    return WHIconENUM.ExpandIcon as string;
  }

  public get showSnappedIcon(): string {
    return WHIconENUM.ShowSnappedIcon as string;
  }
}
