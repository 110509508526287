<form
  [formGroup]="shiftModelsFormGroup"
  autocomplete="off"
  class="mat-dialog-add-shift-model-container"
>
  <div class="detail-card-header mat-dialog-add-content">
    <span whFlex class="w-h-header-span">
      <div class="single-header">
        {{ "shift.button.addShiftModels" | translate }}
      </div>
    </span>
  </div>
  <mat-divider class="wh-dialog-divider"></mat-divider>
  <div class="mat-dialog-add-content">
    <div>
      <ng-container formArrayName="shiftModels">
        <ng-container
          *ngFor="let shiftModel of shiftModels.controls; let i = index"
          class="shift-models-container"
        >
          <app-shift-model-fields
            [shiftModelsFormGroup]="getModelByIndex(i)"
            [canDelete]="true"
            (onDelete)="deleteShiftModel(i)"
          ></app-shift-model-fields>
        </ng-container>
        <div *ngIf="shiftModels.controls.length === 0">
          {{ "shift.label.noShiftModels" | translate }}
        </div>
        <div class="flex--r-c-r flex-1 p-t--16 p-b--16">
          <button
            class="w-h-default-button dialog-button mat-elevation-z2"
            mat-flat-button
            type="button"
            (click)="addShiftModel()"
          >
            {{ "shift.button.addShiftModel" | translate }}
          </button>
        </div>
        <div *ngIf="shiftModels.errors" class="messages-container warn m--8">
          <div>{{ "error.formValidation.title" | translate }}</div>
          <ul>
            <li *ngIf="shiftModels.errors?.duplicateName">
              <mat-error>
                {{ "error.formValidation.duplicateName" | translate }}
              </mat-error>
            </li>
            <li *ngIf="shiftModels.errors?.overlappingTime">
              <mat-error>
                {{ "error.formValidation.overlappingError" | translate }}
              </mat-error>
            </li>
            <li *ngIf="shiftModels.errors?.isSameHour">
              <mat-error>
                {{ "error.formValidation.sameHourShiftError" | translate }}
              </mat-error>
            </li>
          </ul>
        </div>
      </ng-container>
    </div>
    <div class="dialog-action-wrapper" mat-dialog-actions>
      <button
        class="w-h-default-button dialog-button mat-elevation-z2"
        mat-flat-button
        type="button"
        (click)="cancel()"
      >
        {{ "app.cancel.label" | translate | uppercase }}
      </button>

      <button
        color="accent"
        [disabled]="this.shiftModelsFormGroup.invalid"
        class="dialog-button mat-elevation-z2"
        mat-flat-button
        type="button"
        (click)="confirm()"
      >
        <mat-spinner
          style="display: inline-block"
          *ngIf="loading$ | async"
          [diameter]="18"
        >
        </mat-spinner>
        {{ "app.confirm.label" | translate | uppercase }}
      </button>
    </div>
  </div>
</form>
