export function sortEnum(
  enumArray: { id: string; translateKey: string; enabled?: boolean }[],
) {
  if (!enumArray || enumArray.length === 0) {
    return enumArray;
  }
  return enumArray.sort((a, b) => {
    return a.translateKey.localeCompare(b.translateKey);
  });
}

export function sortEnumObject(object: Object) {
  const sortedKeys = Object.keys(object).sort((a, b) => a.localeCompare(b));
  const sortedObject = {};
  for (const key of sortedKeys) {
    sortedObject[key] = object[key];
  }

  return sortedObject;
}
