// ANGULAR
import { Component, Input, OnDestroy } from '@angular/core';

// RxJS 6
import { Subscription } from 'rxjs';

// MODELS
import {
  IWHCustomerDTO,
  WHCustomerDOM,
  WHDataService,
} from '@workheld/workheld-shared-lib';
import { DataModelHelperService } from 'src/app/app-services-helper/data-model-helper.service';

@Component({
  selector: 'ng-bee-customer-static-data',
  templateUrl: './customer-static-data.component.html',
})
export class CustomerStaticDataComponent implements OnDestroy {
  // INPUT
  @Input() public label: string = 'customer.model.root';
  @Input('detailURL') set _detailURL(detailURL: string) {
    if (!detailURL) return;
    this.subscriptions.push(
      this.dataService
        .getByUrl(detailURL)
        .subscribe((customerDTO: IWHCustomerDTO) => {
          setTimeout(() => {
            this.customerDOM =
              this.dataModelHelperService.initCustomerDOM(customerDTO);
          }, 50);
        }),
    );
  }
  @Input() public withLabel: boolean = false;
  public customerDOM: WHCustomerDOM;

  // MANAGE SUBSCRIPTIONS
  private subscriptions: Subscription[] = [];

  constructor(
    private dataService: WHDataService,
    private dataModelHelperService: DataModelHelperService,
  ) {}

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription: Subscription) => {
      subscription.unsubscribe();
    });
  }
}
