// HELPER
import { updateObject } from '@workheld/workheld-shared-lib';

// ANGULAR
import {
  Component,
  OnInit,
  Input,
  OnDestroy,
  ChangeDetectorRef,
  signal,
} from '@angular/core';

// SERVICES
import { WHDataService } from '@workheld/workheld-shared-lib';
import { DataModelHelperService } from 'src/app/app-services-helper/data-model-helper.service';

// RxJS 6
import { Subscription } from 'rxjs';

// MODELS
import {
  WHEntryDOM,
  WHExpenseEntryDOM,
  IWHExpenseEntryDTO,
} from '@workheld/workheld-shared-lib';
import { WHEnumDataService } from 'src/app/app-services-helper/w-h-enum-helper.service';

@Component({
  selector: 'ng-bee-type3-expence-entry-static-data',
  templateUrl: './type3-expence-entry-static-data.component.html',
  styleUrls: ['./type3-expence-entry-static-data.component.scss'],
})
export class Type3ExpenceEntryStaticDataComponent implements OnInit, OnDestroy {
  // ENUMS
  public CURRENCY: any;

  // DATA VAR
  @Input() public entryDOM: WHEntryDOM;
  public expenceEntryDOM: WHExpenseEntryDOM = {
    loading: true,
    loaded: false,
  } as any;

  // MANAGE SUBSCRIPTIONS
  private subscriptions: Subscription[] = [];
  $ENUMS_OBJECT = signal(null);

  constructor(
    private dataService: WHDataService,
    private dataModelHelperService: DataModelHelperService,
    private changeDetectorRef: ChangeDetectorRef,
    private whEnumDataService: WHEnumDataService,
  ) {}

  ngOnInit() {
    this.$ENUMS_OBJECT = this.whEnumDataService.$ENUMS_OBJECT;

    this.subscriptions.push(
      this.dataService
        .getByUrl(this.entryDOM?.entryDetailURL)
        .subscribe((expenceEntryDTO: IWHExpenseEntryDTO) => {
          this.expenceEntryDOM = updateObject(
            this.expenceEntryDOM,
            this.dataModelHelperService.initExpenceEntryDOM(expenceEntryDTO),
          );
          this.changeDetectorRef.detectChanges();
        }),
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription: Subscription) => {
      subscription.unsubscribe();
    });
  }
}
