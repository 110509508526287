import { Injectable } from '@angular/core';
import {
  AbstractControl,
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { BehaviorSubject, Observable, catchError, map, throwError } from 'rxjs';
import {
  IWHShiftGroupDTO,
  IWHShiftModelDTO,
  PageResultShiftGroupDto,
  WHShiftGroupDataService,
  FLOW_GATEWAY,
  NumberValidator,
  noWhitespaceValidator,
} from '@workheld/workheld-shared-lib';
import { environment } from '../../environments/environment';
import { SelectTime } from '../app-dialogs/mat-dialog-add-shift-model/mat-dialog-add-shift-model.component';
import {
  differenceInMinutes,
  endOfDay,
  isAfter,
  isBefore,
  isSameDay,
  setHours,
  setMinutes,
  startOfDay,
} from 'date-fns';

class ShiftGroupEntity {
  _pageResult: BehaviorSubject<PageResultShiftGroupDto>;
  pageResult$: Observable<PageResultShiftGroupDto>;
  _loading: BehaviorSubject<boolean>;
  loading$: Observable<boolean>;
  constructor() {
    this._pageResult = new BehaviorSubject<PageResultShiftGroupDto>(null);
    this.pageResult$ = this._pageResult.asObservable();
    this._loading = new BehaviorSubject<boolean>(false);
    this.loading$ = this._loading.asObservable();
  }
}

@Injectable({
  providedIn: 'root',
})
export class ShiftGroupDataService {
  public data = new ShiftGroupEntity();

  constructor(private shiftGroupDataService: WHShiftGroupDataService) {}

  public initParamsFormGroup() {
    return new FormBuilder().group({
      page: [0],
      status: [''],
      term: [null],
    });
  }

  getShiftGroups(params?: any) {
    this.data._loading.next(true);
    this.shiftGroupDataService
      .getShiftGroups({ apiUrl: environment.apiUrl + FLOW_GATEWAY }, params)
      .pipe(
        map((data: PageResultShiftGroupDto) => {
          this.data._pageResult.next(data);
          this.data._loading.next(false);
        }),
        catchError((err) => {
          this.data._loading.next(false);
          return throwError(() => err);
        }),
      )
      .subscribe();
  }

  getShiftGroup(id: string) {
    const config = {
      apiUrl: environment.apiUrl + FLOW_GATEWAY,
      id,
    };
    return this.shiftGroupDataService.getShiftGroupById(config);
  }

  createOrUpdateShiftGroup(shiftGroup: IWHShiftGroupDTO) {
    this.data._loading.next(true);
    return this.shiftGroupDataService.addShiftGroup(
      { apiUrl: environment.apiUrl + FLOW_GATEWAY, id: shiftGroup.extId },
      shiftGroup,
    );
  }

  addShiftModels(shiftGroup: IWHShiftGroupDTO) {
    this.data._loading.next(true);
    return this.shiftGroupDataService.updateShiftModels(
      {
        apiUrl: environment.apiUrl + FLOW_GATEWAY,
        shiftgroupId: shiftGroup.id,
      },
      shiftGroup.shiftModelsList,
    );
  }

  /**
   *
   * @param shiftGroupId
   * @param shiftModel
   * @returns
   */
  updateShiftModel(shiftGroupId: string, shiftModel: IWHShiftModelDTO) {
    this.data._loading.next(true);

    return this.shiftGroupDataService.updateShiftModel(
      { apiUrl: environment.apiUrl + FLOW_GATEWAY, shiftgroupId: shiftGroupId },
      shiftModel,
    );
  }

  deleteShiftGroup(shiftGroup: IWHShiftGroupDTO) {
    this.data._loading.next(true);

    return this.shiftGroupDataService.deleteShiftGroup({
      apiUrl: environment.apiUrl + FLOW_GATEWAY,
      id: shiftGroup.id,
    });
  }

  deleteShiftModel(shiftModel: IWHShiftModelDTO) {
    this.data._loading.next(true);
    return this.shiftGroupDataService.deleteShiftModel({
      apiUrl: environment.apiUrl + FLOW_GATEWAY,
      shiftgroupId: shiftModel.shiftModelGroupId,
      id: shiftModel.id,
    });
  }
}

export function createShiftModelFormGroup(): FormGroup {
  return new FormGroup({
    name: new FormControl('', [Validators.required, noWhitespaceValidator]),
    startTime: new FormControl('', [
      Validators.required,
      Validators.pattern(/^([0-1]?\d|2[0-3]):[0-5]\d$/),
    ]),
    endTime: new FormControl('', [
      Validators.required,
      Validators.pattern(/^([0-1]?\d|2[0-3]):[0-5]\d$/),
    ]),
    status: new FormControl('ACTIVE'),
    breakDuration: new FormControl(0, [
      Validators.required,
      NumberValidator.number({ min: 0, max: 1440 }),
    ]),
    shiftModelGroupId: new FormControl(''),
  });
}

export function updateShiftModelFormGroup(
  shiftModel: IWHShiftModelDTO,
): FormGroup {
  return new FormGroup({
    id: new FormControl(shiftModel.id),
    extId: new FormControl(shiftModel.extId),
    name: new FormControl(shiftModel.name, [
      Validators.required,
      noWhitespaceValidator,
    ]),
    startTime: new FormControl(shiftModel.startTime, [
      Validators.required,
      Validators.pattern(/^([0-1]?\d|2[0-3]):[0-5]\d$/),
    ]),
    endTime: new FormControl(shiftModel.endTime, [
      Validators.required,
      Validators.pattern(/^([0-1]?\d|2[0-3]):[0-5]\d$/),
    ]),
    status: new FormControl(shiftModel.status),
    breakDuration: new FormControl(shiftModel.breakDuration, [
      Validators.required,
      NumberValidator.number({ min: 0, max: 1440 }),
    ]),
    shiftModelGroupId: new FormControl(shiftModel.shiftModelGroupId),
  });
}

export function startAndEndTimeValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    if (!control.parent) {
      return null; // Control is not yet associated with a parent.
    }

    let startTime = control.parent.get('startTime').value;
    let endTime = control.parent.get('endTime').value;

    //If shiftModel.StartTime is an object then convert Object.time to String
    if (typeof startTime === 'object') {
      const tempStartTime = structuredClone(startTime as SelectTime);
      startTime = tempStartTime ? tempStartTime.time : '';
    }

    //If shiftModel.StartTime is an object then convert Object.time to String
    if (typeof endTime === 'object') {
      const tempEndTime = structuredClone(endTime as SelectTime);
      endTime = tempEndTime ? tempEndTime.time : '';
    }

    if (startTime === '' || endTime === '') {
      return null; // don't validate empty value
    }

    // time in format hh:mm:ss get the hh and mm and ss in three numbers objects
    const startTimeHours = startTime.split(':').map((x) => parseInt(x));
    const endTimeHours = endTime.split(':').map((x) => parseInt(x));

    // WRite regex to validate
    //check if the start time is before the end time and save in a boolean
    const startTimeBeforeEndTime =
      startTimeHours[0] < endTimeHours[0] ||
      (startTimeHours[0] === endTimeHours[0] &&
        startTimeHours[1] < endTimeHours[1]);

    return !startTimeBeforeEndTime ? { startBeforeEnd: true } : null;
  };
}

const getDateTime = (time: string) => {
  if (!time) return null;
  const timeArr = time?.split(':');
  return setHours(
    setMinutes(new Date(), parseInt(timeArr[1])),
    parseInt(timeArr[0]),
  );
};

export function validateOverlappingTimesArrayGroup(): ValidatorFn {
  return (formArray: FormArray): { [key: string]: any } | null => {
    const shifts = formArray.value;
    const errors = {};

    for (let i = 0; i < shifts.length; i++) {
      const currentShift = shifts[i];
      const currentShiftStartDateTime = getDateTime(currentShift.startTime);
      const currentShiftEndDateTime = getDateTime(currentShift.endTime);
      const currentShiftDuration = differenceInMinutes(
        currentShiftStartDateTime,
        currentShiftEndDateTime,
      );

      if (Math.abs(currentShiftDuration) <= 59) {
        errors['isSameHour'] = true;
      }

      if (shifts.length > 1) {
        for (let j = i + 1; j < shifts.length; j++) {
          const otherShift = shifts[j];
          const otherShiftStartDateTime = getDateTime(otherShift.startTime);
          const otherShiftEndDateTime = getDateTime(otherShift.endTime);

          const overlaps = shiftOverlap(
            currentShiftStartDateTime,
            otherShiftStartDateTime,
            otherShiftEndDateTime,
            currentShiftEndDateTime,
          );

          if (overlaps) {
            errors['overlappingTime'] = true;
            break;
          }
        }

        const overlapsIntoAnotherDay = shiftOverlapIntoAnotherDay(
          currentShiftStartDateTime,
          currentShiftEndDateTime,
        );

        if (overlapsIntoAnotherDay) {
          errors['overlappingTime'] = true;
          break;
        }
      }
    }
    return returnErrors(errors);
  };
}

function returnErrors(errors: any) {
  return Object.keys(errors).length > 0 ? errors : null;
}
function shiftOverlapIntoAnotherDay(
  currentShiftStartDateTime: Date,
  currentShiftEndDateTime: Date,
): boolean {
  const overlapsIntoAnotherDay =
    currentShiftStartDateTime &&
    currentShiftEndDateTime &&
    !isSameDay(currentShiftStartDateTime, currentShiftEndDateTime) &&
    !isBefore(currentShiftEndDateTime, currentShiftStartDateTime) &&
    !isBefore(currentShiftEndDateTime, startOfDay(currentShiftStartDateTime)) &&
    !isAfter(currentShiftStartDateTime, endOfDay(currentShiftEndDateTime));
  return overlapsIntoAnotherDay;
}

function shiftOverlap(
  currentShiftStartDateTime: Date,
  otherShiftStartDateTime: Date,
  otherShiftEndDateTime: Date,
  currentShiftEndDateTime: Date,
): boolean {
  const overlaps =
    (isAfter(currentShiftStartDateTime, otherShiftStartDateTime) &&
      isBefore(currentShiftStartDateTime, otherShiftEndDateTime)) ||
    (isAfter(currentShiftEndDateTime, otherShiftStartDateTime) &&
      isBefore(currentShiftEndDateTime, otherShiftEndDateTime)) ||
    (isAfter(otherShiftStartDateTime, currentShiftStartDateTime) &&
      isBefore(otherShiftStartDateTime, currentShiftEndDateTime)) ||
    (isAfter(otherShiftEndDateTime, currentShiftStartDateTime) &&
      isBefore(otherShiftEndDateTime, currentShiftEndDateTime));

  return overlaps;
}

export function validateStartAndEndDate() {
  return (formGroup: FormGroup) => {
    if (
      formGroup.controls['startTime']?.value &&
      formGroup.controls['endTime']?.value
    ) {
      const startTime = getDateTime(formGroup.controls['startTime'].value);
      const endTime = getDateTime(formGroup.controls['endTime'].value);

      if (differenceInMinutes(endTime, startTime) <= 59) {
        return { isSameHour: true };
      }
    }

    return null;
  };
}

export function validateNamesArrayGroup(): ValidatorFn {
  return (formArray: FormArray): { [key: string]: any } | null => {
    let valid: boolean = true;

    const tempList = structuredClone(formArray.value) as IWHShiftModelDTO[];

    if (tempList.length < 2) {
      return null;
    }

    //check if duplicate name exists in tempList
    const duplicateNameExists = tempList.some(
      (x, index) =>
        tempList.findIndex((y) => y.name === x.name) !== index && x.name !== '',
    );
    //if duplicate name exists then return error
    if (duplicateNameExists) {
      valid = false;
    }
    return valid ? null : { duplicateName: 'Names have duplicate' };
  };
}
