// ANGULAR
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

// ANGULAR MATERIAL
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Subscription } from 'rxjs';

// API
import {
  WHIconENUM,
  WHLoginDataService,
  WHTranslationTypeENUM,
} from '@workheld/workheld-shared-lib';

@Component({
  selector: 'mat-dialog-workobject-pause-resume-entry',
  templateUrl: './mat-dialog-workobject-pause-resume-entry.component.html',
  styleUrls: ['./mat-dialog-workobject-pause-resume-entry.component.scss'],
})
export class MatDialogWorkObjectPauseResumeEntryComponent
  implements OnInit, OnDestroy
{
  // VAR
  public paylaodFormGroup: FormGroup = new FormBuilder().group({
    comment: [null],
    reason: [null, Validators.required],
  });

  // STATE
  public isWorkObjectPaused: boolean;

  // SUBSCRIPTION
  private subscriptions: Subscription[] = [];
  ENUMS = WHTranslationTypeENUM;

  constructor(
    public matDialogRef: MatDialogRef<MatDialogWorkObjectPauseResumeEntryComponent>,
    private loginDataService: WHLoginDataService,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      isWorkObjectPaused: boolean;
    },
  ) {}

  ngOnInit(): void {
    this.isWorkObjectPaused = this.data.isWorkObjectPaused;
  }

  public handleSubmit(): void {
    this.confirmNewStateWorkObject();
  }

  private confirmNewStateWorkObject() {
    const userName: string =
      this.loginDataService.activeUserDOM$.value.formattedName + ': ';

    const payload: any = this.paylaodFormGroup.value;
    payload.comment =
      userName +
      payload.reason +
      (payload.comment ? ', ' + payload.comment : '');

    this.matDialogRef.close(payload.comment);
  }

  public get isFormGroupInvalid(): boolean {
    return this.paylaodFormGroup.invalid as boolean;
  }

  public get workObjectPaused(): boolean {
    return this.isWorkObjectPaused;
  }

  public closeDialog(): void {
    this.matDialogRef.close();
  }

  public get saveIcon(): string {
    return WHIconENUM.SaveIcon as string;
  }

  public get cancelIcon(): string {
    return WHIconENUM.CancelIcon as string;
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription: Subscription) => {
      subscription.unsubscribe();
    });
  }
}
