<!-- LAYOUT -->

<lib-w-h-main-layout
  class="cdk-border"
  data-cy="main-layout"
  [quickNavItemDOM]="quickNavItemDOM"
  [helpNavItemDOM]="helpNavItemDOM"
  [layoutConfigDOM]="layoutConfigDOM"
  [layoutNavItemDOMList]="layoutNavItemDOMList"
  [layoutLanguageDOMList]="layoutLanguageDOMList"
  [layoutMobileNavItemDOMList]="layoutMobileNavItemDOMList"
  [layoutLoggedInUserDOM]="layoutLoggedInUserDOM"
  [layoutLogoDOM]="layoutLogoDOM"
  [navItemLimit]="7"
  [isMobileMenuVisible]="isSmallScreen()"
  [layoutLoggedInUserNavItemDOMList]="layoutLoggedInUserNavItemDOMList"
  (onDomEvent)="onDomChildEvent($event)"
>
</lib-w-h-main-layout>
