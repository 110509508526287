import { Injectable, signal } from '@angular/core';
import { WHMetadataDataService } from '@workheld/workheld-shared-lib';
import { sortEnum } from '../app-shared/helpers/enumSort.helper';

export interface MetadataEnum {
  id: string;
  translateKey: string;
}

@Injectable({
  providedIn: 'root',
})
export class WHEnumDataService {
  $ENUMS = signal(null);
  $ENUMS_OBJECT = signal(null);

  constructor(private metadataDataService: WHMetadataDataService) {
    this.metadataDataService.metadataTranslation$.subscribe((metadata) => {
      if (metadata) {
        const preparedMetadata = this._prepareMetadata(metadata);

        this.$ENUMS.set(preparedMetadata);
        this.$ENUMS_OBJECT.set(metadata);
      }
    });
  }

  private _prepareMetadata(metadata: any): any {
    const preparedMetadata: any = {};
    Object.keys(metadata).forEach((key) => {
      preparedMetadata[key] = sortEnum(
        this._convertToKeyValueArray(metadata[key]),
      );
    });
    return preparedMetadata;
  }

  private _convertToKeyValueArray(obj: {
    [key: string]: string;
  }): MetadataEnum[] {
    return Object.entries(obj).map(([key, value]) => ({
      id: key,
      translateKey: value,
    }));
  }
}
