import { Component, Inject, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {
  Observable,
  Subscription,
  catchError,
  map,
  tap,
  throwError,
} from 'rxjs';
import {
  ShiftGroupDataService,
  createShiftModelFormGroup,
  validateOverlappingTimesArrayGroup,
  updateShiftModelFormGroup,
  validateNamesArrayGroup,
} from 'src/app/app-services-async/shift-group-data.service';
import {
  DateTimeService,
  IWHShiftGroupDTO,
  IWHShiftModelDTO,
  WHNgxToastrENUM,
  WHNgxToastrService,
  materialStatusIdEnumArray,
} from '@workheld/workheld-shared-lib';
import { FormReferenceService } from 'src/app/app-services-helper/form-reference.service';
import { deriveTimeFormat } from 'time-input-formatter';

@Component({
  selector: 'app-mat-dialog-add-shift-models',
  templateUrl: './mat-dialog-add-shift-models.component.html',
  styleUrls: ['./mat-dialog-add-shift-models.component.scss'],
})
export class MatDialogAddShiftModelsComponent implements OnInit {
  public emptyForm: FormControl = new FormControl('');
  loading$: Observable<boolean>;
  private subscriptions: Subscription[] = [];
  statusIdEnumArray: string[] = materialStatusIdEnumArray;

  timeIntervals: string[] = [];

  labelStartTime = '';

  shiftModelsFormGroup: FormGroup;

  constructor(
    private formReferenceService: FormReferenceService,
    private dateTimeHelperService: DateTimeService,
    private shiftGroupService: ShiftGroupDataService,
    private ngxToastrService: WHNgxToastrService,
    private _formBuilder: FormBuilder,
    public matDialogRef: MatDialogRef<MatDialogAddShiftModelsComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      shiftGroup: IWHShiftGroupDTO;
    },
  ) {}
  ngOnInit(): void {
    this.shiftModelsFormGroup = this._formBuilder.group({
      shiftModels: this._formBuilder.array(
        [],
        [validateOverlappingTimesArrayGroup(), validateNamesArrayGroup()],
      ),
    });

    this.loading$ = this.shiftGroupService.data.loading$;
    this.timeIntervals =
      this.dateTimeHelperService.initTimeOptionDropdownArray(30);
    /**
       this.dateTimeHelperService
      .initTimeOptionDropdownArray(30)
      .forEach((time) => {
        this.timeIntervals.push({ time: time });
      });
     */
    if (this.data.shiftGroup) {
      if (this.data.shiftGroup.shiftModelsList?.length > 0) {
        this.data.shiftGroup.shiftModelsList.forEach((shiftModel) => {
          this.shiftModels.push(updateShiftModelFormGroup(shiftModel));
        });
      } else if (this.data.shiftGroup.shiftModelsList?.length === 0) {
        this.shiftModels.push(createShiftModelFormGroup());
      }
    }

    this.subscriptions.push(
      this.shiftModelsFormGroup.valueChanges.subscribe((_) => {
        this.formReferenceService.unsavedChanges =
          this.shiftModelsFormGroup.dirty;
      }),
    );
  }

  onBlur(event, index: number, timeType: string) {
    const control = this.shiftModels.at(index).get(timeType);
    if (control) {
      const formattedTime = deriveTimeFormat(control.value, '24hm');
      if (formattedTime.valid) {
        control.setValue(formattedTime.value);
      }
    }
  }

  confirm() {
    const shiftModelList = structuredClone(
      this.shiftModelsFormGroup.get('shiftModels').value as IWHShiftModelDTO[],
    );

    const shiftGroupToReturn = structuredClone(this.data.shiftGroup);

    shiftGroupToReturn.shiftModelsList = shiftModelList;

    this.shiftGroupService
      .addShiftModels(shiftGroupToReturn)
      .pipe(
        map((data: IWHShiftModelDTO[]) => {
          this.shiftGroupService.data._loading.next(false);
          this.ngxToastrService.displayToastr({
            toastrType: WHNgxToastrENUM.SUCCESS,
            messageTranslateKey: 'shiftmodels.ui.updatesuccess.notification',
          });

          this.matDialogRef.close(true);
        }),
        catchError((err) => {
          this.shiftGroupService.data._loading.next(false);
          return throwError(err);
        }),
      )
      .subscribe();
  }

  cancel() {
    if (this.shiftModelsFormGroup.dirty) {
      const ref = this.formReferenceService.createDialog();
      ref
        .pipe(
          tap((canDiscard) => {
            if (canDiscard) {
              this.matDialogRef.close(false);
              this.formReferenceService.unsavedChanges = false;
            }
          }),
        )
        .subscribe();
    } else {
      this.matDialogRef.close(false);
    }
  }

  addShiftModel() {
    this.shiftModels.push(createShiftModelFormGroup());
  }

  deleteShiftModel(modelIndex: number) {
    this.shiftModels.removeAt(modelIndex);
  }

  get shiftModels(): FormArray {
    return this.shiftModelsFormGroup.get('shiftModels') as FormArray;
  }

  getModelByIndex(index: number): FormControl {
    return this.shiftModels.at(index) as FormControl;
  }

  onDomChildEvent(event: any) {
    console.log(event);
  }
}
