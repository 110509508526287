// ANGULAR
import {
  Component,
  OnInit,
  Input,
  ChangeDetectorRef,
  OnChanges,
  SimpleChanges,
  OnDestroy,
} from '@angular/core';

// SERVICES
import {
  FLOW_GATEWAY,
  getWorkObjectIconByWorkObjectTypeId,
  IWHWorkObjectEndpointConfigModel,
  WHDataService,
  WHFeatureKeyENUM,
  WHIconENUM,
  WHRouteZeroENUM,
  WHWorkObjectDataService,
} from '@workheld/workheld-shared-lib';
import { DataModelHelperService } from 'src/app/app-services-helper/data-model-helper.service';

// RxJS 6
import { first, Subscription } from 'rxjs';

// API
import {
  WHWorkObjectDOM,
  IWHWorkObjectDTO,
  WHMetadataDataService,
  IWHFlowMetadataTranslationModel,
  WHLoginDataService,
} from '@workheld/workheld-shared-lib';
import { IWHUIConfigModel } from '@workheld/workheld-shared-lib';
import {
  IWHLocationDTO,
  IWHFlowContactDTO,
} from '@workheld/workheld-shared-lib';
import { workObjectUiConfig$ } from '@workheld/workheld-shared-lib';
import { AppDialogService } from 'src/app/app-dialogs/app-dialog.service';
import { environment } from 'src/environments/environment';
import { Store } from '@ngrx/store';
import { IAppStore } from 'src/app/app-stores/index.stores';
import { SetWHWorkObjectDOM } from 'src/app/app-stores/w-h-work-object-stores/w-h-work-object-crud-store/w-h-work-object-crud-store.actions';
import { Router } from '@angular/router';

@Component({
  selector: 'ng-bee-work-object-static-data',
  templateUrl: './work-object-static-data.component.html',
  styleUrls: ['./work-object-static-data.component.scss'],
})
export class WorkObjectStaticDataComponent
  implements OnInit, OnChanges, OnDestroy
{
  // WH ENUM
  public PRIORITY: any;
  public SLA: any;
  public CATEGORY: any;
  public WORK_OBJECT_CATEGORY: any;
  public WORK_OBJECT_TARGET_STATE: any;
  public CONTACT_SALUTATION: any;

  // DISPLAY DATE
  @Input() public isTimeFrame: boolean = false;
  @Input() public isStartTimeVisible: boolean = false;
  @Input() public isEndTimeVisible: boolean = false;

  // DISPLAY NAME
  @Input() public isSimpleData: boolean = false;
  @Input() public withTitleLabel: boolean = false;
  @Input() public titleLabel: string = 'workobject.model.root';

  // NUMBER EXT
  @Input() public withExtId: boolean = false;
  @Input() public extIdLabel: string = 'workobject.model.extid';

  // DATA
  @Input() public isExternalInput: boolean = false;
  @Input() public isWorkObjectDetail: boolean = false;
  @Input() public detailURL: string;
  @Input() public workObjectDOM: WHWorkObjectDOM | any;
  @Input() public sumOfPlannedWorkTime: number = 0;

  // EXTRA
  @Input() public location: IWHLocationDTO;
  @Input() public isWorkObjectActive: boolean;
  public isLocationEnabled: boolean = false;
  public isTargetStateEnabled: boolean;
  public isPlannedWorkEnabled: boolean;

  result: any = 'ost';
  // MANAGE SUBSCRIPTIONS
  private subscriptions: Subscription[] = [];

  constructor(
    private loginDataService: WHLoginDataService,
    private dataService: WHDataService,
    private store: Store<IAppStore>,
    private dataModelHelperService: DataModelHelperService,
    private metadataDataService: WHMetadataDataService,
    private workObjectDataService: WHWorkObjectDataService,
    private changeDetectorRef: ChangeDetectorRef,
    private appDialogService: AppDialogService,
    private router: Router,
  ) {}

  public ngOnInit(): void {
    // FEATURE CONFIG
    this.subscriptions.push(
      this.loginDataService.featureConfigMap$.subscribe(
        (featureConfigMap: Map<string, boolean>) => {
          // console.log(featureConfigMap);
          this.isLocationEnabled = featureConfigMap.get(
            WHFeatureKeyENUM.CORE_WORK_FLOW_WORK_ORDER_LOCATION,
          );
          this.isTargetStateEnabled = featureConfigMap.get(
            WHFeatureKeyENUM.CORE_WORK_FLOW_WORK_ORDER_TARGET_STATE,
          );
          this.isPlannedWorkEnabled = featureConfigMap.get(
            WHFeatureKeyENUM.PLANNED_WORK,
          );
        },
      ),
    );

    // WH ENUMS
    this.subscriptions.push(
      this.metadataDataService.metadataTranslation$.subscribe(
        (metadataTranslation: IWHFlowMetadataTranslationModel) => {
          this.PRIORITY = metadataTranslation.PRIORITY;
          this.SLA = metadataTranslation.SLA;
          this.CATEGORY = metadataTranslation.CATEGORY;
          this.WORK_OBJECT_CATEGORY = metadataTranslation.WORK_OBJECT_CATEGORY;
          this.CONTACT_SALUTATION = metadataTranslation.CONTACT_SALUTATION;
          this.WORK_OBJECT_TARGET_STATE =
            metadataTranslation.WORK_OBJECT_TARGET_STATE;
          this.changeDetectorRef.detectChanges();
        },
      ),
    );
  }

  public ngOnChanges(changes: SimpleChanges): void {
    // console.log(changes);
    if (
      !this.isExternalInput &&
      changes &&
      changes.detailURL &&
      changes.detailURL.currentValue
    ) {
      this.subscriptions.push(
        this.dataService
          .getByUrl(changes.detailURL.currentValue)
          .subscribe((workObjectDTO: IWHWorkObjectDTO | any) => {
            console.log(this.workObjectDOM?.workObjectTypeId);
            this.workObjectDOM =
              this.dataModelHelperService.initWorkObjectTypeDOM(workObjectDTO);
            this.titleLabel = workObjectUiConfig$.value.find(
              (enumModelDOM: IWHUIConfigModel) => {
                return (
                  enumModelDOM.typeId === this.workObjectDOM.workObjectTypeId
                );
              },
            ).translateKey;
            this.changeDetectorRef.detectChanges();
          }),
      );
    }
  }

  public get workObjectIcon(): string {
    return this.workObjectDOM && this.workObjectDOM.workObjectTypeId
      ? getWorkObjectIconByWorkObjectTypeId(this.workObjectDOM.workObjectTypeId)
      : (WHIconENUM.WorkheldIcon as string);
  }

  public get contacPersonIcon(): string {
    return WHIconENUM.UserIcon as string;
  }

  public async contactCrud(contactDTO?: IWHFlowContactDTO) {
    this.result = 'odsaodosa';

    const result = await this.appDialogService
      .openContactMatDialog({
        minWidth: '60%',
        data: {
          contactDTO,
        },
      })
      .afterClosed()
      .toPromise()
      .then((result) => {
        return result;
      });

    if (typeof result === 'string') {
      // Returning string means delete
      const payload: IWHWorkObjectDTO = structuredClone(this.workObjectDOM);
      payload['removeContacts'] = [{ id: result }];
      this.updateContacts(payload);
    } else {
      if (result) {
        // Returning object means update or create
        const payload: IWHWorkObjectDTO = structuredClone(this.workObjectDOM);
        payload.contacts = [result];
        this.updateContacts(payload);
      }
    }
  }

  openExecutionGroupDetails() {
    this.router.navigate([
      WHRouteZeroENUM.EXECUTION_GROUPS,
      this.workObjectDOM.executionGroupId,
    ]);
  }

  public updateContacts(workObjectDOM: IWHWorkObjectDTO): void {
    const endpointConfig: IWHWorkObjectEndpointConfigModel = {
      apiUrl: environment.apiUrl + FLOW_GATEWAY,
      workObjectTypeId: this.workObjectDOM.workObjectTypeId,
      workObjectId: this.workObjectDOM.id,
    };
    this.workObjectDataService
      .updateWorkObjectByWorkObjectType(endpointConfig, workObjectDOM)
      .pipe(first())
      .subscribe((workObjectDTO: IWHWorkObjectDTO) => {
        const workObjectDOM: WHWorkObjectDOM =
          this.dataModelHelperService.initWorkObjectTypeDOM(workObjectDTO);
        this.store.dispatch(
          new SetWHWorkObjectDOM({
            workObjectTypeId: workObjectDOM.workObjectTypeId,
            workObjectDOM: workObjectDOM,
          }),
        );
      });
  }

  public get infoIcon() {
    return WHIconENUM.InfoIcon as string;
  }

  public ngOnDestroy(): void {
    this.subscriptions.forEach((subscription: Subscription) => {
      subscription.unsubscribe();
    });
  }
}
