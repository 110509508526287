export const PLANNER_PRESET_DAY_ID = 'planner_day_preset';
export const PLANNER_PRESET_WEEK_ID = 'planner_week_preset';
export const PLANNER_PRESET_MONTH_ID = 'planner_month_preset';
export const PLANNER_PRESET_QUARTER_ID = 'planner_quarter_preset';
export const PLANNER_CONTEXT_OPTION_WORK_OBJECT = 'planner_work_object';
export const PLANNER_CONTEXT_OPTION_WORK_ORDER = 'planner_work_order';

export const PLANNER_SHIFT_UNITS = {
  [PLANNER_PRESET_DAY_ID]: 'day',
  [PLANNER_PRESET_WEEK_ID]: 'week',
  [PLANNER_PRESET_MONTH_ID]: 'month',
  [PLANNER_PRESET_QUARTER_ID]: 'quarter',
};

// Drag and drop will work for every 30 min tick
const timeResolution = {
  unit: 'minute',
  increment: 30,
};

// Date formats: https://bryntum.com/products/scheduler/docs/api/Core/helper/DateHelper
// ViewPreset config: https://bryntum.com/products/scheduler/docs/api/Scheduler/preset/ViewPreset
export const teamPlannerViewPresets = [
  {
    id: PLANNER_PRESET_DAY_ID,
    name: 'Planner Day View',
    base: 'hourAndDay',
    shiftUnit: PLANNER_SHIFT_UNITS[PLANNER_PRESET_DAY_ID] as 'day', // ENUM
    shiftIncrement: 1,
    timeResolution: { ...timeResolution },
    headers: [
      {
        unit: 'day',
        dateFormat: 'ddd DD.MM', // Mon 01.10
      },
      {
        unit: 'hour',
        increment: 1,
        dateFormat: 'HH:mm', // 17:00
      },
    ],
  },
  {
    id: PLANNER_PRESET_WEEK_ID,
    name: 'Planner Week View',
    base: 'weekAndDay',
    tickWidth: 50,
    shiftUnit: PLANNER_SHIFT_UNITS[PLANNER_PRESET_WEEK_ID] as 'week', // ENUM
    shiftIncrement: 1,
    timeResolution: { ...timeResolution },
    headers: [
      {
        unit: 'day',
        dateFormat: 'ddd DD.MM', // Mon 01.10
      },
      {
        unit: 'hour',
        increment: 4,
        dateFormat: 'HH:mm', // 17:00
      },
    ],
  },
  {
    id: PLANNER_PRESET_MONTH_ID,
    name: 'Planner Month View',
    base: 'dayAndMonth',
    tickWidth: 50,
    shiftUnit: PLANNER_SHIFT_UNITS[PLANNER_PRESET_MONTH_ID] as 'month', // ENUM
    shiftIncrement: 1,
    timeResolution: { ...timeResolution },
    headers: [
      {
        unit: 'month',
        dateFormat: 'MMMM', // July
        verticalColumnWidth: 115,
      },
      {
        unit: 'week',
        dateFormat: 'WWp0',
      },
      {
        unit: 'day',
        dateFormat: 'dd DD', // Su 04
        verticalColumnWidth: 25,
      },
    ],
  },
];
