// HELPER
import { updateObject } from '@workheld/workheld-shared-lib';

// ANGULAR
import {
  Component,
  OnInit,
  Input,
  OnDestroy,
  ChangeDetectorRef,
  signal,
} from '@angular/core';

// SERVICES
import { WHDataService } from '@workheld/workheld-shared-lib';
import { DataModelHelperService } from 'src/app/app-services-helper/data-model-helper.service';

// RxJS 6
import { Subscription } from 'rxjs';

// MODELS
import {
  WHEntryDOM,
  IWHMeasurementEntryDTO,
  WHMeasurementEntryDOM,
} from '@workheld/workheld-shared-lib';
import { WHEnumDataService } from 'src/app/app-services-helper/w-h-enum-helper.service';

@Component({
  selector: 'ng-bee-type6-measurment-entry-static-data',
  templateUrl: './type6-measurment-entry-static-data.component.html',
  styleUrls: ['./type6-measurment-entry-static-data.component.scss'],
})
export class Type6MeasurmentEntryStaticDataComponent
  implements OnInit, OnDestroy
{
  // DATA VAR
  @Input() public entryDOM: WHEntryDOM;
  public measurmentEntryDOM: WHMeasurementEntryDOM = {
    loading: true,
    loaded: false,
  } as any;

  // MANAGE SUBSCRIPTIONS
  private subscriptions: Subscription[] = [];
  measurmentValue: any;
  $ENUMS_OBJECT = signal(null);

  constructor(
    private dataService: WHDataService,
    private dataModelHelperService: DataModelHelperService,
    private changeDetectorRef: ChangeDetectorRef,
    private whEnumDataService: WHEnumDataService,
  ) {}

  ngOnInit() {
    this.$ENUMS_OBJECT = this.whEnumDataService.$ENUMS_OBJECT;

    this.subscriptions.push(
      this.dataService
        .getByUrl(this.entryDOM?.entryDetailURL)
        .subscribe((measurmentEntryDTO: IWHMeasurementEntryDTO) => {
          this.measurmentEntryDOM = updateObject(
            this.measurmentEntryDOM,
            this.dataModelHelperService.initMeasurmentEntryDOM(
              measurmentEntryDTO,
            ),
          );

          this.measurmentValue = this._getValue();

          this.changeDetectorRef.detectChanges();
        }),
    );
    this.changeDetectorRef.detectChanges();
  }

  private _getValue(): any {
    const key = Object.keys(this.measurmentEntryDOM?.measurementValue)[0];
    return this.measurmentEntryDOM?.measurementValue[key];
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription: Subscription) => {
      subscription.unsubscribe();
    });
  }
}
