<bryntum-scheduler-pro
  #scheduler
  [barMargin]="schedulerConfig.barMargin"
  [columns]="schedulerConfig.columns"
  [eventStore]="schedulerConfig.eventStore"
  [autoAdjustTimeAxis]="schedulerConfig.autoAdjustTimeAxis"
  [eventRenderer]="schedulerConfig.eventRenderer"
  [endDate]="schedulerConfig.endDate"
  [events]="schedulerConfig.events"
  [mode]="schedulerConfig.mode"
  [taskEditFeature]="schedulerConfig.features.taskEdit"
  [dependenciesFeature]="schedulerConfig.features.dependenciesFeature"
  [enableDeleteKey]="schedulerConfig.enableDeleteKey"
  [presets]="schedulerConfig.presets"
  [eventRenderer]="schedulerConfig.eventRenderer"
  [enableTextSelection]="schedulerConfig.enableTextSelection"
  [createEventOnDblClick]="schedulerConfig.createEventOnDblClick"
  [zoomKeepsOriginalTimespan]="schedulerConfig.zoomKeepsOriginalTimespan"
  [zoomOnMouseWheel]="schedulerConfig.zoomOnMouseWheel"
  [zoomOnTimeAxisDoubleClick]="schedulerConfig.zoomOnTimeAxisDoubleClick"
  [timeRanges]="schedulerConfig.timeRanges"
  [timeRangesFeature]="schedulerConfig.features.timeRanges"
  [resources]="schedulerConfig.resources"
  [rowHeight]="schedulerConfig.rowHeight"
  [startDate]="schedulerConfig.startDate"
  (onCatchAll)="onSchedulerEvents($event)"
></bryntum-scheduler-pro>
