<section class="use-work-object-template-mat-dialog">
  <div class="detail-card-header">
    <span whFlex class="w-h-header-icon-span">
      <i
        mat-card-avatar
        class="workheld-icons header-icon"
        [innerHtml]="projectTemplateIcon"
      ></i>
    </span>
    <span whFlex class="w-h-header-span">
      <div class="single-header">
        {{ "template.ui.use.dialogheader.label" | translate }}
      </div>
    </span>
  </div>

  <div class="use-work-object-template-mat-dialog-body">
    <form [formGroup]="applyWorkObjectTemplateFromGroup">
      <mat-horizontal-stepper
        [linear]="true"
        #stepper
        class="use-template-stepper"
      >
        <mat-step [stepControl]="applyWorkObjectTemplateFromGroup">
          <ng-template matStepLabel>{{
            "template.ui.use.stepper.1.label" | translate
          }}</ng-template>
          <div class="flex--r-fs gap--8">
            <div style="width: 400px">
              <ng-bee-list
                class="use-work-object-template-ng-bee-list"
                [isMaterialIcon]="false"
                [icon]="projectTemplateIcon"
                name="PROJECT_TEMPLATE"
                [columns]="columns"
                (filterChange)="onFilterChange($event)"
              >
                <div class="actions" fxFlexAlign="center"></div>
                @if (templatesLoading()) {
                  <mat-progress-bar mode="query"></mat-progress-bar>
                }
                <mat-table
                  class="data-table"
                  #table
                  [dataSource]="dataSource"
                  matSort
                >
                  <!--- Note that these columns can be defined in any order.
                                                The actual rendered columns are set as a property on the row definition" -->

                  <!-- ID Column -->
                  <ng-container matColumnDef="id">
                    <mat-header-cell
                      class="image-cell"
                      *matHeaderCellDef
                    ></mat-header-cell>
                    <mat-cell class="image-cell" *matCellDef="let row">
                      <i
                        class="workheld-icons"
                        [innerHTML]="projectTemplateIcon"
                      ></i>
                    </mat-cell>
                  </ng-container>

                  <!-- fullName Column -->
                  <ng-container *ngFor="let column of columns">
                    <ng-container
                      *ngIf="column.isModelProperty"
                      [matColumnDef]="column.property"
                    >
                      <mat-header-cell *matHeaderCellDef mat-sort-header>
                        {{ column.name | translate }}</mat-header-cell
                      >
                      <mat-cell *matCellDef="let row">
                        <div
                          class="html-overflow"
                          matTooltip="{{ row[column.property] }}"
                        >
                          {{ row[column.property] }}
                        </div>
                      </mat-cell>
                    </ng-container>
                  </ng-container>

                  <mat-row
                    *matRowDef="let row; columns: visibleColumns"
                    (click)="selectWorkObjectTemplate(row)"
                    (keydown.enter)="selectWorkObjectTemplate(row)"
                    [ngClass]="{
                      'selected-template':
                        selectedWorkObjectTemplateDOM &&
                        row.id === selectedWorkObjectTemplateDOM.id,
                    }"
                  >
                  </mat-row>
                </mat-table>

                <mat-paginator
                  class="paginator"
                  [length]="resultsLength"
                  [pageSizeOptions]="[10]"
                  [pageSize]="10"
                >
                </mat-paginator>
              </ng-bee-list>
            </div>
            <div
              class="flex--col flex-1"
              style="overflow: hidden; height: 610px"
            >
              <div
                class="selected-work-object-template"
                *ngIf="selectedWorkObjectTemplateDOM"
              >
                <div>
                  <span whFlex="50">
                    <div class="w-h-label">
                      {{ "template.model.name" | translate }}
                    </div>
                    <div
                      class="html-overflow"
                      matTooltip="{{
                        selectedWorkObjectTemplateDOM?.templateName
                      }}"
                    >
                      {{ selectedWorkObjectTemplateDOM?.templateName }}
                    </div>
                  </span>
                  <span whFlex="50">
                    <div class="w-h-label">
                      {{ "template.model.extid" | translate }}
                    </div>
                    <div
                      templateName
                      matTooltip="{{ selectedWorkObjectTemplateDOM?.extId }}"
                    >
                      {{ selectedWorkObjectTemplateDOM?.extId }}
                    </div>
                  </span>
                </div>
                <div class="w-h-label desc-label">
                  {{ "template.model.description" | translate }}
                </div>
                <div>
                  <mat-form-field whFlex>
                    <textarea
                      [rows]="1"
                      matInput
                      [value]="
                        selectedWorkObjectTemplateDOM?.templateDescription
                          ? selectedWorkObjectTemplateDOM.templateDescription
                          : ''
                      "
                      [disabled]="true"
                    >
                    </textarea>
                  </mat-form-field>
                </div>
                <mat-selection-list
                  #workOrderSelectionList
                  class="work-order-selection-list"
                >
                  <mat-list-option
                    *ngFor="let workOrderTemplate of workOrderTemplateList"
                    [value]="workOrderTemplate.id"
                  >
                    <div
                      class="html-overflow"
                      matTooltip="{{ workOrderTemplate?.title }}"
                    >
                      {{ workOrderTemplate?.title }}
                    </div>
                  </mat-list-option>
                </mat-selection-list>
              </div>
              <div
                *ngIf="!selectedWorkObjectTemplateDOM"
                class="select-template-msg"
              >
                <span whFlex style="align-self: center">
                  {{
                    "template.ui.use.selecttemplate.helpdescription" | translate
                  }}
                </span>
              </div>
              <div
                class="dialog-action-wrapper flex--r-c-fe"
                *ngIf="isLocationEnabled"
              >
                <button
                  type="button"
                  mat-flat-button
                  class="w-h-default-button w-h-dialog-button mat-elevation-z2"
                  (click)="cancel()"
                  [disabled]="saving"
                >
                  <i class="workheld-icons" [innerHTML]="cancelIcon"></i>
                  {{ "app.cancel.label" | translate | uppercase }}
                </button>
                <button
                  type="button"
                  color="accent"
                  mat-flat-button
                  matStepperNext
                  class="mat-elevation-z2"
                  [disabled]="
                    !isWorkObjectTemplateSelected ||
                    workOrderSelectionList?._value?.length === 0
                  "
                >
                  {{ "app.next.label" | translate | uppercase }}
                </button>
              </div>
              <div
                class="dialog-action-wrapper flex--r-c-fe"
                *ngIf="!isLocationEnabled"
              >
                <button
                  type="button"
                  mat-flat-button
                  class="w-h-default-button w-h-dialog-button mat-elevation-z2"
                  (click)="cancel()"
                  [disabled]="saving"
                >
                  <i class="workheld-icons" [innerHTML]="cancelIcon"></i>
                  {{ "app.cancel.label" | translate | uppercase }}
                </button>
                <button
                  type="button"
                  mat-flat-button
                  color="accent"
                  class="mat-elevation-z2"
                  [disabled]="!selectedWorkObjectTemplateDOM || saving"
                  (click)="handleSubmit()"
                >
                  <mat-spinner
                    style="display: inline-block"
                    *ngIf="saving"
                    [diameter]="18"
                  >
                  </mat-spinner>
                  <i
                    class="workheld-icons"
                    [innerHTML]="saveIcon"
                    *ngIf="!saving"
                  ></i>
                  {{ "app.save.label" | translate | uppercase }}
                </button>
              </div>
            </div>
          </div>
        </mat-step>
        <mat-step [stepControl]="locationFormGroup" *ngIf="isLocationEnabled">
          <ng-template matStepLabel>{{
            "template.ui.use.stepper.2.label" | translate
          }}</ng-template>
          <div class="agm-core-location-form-wrapper">
            <ng-bee-w-h-agm-core-location-form
              [locationFormGroup]="locationFormGroup"
            >
            </ng-bee-w-h-agm-core-location-form>
          </div>
          <div class="dialog-action-wrapper flex--r-c-fe">
            <button
              type="button"
              mat-flat-button
              class="w-h-default-button w-h-dialog-button mat-elevation-z2"
              (click)="cancel()"
              [disabled]="saving"
            >
              <i class="workheld-icons" [innerHTML]="cancelIcon"></i>
              {{ "app.cancel.label" | translate | uppercase }}
            </button>
            <button
              type="button"
              mat-flat-button
              matStepperPrevious
              class="w-h-default-button w-h-dialog-button mat-elevation-z2"
              [disabled]="saving"
            >
              {{ "app.back.label" | translate | uppercase }}
            </button>
            <button
              type="button"
              mat-flat-button
              color="accent"
              class="mat-elevation-z2"
              [disabled]="!isLocationValid || saving"
              (click)="handleSubmit()"
            >
              <mat-spinner
                style="display: inline-block"
                *ngIf="saving"
                [diameter]="18"
              >
              </mat-spinner>
              <i
                class="workheld-icons"
                [innerHTML]="saveIcon"
                *ngIf="!saving"
              ></i>
              {{ "app.save.label" | translate | uppercase }}
            </button>
          </div>
        </mat-step>
      </mat-horizontal-stepper>
    </form>
  </div>
</section>
