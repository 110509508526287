// HELPER
import { componentDestroyed, WHIconENUM } from '@workheld/workheld-shared-lib';

// ANGULAR
import { Component, OnInit, Inject, OnDestroy, ViewChild } from '@angular/core';

// ANGULAR MATERIAL
import { MatPaginator } from '@angular/material/paginator';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSort } from '@angular/material/sort';
import { Store } from '@ngrx/store';
import { IAppStore } from 'src/app/app-stores/index.stores';
import {
  workheldChecklistDefinitionMasterDataStoreFeatureKey,
  IWHChecklistDefinitionMasterDataStore,
} from 'src/app/app-stores/w-h-checklist-definition-stores/w-h-checklist-definition-master-data-store/w-h-checklist-definition-master-data-store.reducer';
import { GetWHChecklistDefinitionDtoList } from 'src/app/app-stores/w-h-checklist-definition-stores/w-h-checklist-definition-master-data-store/w-h-checklist-definition-master-data-store.actions';
import {
  ClearWHChecklistDefinitionCrudSTORE,
  GetWHChecklistDefinitionDTO,
} from 'src/app/app-stores/w-h-checklist-definition-stores/w-h-checklist-definition-crud-store/w-h-checklist-definition-crud-store.actions';

// RxJS 6
import { Subscription, Observable, ReplaySubject } from 'rxjs';
import { takeUntil, filter } from 'rxjs/operators';

// MODELS
import { WHChecklistDefinitionDOM } from '@workheld/workheld-shared-lib';
import { ListColumn } from 'src/app/app-modules-layout/list/list-column.model';
import { ListDataSource } from 'src/app/app-modules-layout/list/list-datasource';
import { ListDatabase } from 'src/app/app-modules-layout/list/list-database';
import { List } from 'src/app/app-modules-layout/list/list.interface';

@Component({
  selector: 'ng-bee-mat-dialog-checklist-definition-assignment-entry',
  templateUrl:
    './mat-dialog-checklist-definition-assignment-entry.component.html',
  styleUrls: [
    './mat-dialog-checklist-definition-assignment-entry.component.scss',
  ],
})
export class MatDialogChecklistDefinitionAssignmentEntryComponent
  implements List<WHChecklistDefinitionDOM>, OnInit, OnDestroy
{
  // DATA
  public checklistDefinitionDomList: WHChecklistDefinitionDOM[] = [];

  // STATE
  public checklistDefinitionId: string = undefined;
  public isAssignable: boolean = false;

  // DATA TABLE
  public data$: Observable<WHChecklistDefinitionDOM[]>;
  public subject$: ReplaySubject<WHChecklistDefinitionDOM[]> =
    new ReplaySubject<WHChecklistDefinitionDOM[]>(1);
  // TABLE COLUMS
  public columns: ListColumn[] = [
    { name: '', property: 'id', visible: true },
    {
      name: 'checklistdefinition.model.extid',
      property: 'extId',
      visible: false,
      isModelProperty: true,
    },
    {
      name: 'checklistdefinition.model.title',
      property: 'title',
      visible: false,
      isModelProperty: true,
    },
    {
      name: 'checklistdefinition.model.title',
      property: 'formattedName',
      visible: true,
      isModelProperty: true,
    },
  ] as ListColumn[];
  public pageSize: number = 10;
  public resultsLength: number;
  public dataSource: ListDataSource<WHChecklistDefinitionDOM> | null;
  public database: ListDatabase<WHChecklistDefinitionDOM>;

  // DOM
  @ViewChild(MatPaginator, { static: true }) public paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) public sort: MatSort;

  // MANAGE SUBSCRIPTIONS
  private subscriptions: Subscription[] = [];

  constructor(
    private store: Store<IAppStore>,
    public matDialogRef: MatDialogRef<MatDialogChecklistDefinitionAssignmentEntryComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      checklistDefinitionId: string;
      calculatedWorkStepStatusId: number;
      isAssignable: boolean;
    },
  ) {}

  ngOnInit() {
    // SET INITIAL IF EXIST
    this.checklistDefinitionId = this.data.checklistDefinitionId;
    this.isAssignable = this.data.isAssignable;

    // GET/SET ALL
    this.store.dispatch(new GetWHChecklistDefinitionDtoList());
    this.subscriptions.push(
      this.store
        .select(workheldChecklistDefinitionMasterDataStoreFeatureKey)
        .subscribe(
          (
            workheldChecklistDefinitionMasterStore: IWHChecklistDefinitionMasterDataStore,
          ) => {
            if (workheldChecklistDefinitionMasterStore.loaded) {
              this.checklistDefinitionDomList =
                workheldChecklistDefinitionMasterStore.checklistDefinitionDomList;
              this.subject$.next(this.checklistDefinitionDomList);
            }
          },
        ),
    );

    // SET INITIAL
    if (this.data.checklistDefinitionId) {
      this.store.dispatch(
        new GetWHChecklistDefinitionDTO({
          targetID: this.data.checklistDefinitionId,
        }),
      );
    }

    this.data$ = this.subject$.asObservable();

    this.database = new ListDatabase<WHChecklistDefinitionDOM>();
    this.data$
      .pipe(
        takeUntil(componentDestroyed(this)),
        filter<WHChecklistDefinitionDOM[]>(Boolean),
      )
      .subscribe((workObjectTemplateDomList) => {
        this.checklistDefinitionDomList = workObjectTemplateDomList;
        this.database.dataChange.next(workObjectTemplateDomList);
        this.resultsLength = workObjectTemplateDomList.length;
      });

    this.dataSource = new ListDataSource<WHChecklistDefinitionDOM>(
      this.database,
      this.sort,
      this.paginator,
      this.columns,
    );
  }

  public assignChecklistDefinition() {
    this.matDialogRef.close(this.checklistDefinitionId);
  }

  public get visibleColumns() {
    return this.columns
      .filter((column) => column.visible)
      .map((column) => column.property);
  }

  public onFilterChange(value: any) {
    if (!this.dataSource) {
      return;
    }
    this.dataSource.filter = value;
    this.paginator.length = this.dataSource.filterData().length;
  }

  cancel() {
    this.matDialogRef.close();
  }

  public selectChecklistDefinition(
    checklistDefinitionDOM: WHChecklistDefinitionDOM,
  ) {
    if (
      this.checklistDefinitionId &&
      this.checklistDefinitionId === checklistDefinitionDOM.id
    ) {
      return;
    }

    this.checklistDefinitionId = checklistDefinitionDOM.id;
    this.store.dispatch(
      new GetWHChecklistDefinitionDTO({
        targetID: checklistDefinitionDOM.id,
      }),
    );
  }

  public get checklistDefinitionIcon(): string {
    return WHIconENUM.ChecklistDefinitionIcon as string;
  }

  public get saveIcon(): string {
    return WHIconENUM.SaveIcon as string;
  }
  public get cancelIcon(): string {
    return WHIconENUM.CancelIcon as string;
  }

  ngOnDestroy(): void {
    this.store.dispatch(new ClearWHChecklistDefinitionCrudSTORE());
    this.subscriptions.forEach((subscription: Subscription) => {
      subscription.unsubscribe();
    });
  }
}
