import {
  AfterViewInit,
  Component,
  Inject,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {
  IWHShiftDefinitionDTO,
  PageResultShiftDefinitionDto,
} from '@workheld/workheld-shared-lib';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { ShiftDefinitionsDataService } from 'src/app/app-services-async/shift-definitions-data.service';
import { TranslateService } from '@ngx-translate/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';

@Component({
  selector: 'app-mat-dialog-add-shift-definition',
  templateUrl: './mat-dialog-add-shift-definition.component.html',
  styleUrls: ['./mat-dialog-add-shift-definition.component.scss'],
})
export class MatDialogAddShiftDefinitionComponent
  implements OnInit, AfterViewInit, OnDestroy
{
  @ViewChild(MatSort) sort: MatSort;

  form: FormGroup;
  filteredOptions: Observable<IWHShiftDefinitionDTO[]>;
  shiftDefinition: string = '';
  private _shiftDefinitions: BehaviorSubject<PageResultShiftDefinitionDto> =
    new BehaviorSubject(null);
  public shiftDefinitions$: Observable<PageResultShiftDefinitionDto> =
    this._shiftDefinitions.asObservable();

  private subscriptions: Subscription[] = [];
  loaded: boolean = false;

  overlappingAnotherShiftDefinition: boolean = false;
  currentPage: number = 0;
  dataSource = new MatTableDataSource<IWHShiftDefinitionDTO>();
  displayedColumns: string[] = ['toggle', 'dayOfWeek', 'name'];
  selectedShiftDefinitions: IWHShiftDefinitionDTO[] = [];
  shiftDefinitionsInUse: IWHShiftDefinitionDTO[] = [];

  selectedDays: number[] = [];
  loading$: Observable<boolean>;
  constructor(
    private shiftDefinitionService: ShiftDefinitionsDataService,
    public matDialogRef: MatDialogRef<MatDialogAddShiftDefinitionComponent>,
    public translateService: TranslateService,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      departmentId: string;
      shiftDefinitions: IWHShiftDefinitionDTO[];
    },
  ) {}

  ngOnInit(): void {
    const { shiftDefinitionsData } = this.shiftDefinitionService;
    this.loading$ = shiftDefinitionsData.loading$;

    this.form = new FormGroup({
      shiftDefinition: new FormControl(null, [Validators.required]),
      shiftDefinitions: new FormControl([]),
    });

    this.subscriptions.push(
      shiftDefinitionsData.shiftDefinitions$.subscribe((shiftDefinitions) => {
        // filter this.data.shiftDefinitions from shiftDefinition and update shiftDefinitionsData._shiftDefinitions.next
        if (!shiftDefinitions) {
          return;
        }
        //We need to filter out the shiftDefinitions that are already in the shiftCalendar
        //We also need to filter the daysOfWeek that are already in the shiftCalendar (eg. 0 is other mondays)
        const filteredShiftDefinitions = shiftDefinitions?.data.filter(
          (shiftDefinition) =>
            !this.data.shiftDefinitions.find(
              (shiftDefinition2) =>
                shiftDefinition2.id === shiftDefinition.id ||
                shiftDefinition2.dayOfWeek === shiftDefinition.dayOfWeek,
            ),
        );
        this.shiftDefinitionsInUse = this.data.shiftDefinitions;
        // Sort the shiftDefinitions by name and dayOfWeek
        const allData = this.dataSource.data.concat(filteredShiftDefinitions);
        allData.sort(function (a, b) {
          return (
            a.name.localeCompare(b.name) ||
            Number(a.dayOfWeek) - Number(b.dayOfWeek)
          );
        });

        this.dataSource.data = allData;
        this.loaded = true;
      }),
    );

    this.fetchInitial();
  }

  ngAfterViewInit(): void {
    this.dataSource.sort = this.sort;
  }

  selectRow(row: IWHShiftDefinitionDTO) {
    this.overlappingAnotherShiftDefinition = false;
    //If the row is already selected then remove it from the selectedShiftDefinitions
    if (this.selectedShiftDefinitions.includes(row)) {
      this.selectedShiftDefinitions = this.selectedShiftDefinitions.filter(
        (x) => x.id !== row.id,
      );
      this.selectedDays = this.selectedDays.filter((x) => x !== row.dayOfWeek);
      this.checkIfOverlapping();
      return;
    }

    if (this.selectedDays.includes(row.dayOfWeek)) {
      return;
    }
    this.selectedDays.push(row.dayOfWeek);
    this.selectedShiftDefinitions.push(row);
    this.checkIfOverlapping();
  }

  onScroll() {
    this.currentPage++;
    const params = {
      term: '',
      page: this.currentPage,
      sort: 'name',
      sortMode: 'ASC',
      departmentId: this.data.departmentId,
    } as any;
    this.shiftDefinitionService.getShiftDefinitions(params);
  }

  fetchInitial() {
    const params = {
      term: '',
      page: this.currentPage,
      sort: 'name',
      sortMode: 'ASC',
      departmentId: this.data.departmentId,
    } as any;
    this.shiftDefinitionService.getShiftDefinitions(params);
  }

  hasShiftDefinition() {
    return this.form.value.shiftDefinition;
  }

  displayFn(shiftDefinition: IWHShiftDefinitionDTO): string {
    return shiftDefinition?.name ? shiftDefinition.name : '';
  }

  clear() {
    this.form.value.shiftDefinition = null;
    this.shiftDefinition = '';
  }
  confirm() {
    this.matDialogRef.close(this.selectedShiftDefinitions);
  }

  cancel() {
    this.matDialogRef.close(false);
  }

  get noneSelected(): boolean {
    return this.selectedShiftDefinitions.length === 0;
  }

  get getShiftDefinition(): FormControl {
    return this.form.get('shiftDefinition') as FormControl;
  }

  checkIfOverlapping() {
    //List of all the selectedShiftDefinitions
    const selectedShiftDefinitions = structuredClone(
      this.shiftDefinitionsInUse,
    ).concat(this.selectedShiftDefinitions);

    //Sort the selectedShiftDefinitions by dayOfWeek
    selectedShiftDefinitions.sort(function (a, b) {
      return a.dayOfWeek - b.dayOfWeek;
    });

    // iterate with index of next element
    for (let i = 0; i < selectedShiftDefinitions.length; i++) {
      const firstShiftDefinition = selectedShiftDefinitions[i];
      let secondShiftDefinition;
      //Check if the next day is weekday after the first day
      if (firstShiftDefinition.dayOfWeek === 6) {
        secondShiftDefinition = selectedShiftDefinitions.find(
          (shiftDef) => shiftDef.dayOfWeek === 0,
        );
        if (
          this.checkIfOverlappingDate(
            firstShiftDefinition,
            secondShiftDefinition,
          )
        ) {
          this.overlappingAnotherShiftDefinition = true;
        }
      } else {
        secondShiftDefinition = selectedShiftDefinitions[i + 1];
        if (
          firstShiftDefinition.dayOfWeek + 1 ===
          secondShiftDefinition.dayOfWeek
        ) {
          if (
            this.checkIfOverlappingDate(
              firstShiftDefinition,
              secondShiftDefinition,
            )
          ) {
            this.overlappingAnotherShiftDefinition = true;
          }
        }
      }
    }
  }

  checkIfOverlappingDate(
    firstshiftDefinition: IWHShiftDefinitionDTO,
    secondShiftDefinition: IWHShiftDefinitionDTO,
  ) {
    const newStartTimeHours = firstshiftDefinition?.startTime
      ? secondShiftDefinition.startTime.split(':').map((x) => parseInt(x))
      : [];

    const newEndTimeHours = firstshiftDefinition?.endTime
      ? secondShiftDefinition.endTime.split(':').map((x) => parseInt(x))
      : [];

    if (secondShiftDefinition) {
      const nextStartTimeHours = secondShiftDefinition?.startTime
        ? secondShiftDefinition.startTime.split(':').map((x) => parseInt(x))
        : [];
      if (nextStartTimeHours.length === 0) {
        return false;
      }

      //check if newStartTimeHours and newEndTimeHours within 24 hours
      if (newEndTimeHours[0] > newStartTimeHours[0]) {
        return false;
      }

      //Check if newEndTimeHours is overlapping with nextStartTimeHours
      if (
        newEndTimeHours[0] > nextStartTimeHours[0] ||
        (newEndTimeHours[0] === nextStartTimeHours[0] &&
          newEndTimeHours[1] > nextStartTimeHours[1])
      ) {
        return true;
      }
    }
    return false;
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
    this.shiftDefinitionService.shiftDefinitionsData._shiftDefinitions.next(
      null,
    );
  }
}
