import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { WHEnumPipe } from './wh-enum.pipe';
import { CustomTranslatePipePipe } from './custom-translate-pipe.pipe';
import { DayOfWeekPipe } from './dayofweek.pipe';
import { DurationPipe } from './duration.pipe';
import { ParamsTranslatePipe } from './params-translate.pipe';
import { TruncatePipe } from './truncate.pipe';

@NgModule({
  declarations: [
    WHEnumPipe,
    CustomTranslatePipePipe,
    DayOfWeekPipe,
    DurationPipe,
    ParamsTranslatePipe,
    TruncatePipe,
  ],
  imports: [CommonModule],
  exports: [
    WHEnumPipe,
    CustomTranslatePipePipe,
    DayOfWeekPipe,
    DurationPipe,
    ParamsTranslatePipe,
    TruncatePipe,
  ],
})
export class WHPipesModule {}
