<div class="p--16">
  <span class="w-h-header-span">
    <div>
      {{ "workobject.ui.resume.label" | translate }}
    </div>
  </span>
</div>

<mat-divider class="wh-dialog-divider"></mat-divider>
<div class="p--16">
  <div class="flex--r-fs gap--16 p-b--24">
    <div class="flex-1 confirm-text">
      {{ "workobject.ui.resume.body.label" | translate }}
    </div>
  </div>
  <div class="dialog-action-wrapper" mat-dialog-actions>
    <button
      class="w-h-default-button dialog-button mat-elevation-z2"
      mat-flat-button
      type="button"
      (click)="cancel()"
    >
      {{ "app.cancel.label" | translate | uppercase }}
    </button>

    <button
      color="accent"
      class="dialog-button mat-elevation-z2"
      mat-flat-button
      type="button"
      (click)="confirm()"
    >
      {{ "app.resume.label" | translate | uppercase }}
    </button>
  </div>
</div>
